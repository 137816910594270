<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    @keydown.esc="dialog = false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on" @click="reset">
        Add Eventx
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Add Event</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-btn-toggle v-model="type" tile color="primary accent-3" group>
                <v-btn value="goal" @click="reset">
                  GOAL
                </v-btn>
                <v-btn value="penalty" @click="reset">
                  PENALTY GOAL
                </v-btn>
                <v-btn value="penaltyMiss" @click="reset">
                  PENALTY MISS
                </v-btn>
                <v-btn value="ownGoal" @click="reset">
                  OWN GOAL
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <draggable :list="playersCopy" group="players">
                <v-btn
                  v-for="player in playersCopy"
                  :key="player.nickname"
                  :color="player.colour"
                  small
                  class="ma-2"
                >
                  {{ player.nickname }}
                </v-btn>
              </draggable>
            </v-col>
          </v-row>
          <template v-if="type === 'goal'">
            <v-row>
              <v-col cols="6">
                <div class="dragDropArea">
                  <draggable
                    class="dragDropAreaText"
                    :list="arrGoal"
                    group="players"
                  >
                    <drag-drop-area :arr-event="arrGoal" event-type="goal" />
                  </draggable>
                </div>
              </v-col>
              <v-col cols="6">
                <div class="dragDropArea">
                  <draggable
                    class="dragDropAreaText"
                    :list="arrAssist"
                    group="players"
                  >
                    <drag-drop-area
                      :arr-event="arrAssist"
                      event-type="assist"
                    />
                  </draggable>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="type === 'penalty'">
            <v-row>
              <v-col cols="6">
                <div class="dragDropArea">
                  <draggable
                    class="dragDropAreaText"
                    :list="arrPenaltyGoalConceededBy"
                    group="players"
                  >
                    <drag-drop-area
                      :arr-event="arrPenaltyGoalConceededBy"
                      event-type="conceeded by"
                    />
                  </draggable>
                </div>
              </v-col>
              <v-col cols="6">
                <div class="dragDropArea">
                  <draggable
                    class="dragDropAreaText"
                    :list="arrPenaltyGoalBy"
                    group="players"
                  >
                    <drag-drop-area
                      :arr-event="arrPenaltyGoalBy"
                      event-type="penalty scored by"
                    />
                  </draggable>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="type === 'penaltyMiss'">
            <v-row>
              <v-col cols="4">
                <div class="dragDropArea">
                  <draggable
                    class="dragDropAreaText"
                    :list="arrPenaltyMissConceededBy"
                    group="players"
                  >
                    <drag-drop-area
                      :arr-event="arrPenaltyMissConceededBy"
                      event-type="conceeded by"
                    />
                  </draggable>
                </div>
              </v-col>
              <v-col cols="4">
                <div class="dragDropArea">
                  <draggable
                    class="dragDropAreaText"
                    :list="arrPenaltyMissMissBy"
                    group="players"
                  >
                    <drag-drop-area
                      :arr-event="arrPenaltyMissMissBy"
                      event-type="missed by"
                    />
                  </draggable>
                </div>
              </v-col>
              <v-col cols="4">
                <div class="dragDropArea">
                  <draggable
                    class="dragDropAreaText"
                    :list="arrPenaltyMissSavedBy"
                    group="players"
                  >
                    <drag-drop-area
                      :arr-event="arrPenaltyMissSavedBy"
                      event-type="saved by"
                    />
                  </draggable>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row>
              <v-spacer />
              <v-col cols="6">
                <div class="dragDropArea">
                  <draggable
                    class="dragDropAreaText"
                    :list="arrOwnGoal"
                    group="players"
                  >
                    <drag-drop-area
                      :arr-event="arrOwnGoal"
                      event-type="own goal"
                    />
                  </draggable>
                </div>
              </v-col>
              <v-spacer />
            </v-row>
          </template>
          <v-row>
            <v-col cols="4">
              <v-switch
                v-model="footage"
                :label="`${footage ? 'Video available' : 'No video'}`"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                ref="min"
                v-model="min.val"
                label="Mins"
                type="number"
                :rules="minRules"
                :disabled="!footage"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="secs.val"
                label="Seconds"
                type="number"
                :rules="secRules"
                :disabled="!footage"
              />
            </v-col>
          </v-row>
          <v-row v-show="false">
            <v-col>
              <v-slider
                v-model="min.val"
                :label="min.label"
                :thumb-color="min.color"
                thumb-label="always"
                :min="0"
                :max="100"
              />
            </v-col>
            <v-col cols="6">
              <v-slider
                v-model="secs.val"
                :label="secs.label"
                :thumb-color="secs.color"
                thumb-label="always"
                :min="0"
                :max="59"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="saveEvent()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import draggable from 'vuedraggable';
import DragDropArea from './DragDropArea.vue';
import objectId from 'bson-objectid';
import { addMinutes, addSeconds, intervalToDuration } from 'date-fns';
export default {
  name: 'AddPoint',
  components: {
    draggable,
    DragDropArea,
  },
  props: {
    players: Array,
  },
  data: () => ({
    addMinutes,
    addSeconds,
    intervalToDuration,
    dialog: false,
    type: 'goal',
    scorer: null,
    assist: null,
    min: { label: 'Mins', val: 0, color: 'red' },
    secs: { label: 'Secs', val: 0, color: 'blue' },
    minRules: [
      v => !!v || 'This field is required',
      v => (v && v >= 0) || 'Mins have to be 0 or more',
      v => (v && v <= 100) || 'Should not be above 100*',
    ],
    secRules: [
      v => !!v || 'This field is required',
      v => (v && v >= 0) || 'Secs have to be 0 or more',
      v => (v && v <= 59) || 'Should be less than 60',
    ],
    playersCopy: [],
    arrGoal: [],
    arrAssist: [],
    arrPenaltyGoalConceededBy: [],
    arrPenaltyGoalBy: [],
    arrPenaltyMissConceededBy: [],
    arrPenaltyMissMissBy: [],
    arrPenaltyMissSavedBy: [],
    arrOwnGoal: [],
    dtMatchStartDateTime: null,
    dtLater: null,
    footage: true,
  }),
  computed: {
    ...mapState('leagues', ['match']),
    orangePlayers() {
      return this.playersCopy.filter(p => {
        return p.colour === 'orange';
      });
    },
    bluePlayers() {
      return this.playersCopy.filter(p => {
        return p.colour === 'blue';
      });
    },
  },
  watch: {
    arrPenaltyMissConceededBy: function() {
      const players = new Set(this.players);
      this.playersCopy = [...players];
    },
    arrPenaltyMissSavedBy: function() {
      const players = new Set(this.players);
      this.playersCopy = [...players];
    },
  },
  mounted() {
    this.reset();
  },
  methods: {
    ...mapActions('leagues', [
      'addGoal',
      'addOwnGoal',
      'addPenaltyGoal',
      'addPenaltyMiss',
      'getMatchPoints',
    ]),
    async saveEvent() {
      const leagueId = objectId(this.$route.params.leagueId);
      const matchId = objectId(this.$route.params.matchId);
      const min = this.footage ? parseInt(this.min.val) : -1;
      const sec = this.footage ? parseInt(this.secs.val) : -1;
      const payload = {
        leagueId: leagueId,
        matchId: matchId,
        type: this.type,
        min: min,
        sec: sec,
      };

      if (this.type === 'goal') {
        payload.goalBy = this.arrGoal[0];
        payload.assistBy = this.arrAssist[0];
        await this.addGoal(payload);
      } else if (this.type === 'ownGoal') {
        payload.ownGoalBy = this.arrOwnGoal[0];
        await this.addOwnGoal(payload);
      } else if (this.type === 'penalty') {
        payload.penaltyGoalBy = this.arrPenaltyGoalBy[0];
        payload.penaltyConceededBy = this.arrPenaltyGoalConceededBy[0];
        await this.addPenaltyGoal(payload);
      } else if (this.type === 'penaltyMiss') {
        payload.penaltyMissBy = this.arrPenaltyMissMissBy[0];
        payload.penaltySavedBy = this.arrPenaltyMissSavedBy[0];
        payload.penaltyConceededBy = this.arrPenaltyMissConceededBy[0];
        await this.addPenaltyMiss(payload);
      }

      // this.getMatchPoints(this.$route.params.matchId.toString()) // deprecated
      this.dialog = false;
    },
    reset() {
      const players = new Set(this.players);
      this.playersCopy = [...players];
      this.arrGoal = [];
      this.arrAssist = [];
      this.arrPenaltyGoalConceededBy = [];
      this.arrPenaltyGoalBy = [];
      this.arrPenaltyMissConceededBy = [];
      this.arrPenaltyMissMissBy = [];
      this.arrPenaltyMissSavedBy = [];
      this.arrOwnGoal = [];
    },
    bgColor(color) {
      switch (color) {
        case 'orange':
          return 'orange';
        case 'blue':
          return '#2196F3';
        default:
          return 'black';
      }
    },
    editEvent() {},
  },
};
</script>

<style scoped>
.dragDropArea {
  width: 100%;
  height: 60px;
  border: 1px dashed grey;
  border-radius: 5px;
  line-height: 48px;
  text-align: center;
  color: #333333;
}
.dragDropAreaText {
  font-weight: bold;
  height: 100%;
}
</style>
