<template>
  <section class="mb-12 text-center">
    <h1
      class="mb-2 text-h3"
      v-text="heading"
    />

    <div
      v-if="date"
      class="text-body-2 font-weight-light"
    >
      {{ format(date, 'E do MMM yyyy K:mmbbb') }}
    </div>

    <div
      v-if="link && link.startsWith('https:')"
      class="text-body-2 font-weight-light mt-1"
    >
      <a
        :href="link"
        class="text-decoration-none secondary--text"
        target="_blank"
      > {{ link }}
        <v-icon
          color="red"
          light
        >
          mdi-youtube
        </v-icon>
      </a>
    </div>
    <div
      v-else-if="link"
      class="text-body-2 font-weight-light"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ attrs, on }">
          <v-icon
            v-bind="attrs"
            color="grey"
            light
            v-on="on"
          >
            mdi-video-off
          </v-icon>
        </template>
        <span>No footage available</span>
      </v-tooltip>
    </div>

    <v-responsive
      class="text-center mx-auto text-body-1 font-weight-light"
      max-width="400"
    >
      <slot />
    </v-responsive>
  </section>
</template>

<script>
  import { format, parseISO } from 'date-fns'
  export default {
    name: 'ViewIntro',
    props: {
      heading: String,
      link: String,
      date: Date,
    },
    data: () => ({
      format,
      parseISO,
    }),
  }
</script>
