<template>
  <v-card class="mb-3">
    <v-card-text>
      <v-row>
        <v-col>
          <template>
            <v-icon class="mb-1 mr-1">
              mdi-card-account-details-outline
            </v-icon>
            <span class="ml-2 pt-5 text-sm-h4 ">Subs</span>
          </template>
        </v-col>
      </v-row>
      <v-row v-if="user">
        <v-col>
          <span v-if="user.paid">
            Thanks for paying £20 for this year's subscription!
          </span>
          <span v-else>
            Please pay your subscription of £20 for this year. Transfer to Rahul
            Vaghela 40-28-06 72489937 or PayPal rahulvaghela@hotmail.com
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'Subscriptions',
  computed: {
    ...mapState('auth', ['user']),
  },
};
</script>

<style></style>
