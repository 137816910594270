<template>
  <v-data-table
    v-if="match.highlights.length > 0"
    :headers="headers"
    :items="match.highlights"
    :items-per-page="5"
    class="elevation-1"
    dense
    :mobile-breakpoint="0"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>
          Highlights
        </v-toolbar-title>
      </v-toolbar>
    </template>
    <template #[`item.min`]="{ item }">
      {{
        item.min !== -1
          ? `${item.min
              .toString()
              .padStart(2, '0')}:${item.sec.toString().padStart(2, '0')}`
          : 'No footage'
      }}
    </template>
    <template #[`item.added`]="{ item }">
      {{ format(new Date(item.added), 'E do MMM yyyy K:mm') }}
    </template>
    <template #[`item.submittedBy`]="{ item }">
      {{ item.submittedBy ? item.submittedBy : 'Rahul' }}
    </template>
    <template
      v-if="user.email === 'rahulvaghela@hotmail.com'"
      #[`item.actions`]="{ item }"
    >
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <button v-on="on" @click="deleteHighlightClick(item)">
            <v-icon>
              mdi-delete
            </v-icon>
          </button>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { format } from 'date-fns';
export default {
  name: 'MatchHighlightsTable',
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState('leagues', ['match']),
    ...mapState('auth', ['user']),
    headers() {
      const headers = [
        { text: 'Time', value: 'min' },
        { text: 'id', value: '_id' },
        { text: 'Description', value: 'description' },
        { text: 'Added', value: 'added' },
        { text: 'Submitted By', value: 'submittedBy' },
      ];

      if (this.user.email === 'rahulvaghela@hotmail.com') {
        headers.push({ text: 'Action', value: 'actions', sortable: false });
      }

      return headers;
    },
  },
  methods: {
    ...mapActions('leagues', ['deleteHighlight']),
    async deleteHighlightClick(item) {
      const payload = {
        matchId: this.match._id.toString(),
        eventId: item._id.toString(),
      };
      await this.deleteHighlight(payload);
    },
  },
};
</script>

<style></style>
