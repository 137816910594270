<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-stepper v-model="eventStepper" vertical>
      <v-stepper-step :complete="eventStepper > 1" step="1">
        <span v-if="NEWtype"> Select event type: {{ NEWtype }}</span
        ><span v-else>Select event type</span>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card class="mb-12">
          <v-btn-toggle
            v-model="NEWtype"
            tile
            color="primary accent-3"
            group
            @change="eventStepperType"
          >
            <v-btn value="goal">
              Goal
            </v-btn>
            <v-btn value="penaltyConceeded">
              Penalty Conceeded
            </v-btn>
            <v-btn value="penaltyScored">
              Penalty Scored
            </v-btn>
            <v-btn value="penaltyMiss">
              Penalty Miss
            </v-btn>
          </v-btn-toggle>
        </v-card>
      </v-stepper-content>

      <v-stepper-step :complete="eventStepper > 2" step="2">
        Colour: {{ goalAndAssistColour }}
      </v-stepper-step>
      <v-stepper-content step="2">
        <v-row>
          <v-col>
            <v-btn
              v-if="!goalAndAssistColour"
              color="orange"
              @click="goalAndAssistColourSelected('orange')"
            >
              Oranges
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-if="!goalAndAssistColour"
              color="primary"
              @click="goalAndAssistColourSelected('blue')"
            >
              Blues
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
      <v-stepper-step :complete="eventStepper > 3" step="3">
        {{ eventStepper2Desc }}
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-card class="mb-12" color="primary lighten-3">
          <v-btn-toggle
            v-if="goalAndAssistColour === 'blue' && blueScorer === null"
            v-model="blueScorer"
            tile
            color="primary darken-2"
            group
          >
            <v-btn v-for="n in bluePlayers" :key="n.nickname">
              {{ n.nickname }}
            </v-btn>
          </v-btn-toggle>
          <v-btn-toggle
            v-if="blueScorer !== null"
            v-model="blueAssister"
            tile
            color="primary darken-2"
            group
            @change="blueGoalAssistSelected"
          >
            <v-btn v-for="n in bluePlayers" :key="n.nickname">
              {{ n.nickname }}
            </v-btn>
          </v-btn-toggle>
          <v-btn v-if="blueScorer" class="noAssist" @click="noAssist">
            No Assist
          </v-btn>
        </v-card>
        <v-card class="mb-12" color="orange lighten-3">
          <v-btn-toggle
            v-show="goalAndAssistColour === 'orange' && orangeScorer === null"
            v-model="orangeScorer"
            tile
            color="orange darken-2"
            group
          >
            <v-btn v-for="n in orangePlayers" :key="n.nickname">
              {{ n.nickname }}
            </v-btn>
          </v-btn-toggle>
          <v-btn-toggle
            v-if="orangeScorer !== null"
            v-model="orangeAssister"
            tile
            color="orange darken-2"
            group
            @change="orangeGoalAssistSelected"
          >
            <v-btn v-for="n in orangePlayers" :key="n.nickname">
              {{ n.nickname }}
            </v-btn>
          </v-btn-toggle>
          <v-btn
            v-if="orangeScorer !== null"
            class="noAssist"
            @click="noAssist"
          >
            No Assist
          </v-btn>
        </v-card>
        <v-row>
          <v-col>
            <v-btn text @click="resetStepper">
              Back
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>

      <v-stepper-step :complete="eventStepper > 4" step="4">
        Select time of event: {{ min.val }} mins {{ secs.val }} secs
      </v-stepper-step>

      <v-stepper-content step="4">
        <v-card class="mb-12" />
        <v-row>
          <v-col cols="6">
            <v-text-field
              ref="min"
              v-model="min.val"
              label="Mins"
              type="number"
              :rules="minRules"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="secs.val"
              label="Seconds"
              type="number"
              :rules="secRules"
            />
          </v-col>
        </v-row>
        <v-btn color="primary" @click="NEWsaveEvent">
          Add
        </v-btn>
        <v-btn text @click="resetStepper">
          Cancel
        </v-btn>
      </v-stepper-content>
    </v-stepper>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        Add Goal
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Add Event</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-radio-group v-model="type" :column="false">
                <v-radio value="goal">
                  <template v-slot:label>
                    <div class="rbLabel">
                      Goal
                    </div>
                  </template>
                </v-radio>
                <v-radio value="ownGoal">
                  <template v-slot:label>
                    <div class="rbLabel">
                      Own Goal
                    </div>
                  </template>
                </v-radio>
                <v-radio label="Penalty" value="penalty">
                  <template v-slot:label>
                    <div class="rbLabel">
                      Penalty
                    </div>
                  </template>
                </v-radio>
                <v-radio label="Penalty Miss" value="penaltyMiss">
                  <template v-slot:label>
                    <div class="rbLabel">
                      Penalty Miss
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              scorer
              <v-radio-group v-model="scorer">
                <v-radio
                  v-for="n in players"
                  :key="n.nickname"
                  :label="n.nickname"
                  :value="n.nickname"
                >
                  <template v-slot:label>
                    <v-btn
                      depressed
                      x-small
                      :color="n.colour"
                      class="white--text"
                    >
                      {{ n.nickname }}
                    </v-btn>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="6">
              assist / not required for OG / penalty conceeded by / penalty
              saved by
              <v-btn color="primary" @click="assist = null">
                Add Event
              </v-btn>
              <v-radio-group v-model="assist">
                <v-radio
                  v-for="n in players"
                  :key="n.nickname"
                  :label="n.nickname"
                  :value="n.nickname"
                >
                  <template v-slot:label>
                    <v-btn
                      depressed
                      x-small
                      :color="n.colour"
                      class="white--text"
                    >
                      {{ n.nickname }}
                    </v-btn>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row v-show="false">
            <v-col>
              <v-slider
                v-model="min.val"
                :label="min.label"
                :thumb-color="min.color"
                thumb-label="always"
                :min="0"
                :max="100"
              />
            </v-col>
            <v-col cols="6">
              <v-slider
                v-model="secs.val"
                :label="secs.label"
                :thumb-color="secs.color"
                thumb-label="always"
                :min="0"
                :max="59"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="saveEvent()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'AddPoint',
  props: {
    players: Array,
  },
  data: () => ({
    dialog: false,
    type: 'goal',
    scorer: null,
    assist: null,
    min: { label: 'Mins', val: 0, color: 'red' },
    secs: { label: 'Secs', val: 0, color: 'blue' },
    eventStepper: 1,
    NEWtype: null,
    orangeScorer: null,
    orangeAssister: null,
    blueScorer: null,
    blueAssister: null,
    NEWscorer: null,
    goalAndAssistColour: null,
    minRules: [
      v => !!v || 'This field is required',
      v => (v && v >= 0) || 'Mins have to be 0 or more',
      v => (v && v <= 100) || 'Should not be above 100*',
    ],
    secRules: [
      v => !!v || 'This field is required',
      v => (v && v >= 0) || 'Secs have to be 0 or more',
      v => (v && v <= 59) || 'Should be less than 60',
    ],
  }),
  computed: {
    orangePlayers() {
      return this.players.filter(p => {
        return p.colour === 'orange';
      });
    },
    bluePlayers() {
      return this.players.filter(p => {
        return p.colour === 'blue';
      });
    },
    eventStepper2Desc() {
      if (this.goalAndAssistColour === 'orange') {
        return `Colour: ${this.goalAndAssistColour} | Scorer: ${
          this.orangeScorer !== null
            ? this.orangePlayers[this.orangeScorer].nickname
            : ''
        } | Assist By: ${
          this.orangeAssister !== null
            ? this.orangePlayers[this.orangeAssister].nickname
            : 'No Assist'
        }`;
      } else {
        return `Colour: ${this.goalAndAssistColour} | Scorer: ${
          this.blueScorer !== null
            ? this.bluePlayers[this.blueScorer].nickname
            : ''
        } | Assist By: ${
          this.blueAssister !== null
            ? this.bluePlayers[this.blueAssister].nickname
            : 'No Assist'
        }`;
      }
    },
  },
  mounted() {
    this.eventStepper = 1;
  },
  methods: {
    ...mapActions('leagues', ['addEvent', 'getMatchPoints']),
    async saveEvent() {
      const event = {
        leagueId: this.$route.params.leagueId,
        matchId: this.$route.params.matchId,
        type: this.type,
        scorer: this.scorer,
        assister: this.assist,
        min: parseInt(this.min.val),
        sec: parseInt(this.secs.val),
      };
      await this.addEvent(event);
      // this.getMatchPoints(this.$route.params.matchId.toString()) // deprecated
      this.dialog = false;
    },
    async NEWsaveEvent() {
      let scoredBy;
      if (this.goalAndAssistColour === 'orange') {
        scoredBy = this.orangePlayers[this.orangeScorer].nickname;
      } else {
        scoredBy = this.bluePlayers[this.blueScorer].nickname;
      }
      let assistBy = null;
      if (this.orangeAssister || this.blueAssister) {
        if (this.goalAndAssistColour === 'orange') {
          assistBy = this.orangePlayers[this.orangeAssister].nickname;
        } else {
          assistBy = this.bluePlayers[this.blueAssister].nickname;
        }
      }

      const event = {
        leagueId: this.$route.params.leagueId,
        matchId: this.$route.params.matchId,
        type: this.NEWtype,
        scorer: scoredBy,
        assister: assistBy,
        min: parseInt(this.min.val),
        sec: parseInt(this.secs.val),
      };

      await this.addEvent(event);
      // this.getMatchPoints(this.$route.params.matchId.toString()) // deprecated
      this.dialog = false;
      this.resetStepper();
    },
    eventStepperType() {
      this.goalAndAssistColour = null;
      this.eventStepper = 2;
    },
    goalAndAssistColourSelected(colour) {
      this.blueScorer = null;
      this.orangeScorer = null;
      this.goalAndAssistColour = colour;
      this.eventStepper = 3;
    },
    resetStepper() {
      this.NEWtype = null;
      this.blueScorer = null;
      this.blueAssister = null;
      this.orangeScorer = null;
      this.orangeAssister = null;
      this.goalAndAssistColour = null;
      this.eventStepper = 1;
      this.min = { label: 'Mins', val: 0, color: 'red' };
      this.secs = { label: 'Secs', val: 0, color: 'blue' };
    },
    orangeGoalAssistSelected() {
      this.eventStepper = 4;
      this.$refs.min.$refs.input.focus();
    },
    blueGoalAssistSelected() {
      this.eventStepper = 4;
      this.$refs.min.$refs.input.focus();
    },
    noAssist() {
      this.blueAssister = null;
      this.orangeAssister = null;
      this.eventStepper = 4;
      this.$refs.min.$refs.input.focus();
    },
  },
};
</script>

<style scoped>
.rbLabel {
  margin-right: 10px;
}
.rbPlayer {
  font-weight: bold;
}
.noAssist {
  width: 100%;
}
</style>
