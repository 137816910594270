<template>
  <v-row>
    <v-col>
      <v-tabs v-model="tab" fixed-tabs background-color="primary" dark>
        <v-tab :key="1" :href="`#tab-${1}`">
          Lineups
        </v-tab>
        <v-tab :key="2" :href="`#tab-${2}`">
          Awards
        </v-tab>
        <v-tab :key="3" :href="`#tab-${3}`">
          Points
        </v-tab>
        <v-tab :key="4" :href="`#tab-${4}`">
          Highlights
        </v-tab>
        <!-- <v-tab
          :key="4"
          :href="`#tab-${4}`"
        >
          Match Video
        </v-tab> -->
      </v-tabs>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item :key="1" :value="'tab-' + 1">
          <lineups class="mt-3" />
        </v-tab-item>
        <v-tab-item :key="2" :value="'tab-' + 2">
          <match-awards class="mt-3" />
        </v-tab-item>
        <v-tab-item :key="3" :value="'tab-' + 3">
          <match-individual-points class="mt-3" />
        </v-tab-item>
        <v-tab-item :key="4" :value="'tab-' + 4">
          <match-highlights class="mt-3" />
        </v-tab-item>
        <!-- <v-tab-item
          :key="4"
          :value="'tab-' + 4"
        >
          <v-row>
            <v-col
              class="d-flex justify-center"
            >
              <match-video
                :min="min"
                :secs="secs"
                class="mt-3"
              />
            </v-col>
          </v-row>
        </v-tab-item> -->
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'MatchViewDetail',
  components: {
    Lineups: () => import('./Lineups'),
    MatchAwards: () => import('./MatchAwards'),
    MatchIndividualPoints: () => import('./MatchIndividualPoints'),
    MatchHighlights: () => import('./MatchHighlights'),
    // MatchVideo: () => import(
    //   './MatchVideo'
    // ),
  },
  data() {
    return {
      tab: null,
      loading: true,
      min: 0,
      secs: 0,
    };
  },
};
</script>

<style></style>
