<template>
  <iframe
    ref="youtubeVid"
    :width="getWidth"
    height="315"
    :src="src"
  />
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'MatchVideo',
    props: {
      min: { type: Number, default: 0 },
      secs: { type: Number, default: 0 },
    },
    computed: {
      ...mapState('leagues', ['match']),
      src () {
        const videoId = this.getVideoId()
        const startTime = this.getStartTime()
        return `https://www.youtube.com/embed/${videoId}?start=${startTime}&autoplay=1`
      },
    },
    methods: {
      getVideoId () {
        const indexForSlash = this.match.youtubeURL.lastIndexOf('/')
        const id = this.match.youtubeURL.substring(indexForSlash + 1)
        return id
      },
      getStartTime () {
        const inSecs = (this.min * 60) + this.secs
        return inSecs
      },
      getWidth () {
        console.log(this.$refs.youtubeVid.clientWidth)
        const containerWidth = this.$refs.youtubeVid
        if (containerWidth) {
          return containerWidth
        } else {
          return 600
        }
      },
    },
  }
</script>

<style>

</style>
