import firebase from 'firebase';
import router from '../../router';
import axios from 'axios';
import * as RealmWeb from 'realm-web';
const currentLeague = '63b439285de373a32c87afe2';

function initialState() {
  return {
    user: null,
    token: null,
    customToken: null,
    idToken: null,
    loading: false,
  };
}

export default {
  namespaced: true,
  state: {
    user: null,
    token: null,
    customToken: null,
    idToken: null,
  },
  getters: {
    getUserNickname: state => {
      return state.user ? state.user.nickname : 'Loading';
    },
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_CUSTOM_TOKEN(state, payload) {
      state.customToken = payload;
    },
    SET_ID_TOKEN(state, payload) {
      state.idToken = payload;
    },
    RESET(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    async signUserIn({ commit, dispatch }, payload) {
      commit('SET_LOADING', true);
      await firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password);
      const idTokenResult = await firebase
        .auth()
        .currentUser.getIdTokenResult();
      commit('SET_TOKEN', idTokenResult);
      await dispatch('getUser', firebase.auth().currentUser.email);
      await dispatch('leagues/getLeagues', {}, { root: true });
      await dispatch('leagues/getLeague', currentLeague, { root: true });
      // await dispatch('leagues/getMatches', currentLeague, { root: true });
      // await dispatch('stats/getLeaguePoints', currentLeague, { root: true });
      commit('SET_LOADING', false);
      router.push({ name: 'Dashboard' });
    },
    setToken({ commit, rootState }, payload) {
      commit('SET_TOKEN', payload);
    },
    signUserOut({ commit }) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          commit('stats/RESET', null, { root: true });
          commit('RESET', null);
          router.push({ name: 'Login' });
        });
    },
    async getCustomToken({ commit, dispatch }) {
      return axios({
        method: 'post',
        url: process.env.VUE_APP_BACKENDAPI + '/stats/token',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          uid: process.env.VUE_APP_CUSTOMTOKENUID,
        },
      })
        .then(response => {
          commit('SET_CUSTOM_TOKEN', response.data);
        })
        .catch(err => {
          console.error(err);
        });
    },
    async getIdToken({ commit, dispatch, state }) {
      return firebase
        .auth()
        .signInWithCustomToken(state.customToken)
        .then(() => {
          firebase
            .auth()
            .currentUser.getIdToken()
            .then(idToken => {
              commit('SET_ID_TOKEN', idToken);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },
    async getUser({ commit, rootState }, payload) {
      const app = new RealmWeb.App({
        id: process.env.VUE_APP_MONGO_REALM_SERVER,
      });
      const credentials = RealmWeb.Credentials.jwt(rootState.auth.token.token);
      try {
        const user = await app.logIn(credentials);
        const result = await user.functions.getUser_new(payload);
        commit('SET_USER', result[0]);
      } catch (err) {
        this._vm.$toast.error('User failed to load', { timeout: 2000 });
        console.error(`Error: ${err}`);
      }
    },
  },
};
