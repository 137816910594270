import { format, parseISO } from 'date-fns';

export default {
  data: () => ({
    format,
    parseISO,
  }),
  methods: {
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    returnUserFriendlyLabel(type) {
      switch (type) {
        case 'goal':
          return 'Goal';
        case 'assist':
          return 'Assist';
        case 'ownGoal':
          return 'Own goal';
        case 'penalty':
          return 'Goal (pen.)';
        case 'penaltyConceded':
          return 'Penalty conceded';
        case 'penaltyMiss':
          return 'Penalty miss';
        case 'penaltySave':
          return 'Penalty save';
        case 'motm':
          return 'Man of the Match';
        case 'gotm':
          return 'Goalkeeper of the Match';
        case 'dotm':
          return 'Defender of the Match';
        case 'win':
          return 'Win';
        case 'draw':
          return 'Draw';
        case 'lose':
          return 'Lose';
        case 'concededUnder5':
          return 'Concede under 5';
        case 'concededUnder4':
          return 'Concede under 4';
        case 'concededUnder3':
          return 'Concede under 3';
        case 'concededUnder1':
          return 'Clean sheet';
        default:
          return type;
        // code block
      }
    },
    returnFormattedDate(date) {
      return format(
        parseISO(new Date(date).toISOString()),
        'E do MMM yyyy K:mmbbb',
      );
    },
    getFriendlyAwardName(type) {
      switch (type) {
        case 'motm':
          return 'Man of the Match';
        case 'dotm':
          return 'Defender of the Match';
        case 'gotm':
          return 'Goalkeeper of the Match';
        case 'win':
          return 'Win';
        case 'draw':
          return 'Draw';
        case 'lose':
          return 'Loss';
        case 'concededUnder5':
          return 'Conceded Under 5';
        case 'concededUnder4':
          return 'Conceded Under 4';
        case 'concededUnder3':
          return 'Conceded Under 3';
        case 'concededUnder1':
          return 'Clean sheet';
      }
    },
    find_mode(arr) {
      var max = 0;
      var maxarr = [];
      var counter = [];

      arr.forEach(function() {
        counter.push(0);
      });

      for (var i = 0; i < arr.length; i++) {
        for (var j = 0; j < arr.length; j++) {
          if (arr[i] === arr[j]) counter[i]++;
        }
      }

      max = this.arrayMax(counter);

      for (var k = 0; k < arr.length; k++) {
        if (counter[k] === max) maxarr.push(arr[k]);
      }

      var unique = maxarr.filter(this.onlyUnique);
      return unique.join(' & ');
    },
    arrayMax(arr) {
      var len = arr.length;
      var max = -Infinity;
      while (len--) {
        if (arr[len] > max) {
          max = arr[len];
        }
      }
      return max;
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
  },
};
