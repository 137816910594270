<template>
  <div>
    <template v-if="!match.summary" class="pa-2">
      <span>No awards recorded</span>
    </template>
    <template v-else>
      <v-row>
        <v-col class="d-flex justify-center">
          <h4>Individual</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Award
                  </th>
                  <th class="text-left">
                    Awarded to
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Man of the Match</td>
                  <td>{{ convertToLegibleString(convertArray(AwardedToAndAwardedBy('motm'))) }}</td>
                </tr>
                <tr>
                  <td>Defender of the Match</td>
                  <td>{{ convertToLegibleString(convertArray(AwardedToAndAwardedBy('dotm'))) }}</td>
                </tr>
                <tr>
                  <td>Goalkeeper of the Match</td>
                  <td>{{ convertToLegibleString(convertArray(AwardedToAndAwardedBy('gotm'))) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Award
                  </th>
                  <th class="text-left">
                    Player
                  </th>
                  <th class="text-left">
                    Awarded by
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in match.matchAwards" :key="i">
                  <td>{{ item.type }}</td>
                  <td>{{ item.awardedTo }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col class="d-flex justify-center">
          <h4>Team</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Award
                  </th>
                  <th class="text-left">
                    Team
                  </th>
                  <th class="text-left">
                    Points
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, i) in teamAwards.filter(
                    item => item.awardedTo !== null,
                  )"
                  :key="i"
                >
                  <td>{{ item.type }}</td>
                  <td>{{ item.awardedTo }}</td>
                  <td>{{ item.points }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import helper from '../../mixins/helpers';
export default {
  name: 'Lineups',
  mixins: [helper],
  computed: {
    ...mapState('leagues', ['match', 'league']),
    // motmCandidates() {
    //   const motmCandidates = this.match.matchAwards.filter(awardedPlayer => {
    //     return awardedPlayer.type === 'motm';
    //   }).map(player => {
    //     return player.awardedTo;
    //   })

    //   const filtered = new Set(motmCandidates)
    //   return [...filtered]

    //   // const awardedToOnly = motmCandidates.map(player => {
    //   //   return player.awardedTo;
    //   // });
    // },

    // awards() {
    //   const awards = this.match.matchAwards.map(matchAward => {
    //     return matchAward.type;
    //   });

    //   const uniqueTypes = new Set(awards);

    //   const individualAwards = [];

    //   uniqueTypes.forEach(type => {
    //     individualAwards.push({
    //       type: this.getFriendlyAwardName(type),
    //       awardedTo: this.getMatchAward(type),
    //       points: this.returnPointForEvent(type),
    //     });
    //   });

    //   return individualAwards;
    // },
    teamAwards() {
      const availableTeamPointCategories = this.league.availablePoints.filter(
        point => point.type === 'team',
      );

      return availableTeamPointCategories.map(type => {
        return {
          type: this.getFriendlyAwardName(type.desc),
          awardedTo: this.getTeamAwardedTo(type.desc),
          // awardedTo: 'Player',
          points: this.returnPointForEvent(type.desc),
        };
      });
    },
  },
  methods: {
    getMatchAward(type) {
      const motmCandidates = this.match.matchAwards.filter(awardedPlayer => {
        return awardedPlayer.type === type;
      });

      const awardedToOnly = motmCandidates.map(player => {
        return player.awardedTo;
      });
      // show the awarded to highest player
      return this.find_mode(awardedToOnly);
    },

    getTeamAwardedTo(type) {
      const team1Total = this.match.summary.team1.score;
      const team2Total = this.match.summary.team2.score;

      if (type === 'win') {
        return team1Total > team2Total
          ? this.match.summary.team1.teamName
          : team1Total < team2Total
          ? this.match.summary.team2.teamName
          : null;
      }

      if (type === 'draw') {
        return team1Total === team2Total ? 'Both teams' : null;
      }

      if (type === 'lose') {
        return team1Total > team2Total
          ? this.match.summary.team2.teamName
          : team1Total < team2Total
          ? this.match.summary.team1.teamName
          : null;
      }

      if (type === 'concededUnder1') {
        if (team1Total < 1) {
          return this.match.summary.team2.teamName;
        } else if (team2Total < 1) {
          return this.match.summary.team1.teamName;
        } else {
          return null;
        }
      }

      if (type === 'concededUnder3') {
        if (team1Total < 3 && team1Total > 1) {
          return this.match.summary.team2.teamName;
        } else if (team2Total < 3 && team2Total > 1) {
          return this.match.summary.team1.teamName;
        } else {
          return null;
        }
      }

      if (type === 'concededUnder4') {
        if (team1Total < 4 && team1Total > 2) {
          return this.match.summary.team2.teamName;
        } else if (team2Total < 4 && team2Total > 2) {
          return this.match.summary.team1.teamName;
        } else {
          return null;
        }
      }

      if (type === 'concededUnder5') {
        if (team1Total < 5 && team1Total > 3) {
          return this.match.summary.team2.teamName;
        } else if (team2Total < 5 && team2Total > 3) {
          return this.match.summary.team1.teamName;
        } else {
          return null;
        }
      }
    },

    returnPointForEvent(type) {
      return this.league.availablePoints.find(point => point.desc === type)
        .value;
    },
    AwardedToAndAwardedBy(type) {
      const motmCandidates = this.match.matchAwards.filter(awardedPlayer => {
        return awardedPlayer.type === type;
      }).map(player => {
        return player.awardedTo;
      })

      const filtered = [...new Set(motmCandidates)]

      const fullAwardDetails = []
      filtered.forEach(player => {
          const awardedBy = this.match.matchAwards.filter(award => {
              return award.awardedTo === player && award.type === type;
          });

          awardedBy.forEach(award => {
            const awardDetails = {
              awardedTo: award.awardedTo,
              awardedBy: award.awardedBy,
            }
            fullAwardDetails.push(awardDetails)
          })
      });

      return fullAwardDetails
    },
    convertArray(inputArray) {
      const result = [];

      const groupedByAwardee = {};

      inputArray.forEach(item => {
        const awardedTo = item.awardedTo;
        const awardedBy = item.awardedBy;

        if (!groupedByAwardee[awardedTo]) {
          groupedByAwardee[awardedTo] = { awardedTo, awardedBy: [] };
          result.push(groupedByAwardee[awardedTo]);
        }

        groupedByAwardee[awardedTo].awardedBy.push(awardedBy);
      });

      return result;
    },
    convertToLegibleString(groupedAwardees) {
      const strings = [];
      groupedAwardees.forEach(awardee => {
        const awardedByString = awardee.awardedBy.join(', ');
        awardee.awardedBy = awardedByString;
        strings.push(`${awardee.awardedTo} (${awardee.awardedBy})`);
      });

      return strings.join('; ')
    },
  },
};
</script>

<style></style>
