var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"buttons-view","fluid":"","tag":"section"}},[_c('view-intro',{attrs:{"heading":"League Edit","link":"components/buttons"}}),_c('app-card',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-h5 mb-6"},[_vm._v(" Pick your Color ")]),_vm._l((_vm.colors),function(color,i){return _c('app-btn',{key:i,staticClass:"mr-2 mb-2",attrs:{"color":color}},[_vm._v(" "+_vm._s(color)+" ")])})],2),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-h5 mb-6"},[_vm._v(" Buttons with Label ")]),_vm._l((_vm.colors),function(color,i){return _c('app-btn',{key:i,staticClass:"mr-2 mb-2",attrs:{"color":color}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s(_vm.icons[i])}}),_vm._v(" "+_vm._s(color)+" ")],1)})],2),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-h5 mb-6"},[_vm._v(" Pick your Size ")]),_vm._l((_vm.sizes),function(size,i){
var _obj;
return _c('app-btn',_vm._b({key:i,staticClass:"mr-2",attrs:{"color":"secondary"}},'app-btn',( _obj = {}, _obj[size] = true, _obj ),false),[_vm._v(" "+_vm._s(size)+" ")])})],2),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-h5 mb-6"},[_vm._v(" Pick your Style ")]),_vm._l((_vm.styles),function(style,i){return _c('app-btn',_vm._b({key:i,staticClass:"mr-2 mb-2",attrs:{"color":"secondary"}},'app-btn',style.attrs,false),[(style.icon)?_c('v-icon',{attrs:{"left":!!style.text},domProps:{"textContent":_vm._s(style.icon)}}):_vm._e(),_vm._v(" "+_vm._s(style.text)+" ")],1)})],2),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-h5 mb-6"},[_vm._v(" Pagination ")]),_c('div',{staticClass:"d-flex flex-column align-start"},[_c('v-pagination',{attrs:{"value":1,"length":5,"circle":""}}),_c('v-pagination',{attrs:{"value":2,"length":5,"circle":""}})],1)]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-h5 mb-6"},[_vm._v(" Button Group ")]),_vm._l((_vm.groups),function(group,i){return _c('v-btn-toggle',{key:i,staticClass:"mb-2",attrs:{"rounded":i !== 0,"color":"teal"}},_vm._l((group),function(text){return _c('v-btn',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),1)})],2),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h5 mb-6"},[_vm._v(" Social buttons ")]),_vm._l((_vm.social),function(s,i){return _c('div',{key:i},[_c('div',{staticClass:"d-flex"},_vm._l((5),function(n){return _c('div',{key:n,staticClass:"mr-2 mb-2",style:(n === 1 && 'width: 250px')},[_c('app-btn',_vm._b({attrs:{"color":s.color,"dark":""}},'app-btn',{
                  fab: n === 3,
                  small: n === 3,
                  text: n === 5,
                  icon: n === 4,
                },false),[(s.icon)?_c('v-icon',{attrs:{"left":n === 1 || n === 5},domProps:{"textContent":_vm._s(s.icon)}}):_vm._e(),(n === 1 || n === 5)?_c('span',{domProps:{"textContent":_vm._s(s.text)}}):_vm._e()],1)],1)}),0)])})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }