<template>
  <v-container id="dashboard-view" fluid tag="section">
    <view-intro heading="Tuesday Footy Stats" />
    <v-card class="mb-2 pa-2" :loading="false">
      <v-row>
        <v-col cols="6">
          <v-btn
            color="primary"
            block
            :disabled="statsLoadingStatus"
            x-small
            @click="getStatsPublic('63b439285de373a32c87afe2')"
          >
            2023
          </v-btn>
          <v-btn
            color="primary"
            block
            :disabled="statsLoadingStatus"
            x-small
            @click="getStatsPublic('61d1bf2c38e40bf15687fa44')"
          >
            2022
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            color="primary"
            block
            :disabled="statsLoadingStatus"
            x-small
            @click="getStatsPublic('60808cf35dd6248d301fae26')"
          >
            2021
          </v-btn>
        </v-col>
        <v-col cols="12" class="mt-2">
          <span class="lastUpdated hidden-sm-and-down ml-2"
            >Last Updated:
            <span class="boldText">{{ lastUpdatedText }}</span></span
          >
          <span class="lastUpdatedSM hidden-md-and-up ml-2"
            >Last Updated:
            <span class="boldText">{{ lastUpdatedText }}</span></span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-tabs v-model="tab" background-color="primary" center-active dark>
            <v-tab :key="1" :href="`#tab-${1}`">
              Full Table
            </v-tab>
            <v-tab :key="2" :href="`#tab-${2}`">
              Top 10s &amp; Averages
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" touchless>
            <v-tab-item :key="1" :value="'tab-' + 1">
              <v-row class="mt-3">
                <v-col class="hidden-sm-and-down">
                  <v-btn
                    elevation="2"
                    small
                    class="mx-2"
                    color="primary"
                    @click="statSelection('all')"
                  >
                    All
                  </v-btn>
                  <v-btn
                    elevation="2"
                    small
                    class="ml-2"
                    color="success"
                    @click="statSelection('attack')"
                  >
                    Attack
                    <v-icon right dark>
                      mdi-sword
                    </v-icon>
                  </v-btn>
                  <v-btn
                    elevation="2"
                    small
                    class="ml-2"
                    color="success"
                    @click="statSelection('defense')"
                  >
                    Defense
                    <v-icon right dark>
                      mdi-shield
                    </v-icon>
                  </v-btn>
                  <v-btn
                    elevation="2"
                    small
                    class="ml-2"
                    color="success"
                    @click="statSelection('matchawards')"
                  >
                    Awards
                    <v-icon right dark>
                      mdi-trophy
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col class="hidden-md-and-up">
                  <v-btn
                    elevation="2"
                    x-small
                    class="mx-2"
                    color="primary"
                    @click="statSelection('all')"
                  >
                    All
                  </v-btn>
                  <v-btn
                    elevation="2"
                    x-small
                    class="ml-2"
                    color="success"
                    @click="statSelection('attack')"
                  >
                    Attack
                    <v-icon right dark x-small>
                      mdi-sword
                    </v-icon>
                  </v-btn>
                  <v-btn
                    elevation="2"
                    x-small
                    class="ml-2"
                    color="success"
                    @click="statSelection('defense')"
                  >
                    Defense
                    <v-icon right dark x-small>
                      mdi-shield
                    </v-icon>
                  </v-btn>
                  <v-btn
                    elevation="2"
                    x-small
                    class="ml-2"
                    color="success"
                    @click="statSelection('matchawards')"
                  >
                    Awards
                    <v-icon right dark x-small>
                      mdi-trophy
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    v-if="stats"
                    :headers="headers"
                    :items="stats"
                    sort-by="points"
                    :sort-desc="true"
                    :items-per-page="10"
                    :mobile-breakpoint="0"
                    class="table-striped"
                    :loading="statsLoadingStatus"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item :key="2" :value="'tab-' + 2">
              <v-row v-if="stats" dense>
                <v-col xl="4" lg="4" md="6" sm="12">
                  <top-ten type="points" />
                </v-col>
                <v-col xl="4" lg="4" md="6" sm="12">
                  <top-ten type="goals" />
                </v-col>
                <v-col xl="4" lg="4" md="6" sm="12">
                  <top-ten type="assists" />
                </v-col>
                <v-col xl="4" lg="4" md="6" sm="12">
                  <top-ten type="wins" />
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { format, parseISO } from 'date-fns';
export default {
  components: {
    TopTen: () =>
      import(
        /* webpackChunkName: "default-account-settings" */
        '../components/Dashboard/TopTen.vue'
      ),
  },
  data() {
    return {
      loading: true,
      tab: null,
      headers: [
        { text: 'Player', value: 'name', width: '50px', fixed: true },
        { text: 'Points', value: 'points' },
        { text: 'G', value: 'games' },
        { text: 'W', value: 'wins' },
        { text: 'D', value: 'draws' },
        { text: 'L', value: 'losses' },
        { text: 'Goals', value: 'goals' },
        { text: 'Assists', value: 'assists' },
        { text: 'OGs', value: 'ownGoals' },
        { text: 'PC', value: 'penaltyConceded' },
        { text: 'PM', value: 'penaltyMiss' },
        { text: 'PS', value: 'penaltySave' },
        { text: 'MoTM', value: 'motm' },
        { text: 'GoTM', value: 'gotm' },
        { text: 'DoTM', value: 'dotm' },
        { text: 'CU 5', value: 'concededUnder5' },
        { text: 'CU 4', value: 'concededUnder4' },
        { text: 'CU 3', value: 'concededUnder3' },
        { text: 'CU 1', value: 'concededUnder1' },
      ],
      format,
      parseISO,
    };
  },
  computed: {
    ...mapState('auth', ['customToken', 'idToken']),
    ...mapState('stats', ['stats', 'statsLoadingStatus', 'lastUpdated']),
    lastUpdatedText() {
      if (this.lastUpdated !== null) {
        return (
          this.lastUpdated.match.description +
          ' - ' +
          format(parseISO(this.lastUpdated.match.date), 'E do MMM yyyy K:mmbbb')
        );
      } else {
        return 'No matches have been recorded yet for 2022!';
      }
    },
  },
  async mounted() {
    const leagueId2022 = '61d1bf2c38e40bf15687fa44';
    await this.getStatsPublic(leagueId2022);
  },
  methods: {
    ...mapActions('auth', [
      'getCustomTokenAndRetrieveStats',
      'getCustomToken',
      'getIdToken',
    ]),
    ...mapActions('stats', ['getStatsPublic']),
    statSelection(type) {
      switch (type) {
        case 'all':
          this.headers = [
            { text: 'Player', value: 'name', width: '50px', fixed: true },
            { text: 'Points', value: 'points' },
            { text: 'G', value: 'games' },
            { text: 'W', value: 'wins' },
            { text: 'D', value: 'draws' },
            { text: 'L', value: 'losses' },
            { text: 'Goals', value: 'goals' },
            { text: 'Assists', value: 'assists' },
            { text: 'OGs', value: 'ownGoals' },
            { text: 'PC', value: 'penaltyConceded' },
            { text: 'PM', value: 'penaltyMiss' },
            { text: 'PS', value: 'penaltySave' },
            { text: 'MoTM', value: 'motm' },
            { text: 'GoTM', value: 'gotm' },
            { text: 'DoTM', value: 'dotm' },
            { text: 'CU 5', value: 'concededUnder5' },
            { text: 'CU 4', value: 'concededUnder4' },
            { text: 'CU 3', value: 'concededUnder3' },
            { text: 'CU 1', value: 'concededUnder1' },
          ];
          break;
        case 'attack':
          this.headers = [
            { text: 'Player', value: 'name', width: '50px', fixed: true },
            { text: 'Games', value: 'games' },
            { text: 'Goals', value: 'goals' },
            { text: 'Assists', value: 'assists' },
          ];
          break;
        case 'defense':
          this.headers = [
            { text: 'Player', value: 'name', width: '50px', fixed: true },
            { text: 'Games', value: 'games' },
            { text: 'PS', value: 'penaltySave' },
            { text: 'DoTM', value: 'dotm' },
            { text: 'CU 5', value: 'concededUnder5' },
            { text: 'CU 4', value: 'concededUnder4' },
            { text: 'CU 3', value: 'concededUnder3' },
            { text: 'CU 1', value: 'concededUnder1' },
          ];
          break;
        case 'matchawards':
          this.headers = [
            { text: 'Player', value: 'name', width: '50px', fixed: true },
            { text: 'Games', value: 'games' },
            { text: 'MoTM', value: 'motm' },
            { text: 'GoTM', value: 'gotm' },
            { text: 'DoTM', value: 'dotm' },
          ];
          break;
        default:
          this.headers = [
            { text: 'Player', value: 'name', width: '50px', fixed: true },
            { text: 'Points', value: 'points' },
            { text: 'G', value: 'games' },
            { text: 'W', value: 'wins' },
            { text: 'D', value: 'draws' },
            { text: 'L', value: 'losses' },
            { text: 'Goals', value: 'goals' },
            { text: 'Assists', value: 'assists' },
            { text: 'OGs', value: 'ownGoals' },
            { text: 'PC', value: 'penaltyConceded' },
            { text: 'PM', value: 'penaltyMiss' },
            { text: 'PS', value: 'penaltySave' },
            { text: 'MoTM', value: 'motm' },
            { text: 'GoTM', value: 'gotm' },
            { text: 'DoTM', value: 'dotm' },
            { text: 'CU 5', value: 'concededUnder5' },
            { text: 'CU 4', value: 'concededUnder4' },
            { text: 'CU 3', value: 'concededUnder3' },
            { text: 'CU 1', value: 'concededUnder1' },
          ];
          break;
      }
    },
  },
};
</script>

<style>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.lastUpdated {
  font-size: 0.8em;
  font-style: italic;
  color: gray;
}

.lastUpdatedSM {
  font-size: 0.7em;
  font-style: italic;
  color: gray;
}

.boldText {
  font-weight: bold;
}
</style>
