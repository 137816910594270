<template>
  <v-card :loading="loading" class="mx-auto my-12">
    <template slot="progress">
      <v-progress-linear color="primary" height="5" indeterminate />
    </template>

    <v-card-title class="text-h5">
      League Table
    </v-card-title>

    <v-sheet color="primary" elevation="1" height="10" />

    <!-- <v-img
      height="20"
      :src="require(`@/assets/${statsImage}`)"
    /> -->

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="stats"
        sort-by="points"
        :sort-desc="true"
        :items-per-page="10"
        class="table-striped"
        mobile-breakpoint="0"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { titleCase } from '@/util/helpers';
export default {
  name: 'TopTen',
  props: {
    type: String,
  },
  data: () => ({
    selection: 1,
    statsImage: 'stats.jpg',
    headers: [
      // {
      //   text: 'Pos',
      //   align: 'start',
      //   sortable: false,
      //   value: 'position',
      // },
      { text: 'Player', value: 'name' },
      { text: 'Points', value: 'points' },
      { text: 'G', value: 'games' },
      { text: 'W', value: 'wins' },
      { text: 'D', value: 'draws' },
      { text: 'L', value: 'losses' },
      { text: 'Goals', value: 'goals' },
      { text: 'Assists', value: 'assists' },
      { text: 'OGs', value: 'ownGoals' },
      { text: 'PC', value: 'penaltyConceded' },
      { text: 'PM', value: 'penaltyMiss' },
      { text: 'PS', value: 'penaltySave' },
      { text: 'MoTM', value: 'motm' },
      { text: 'GoTM', value: 'gotm' },
      { text: 'DoTM', value: 'dotm' },
      { text: '< 5', value: 'concededUnder5' },
      { text: '< 4', value: 'concededUnder4' },
      { text: '< 3', value: 'concededUnder3' },
      { text: 'Clean Sheet', value: 'concededUnder1' },
    ],
  }),
  computed: {
    ...mapState('stats', ['stats']),
    // ...mapGetters('stats', ['getStats2021Ordered']),
    typeTitle() {
      return titleCase(this.type);
    },
    loading() {
      return !this.stats;
    },
  },
};
</script>
<style></style>
