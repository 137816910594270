<template>
  <div>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css"
      integrity="sha256-h20CPZ0QyXlBuAw7A+KluUYx/3pK+c7lYEpqLTlxjYQ="
      crossorigin="anonymous"
    />

    <v-card>
      <v-row class="mt-5">
        <v-col cols="3">
          <v-btn
            class="float-right ml-2 mb-4"
            small
            block
            color="primary"
            @click="showAllPlayersList"
          >
            <span v-if="!showAllPlayers">
              Show All Players
            </span>
            <span v-else>
              Show Only The 14
            </span>
          </v-btn>
          <draggable
            :list="!showAllPlayers ? activePlayers : allActivePlayers"
            group="players"
            class="dragDropArea"
            :style="{ height: heightOfPlayersList + 'px' }"
          >
            <v-btn
              v-for="player in !showAllPlayers
                ? activePlayers
                : allActivePlayers"
              :key="player.nickname"
              x-small
              block
              class="mt-2"
            >
              {{ player.order }} | {{ player.nickname }}
            </v-btn>
          </draggable>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="selectedTeam1.teamName"
            :items="getAllTeamsDDL"
            label="Team 1"
          />
          <v-select
            v-model="selectedTeam1.colour"
            :items="getAllTeamColoursDDL"
            label="Colour"
          />
          <draggable
            :list="arrTeam1"
            group="players"
            class="dragDropArea"
            :style="{
              backgroundColor:
                selectedTeam1.colour === 'blue'
                  ? '#2196f3'
                  : selectedTeam1.colour,
            }"
          >
            <v-btn
              v-for="player in arrTeam1"
              :key="player.nickname"
              x-small
              block
              class="mt-2"
            >
              {{ player.nickname }}
            </v-btn>
          </draggable>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="selectedTeam2.teamName"
            :items="getAllTeamsDDL"
            label="Team 2"
          />
          <v-select
            v-model="selectedTeam2.colour"
            :items="getAllTeamColoursDDL"
            label="Colour"
          />
          <draggable
            :list="arrTeam2"
            group="players"
            class="dragDropArea"
            :style="{
              backgroundColor:
                selectedTeam2.colour === 'blue'
                  ? '#2196f3'
                  : selectedTeam2.colour,
            }"
          >
            <v-btn
              v-for="player in arrTeam2"
              :key="player.nickname"
              x-small
              block
              class="mt-2"
            >
              {{ player.nickname }}
            </v-btn>
          </draggable>
        </v-col>
        <v-col cols="3">
          <pre>{{ description }}</pre>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            v-if="user.email === 'rahulvaghela@hotmail.com'"
            class="float-right"
            small
            color="primary"
            @click="callSaveTeams"
          >
            Save Teams
          </v-btn>
          <ShareNetwork
            id="whatsapp-share"
            network="whatsapp"
            url=""
            :title="whatsappShareTitle()"
            :description="whatsappShareTeams()"
            quote="The hot reload is so fast it\'s near instant. - Evan You"
            hashtags="vuejs,vite"
            :popup="{ width: 400, height: 200 }"
            class="float-right"
          >
            <v-btn color="#25d366" class="ma-2 white--text" small>
              <v-icon small>
                mdi-whatsapp
              </v-icon>
              <span id="share-button-text">Share</span>
            </v-btn>
          </ShareNetwork>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapState, mapActions, mapGetters } from 'vuex';
import objectId from 'bson-objectid';
import Table from 'easy-table';
import { format } from 'date-fns';
export default {
  name: 'TeamSelection',
  components: {
    draggable,
  },
  props: {
    players: Array,
  },
  data() {
    return {
      format,
      title: 'Team Selection',
      arrTeam1: [],
      arrTeam2: [],
      showAllPlayers: false,
      heightOfPlayersList: 385,
      network: {
        network: 'whatsapp',
        name: 'Whatsapp',
        icon: 'fab fah fa-lg fa-whatsapp',
        color: '#25d366',
      },
      selectedTeam1: {
        // default values
        teamName: 'teamA',
        colour: 'blue',
      },
      selectedTeam2: {
        // default values
        teamName: 'teamB',
        colour: 'orange',
      },
    };
  },
  computed: {
    ...mapState('leagues', ['league', 'match']),
    ...mapState('auth', ['user']),
    ...mapGetters('leagues', [
      'getPlayerList',
      'getAllTeamColoursDDL',
      'getAllTeamsDDL',
    ]),
    activePlayers() {
      return this.getPlayerList
        .filter(player => {
          return player.order !== -1;
        })
        .filter(player => player.order <= 14)

        .filter(player => {
          // remove players from the arrTeam1
          const result = this.arrTeam1.filter(team1Player => {
            return team1Player.nickname === player.nickname;
          });
          return result.length === 0;
        })
        .filter(player => {
          // remove players from the arrTeam1
          const result = this.arrTeam2.filter(team2Player => {
            return team2Player.nickname === player.nickname;
          });
          return result.length === 0;
        })
        .sort((a, b) => a.order - b.order);
    },
    allActivePlayers() {
      return this.getPlayerList
        .filter(player => {
          return player.order !== -1;
        })
        .filter(player => {
          // remove players from the arrTeam1
          const result = this.arrTeam1.filter(team1Player => {
            return team1Player.nickname === player.nickname;
          });
          return result.length === 0;
        })
        .filter(player => {
          // remove players from the arrTeam2
          const result = this.arrTeam2.filter(team2Player => {
            return team2Player.nickname === player.nickname;
          });
          return result.length === 0;
        })
        .sort((a, b) => a.order - b.order);
    },
    description() {
      if (
        this.arrTeam1.length < 7 ||
        this.arrTeam2.length < 7 ||
        this.arrTeam1.length > 7 ||
        this.arrTeam2.length > 7
      ) {
        return '';
      }

      var x = new Table();

      for (let i = 0; i < 7; i++) {
        x.cell(
          `🔵 ${this.selectedTeam1.colour.toUpperCase()}S       `,
          this.arrTeam1[i].nickname ? this.arrTeam1[i].nickname : '',
        );
        x.cell(
          `🟠 ${this.selectedTeam2.colour.toUpperCase()}S      `,
          this.arrTeam2[i].nickname ? this.arrTeam2[i].nickname : '',
        );
        x.newRow();
      }

      x.newRow();
      x = x + ' - created in 5asidestats.co.uk';

      return x.toString();
    },
  },
  mounted() {
    // set the orange array
    this.match.teams.forEach(player => {
      if (player.colour === 'blue') {
        this.arrTeam1.push(player);
      } else {
        this.arrTeam2.push(player);
      }
    });
  },
  methods: {
    ...mapActions('leagues', ['saveTeams']),
    ...mapActions('admin', ['confirmedTeamsEmail']),
    showAllPlayersList() {
      this.showAllPlayers = !this.showAllPlayers;
      this.showAllPlayers
        ? (this.heightOfPlayersList = 635)
        : (this.heightOfPlayersList = 385);
    },
    async callSaveTeams() {
      const teamsArray = [];

      this.arrTeam1.forEach(player => {
        teamsArray.push({
          nickname: player.nickname,
          playerId: player.playerId || objectId(player._id),
          teamName: this.selectedTeam1.teamName,
          colour: this.selectedTeam1.colour,
        });
      });

      this.arrTeam2.forEach(player => {
        teamsArray.push({
          nickname: player.nickname,
          playerId: player.playerId || objectId(player._id),
          teamName: this.selectedTeam2.teamName,
          colour: this.selectedTeam2.colour,
        });
      });

      const matchId = objectId(this.$route.params.matchId);
      const payload = {
        matchId: matchId,
        teams: teamsArray,
        matchIdAsString: this.$route.params.matchId,
        email: 'rahulvaghela@hotmail.com',
      };

      await this.saveTeams(payload);
      // await this.confirmedTeamsEmail(payload);
    },
    whatsappShareTitle() {
      return `*${this.match.description} - ${format(
        new Date(this.match.date),
        'E do MMM h:mmaaa',
      )}* `;
    },
    whatsappShareTeams() {
      if (
        this.arrTeam1.length < 7 ||
        this.arrTeam2.length < 7 ||
        this.arrTeam1.length > 7 ||
        this.arrTeam2.length > 7
      ) {
        return '';
      }

      var x = new Table();

      for (let i = 0; i < 7; i++) {
        x.cell(
          `🔵 ${this.selectedTeam1.colour.toUpperCase()}S`,
          this.arrTeam1[i].nickname ? this.arrTeam1[i].nickname : '',
        );
        x.cell(
          `🟠 ${this.selectedTeam2.colour.toUpperCase()}S`,
          this.arrTeam2[i].nickname ? this.arrTeam2[i].nickname : '',
        );
        x.newRow();
      }

      x.newRow();
      x = x + ' - created in 5asidestats.co.uk';

      return x.toString();
    },
  },
};
</script>

<style scoped>
.dragDropArea {
  width: 100%;
  height: 217px;
  border: 1px dashed grey;
  border-radius: 5px;
  line-height: 60px;
  text-align: center;
  color: #333333;
  padding: 5px;
}

#whatsapp-share {
  position: relative;
  bottom: 7px;
}

#whatsapp-icon {
  border: 1px solid blue;
}

#share-button-text {
  margin-left: 5px;
}
</style>
