<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    @keydown.esc="dialog = false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on" @click="reset">
        Add Highlight
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Add Highlight</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="4">
              <v-switch
                v-model="footage"
                :label="`${footage ? 'Video available' : 'No video'}`"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                ref="min"
                v-model="min.val"
                label="Mins"
                type="number"
                :rules="minRules"
                :disabled="!footage"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="secs.val"
                label="Seconds"
                type="number"
                :rules="secRules"
                :disabled="!footage"
              />
            </v-col>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="description"
                  name="description"
                  label="Description"
                  hint="Describe the highlight"
                  counter="100"
                  :rules="[
                    v =>
                      (v || '').length <= 200 ||
                      'Description must be 100 characters or less',
                  ]"
                  auto-grow
                />
              </v-col>
            </v-row>
            <v-row v-show="false">
              <v-col>
                <v-slider
                  v-model="min.val"
                  :label="min.label"
                  :thumb-color="min.color"
                  thumb-label="always"
                  :min="0"
                  :max="100"
                />
              </v-col>
              <v-col cols="6">
                <v-slider
                  v-model="secs.val"
                  :label="secs.label"
                  :thumb-color="secs.color"
                  thumb-label="always"
                  :min="0"
                  :max="59"
                />
              </v-col>
            </v-row>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="saveHighlight()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { addMinutes, addSeconds, intervalToDuration } from 'date-fns';
export default {
  name: 'AddHighlight',
  props: {
    players: Array,
  },
  data: () => ({
    addMinutes,
    addSeconds,
    intervalToDuration,
    dialog: false,
    min: { label: 'Mins', val: 0, color: 'red' },
    secs: { label: 'Secs', val: 0, color: 'blue' },
    minRules: [
      v => !!v || 'This field is required',
      v => (v && v >= 0) || 'Mins have to be 0 or more',
      v => (v && v <= 100) || 'Should not be above 100*',
    ],
    secRules: [
      v => !!v || 'This field is required',
      v => (v && v >= 0) || 'Secs have to be 0 or more',
      v => (v && v <= 59) || 'Should be less than 60',
    ],
    footage: true,
    description: '',
  }),
  computed: {
    ...mapState('leagues', ['match']),
    ...mapState('auth', ['user']),
    orangePlayers() {
      return this.playersCopy.filter(p => {
        return p.colour === 'orange';
      });
    },
    bluePlayers() {
      return this.playersCopy.filter(p => {
        return p.colour === 'blue';
      });
    },
  },
  mounted() {
    this.reset();
  },
  methods: {
    ...mapActions('leagues', ['addHighlight']),
    async saveHighlight() {
      const leagueId = this.match.leagueId;
      const matchId = this.match._id;
      const min = this.footage ? parseInt(this.min.val) : -1;
      const sec = this.footage ? parseInt(this.secs.val) : -1;
      const payload = {
        matchId: matchId,
        min: min,
        sec: sec,
        submittedBy: this.user.nickname,
        submittedById: this.user._id,
        description: this.description,
      };

      await this.addHighlight(payload);

      // await this.getMatchHighlights(this.match._id)

      this.dialog = false;
    },
    reset() {
      const players = new Set(this.players);
      this.playersCopy = [...players];
    },
  },
};
</script>
