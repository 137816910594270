<template>
  <div>
    <!-- <v-sheet
      v-if="statsLoadingStatus"
      color="grey lighten-4"
      class="pa-3"
    >
      <v-skeleton-loader
        class="mx-auto"
        max-height="250"
        type="table"
      />
    </v-sheet> -->
    <v-simple-table v-if="selectedStats.length > 0" dense>
      <template v-slot:default>
        <tbody>
          <tr v-for="item in selectedStats" :key="item.type">
            <td>{{ item.type }}</td>
            <td>{{ item.number }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-alert v-else border="top" colored-border type="info" elevation="2">
      No stats associated with this player in the season selected
    </v-alert>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'IndividualStats',
  props: {
    selectedStats: Array,
  },
  computed: {
    ...mapState('stats', ['stats', 'statsLoadingStatus', 'lastUpdated']),
  },
};
</script>

<style></style>
