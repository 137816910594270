<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col>
          <template>
            <v-icon class="mb-1 mr-1">
              mdi-calendar-arrow-left
            </v-icon>
            <span class="ml-2 pt-5 text-sm-h4">Recent Form</span>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-combobox
            v-model="selectedPlayer"
            cols="12"
            :items="getPlayerListDDL"
            label="Select a player"
            hide-selected
            @change="updatePlayerStats"
            item-text="text"
            item-value="value"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="12">
          <span class="ml-2 pt-5 text-sm-h5">Numbers</span>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" />
                  <th class="text-left">
                    Per game
                  </th>
                  <th class="text-left">
                    Total
                  </th>
                  <th class="text-left">
                    Average
                  </th>
                  <th class="text-left">
                    Season Average
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Goals</td>
                  <td v-if="lastXStats">
                    <span v-for="(item, i) in lastXStats.goals" :key="i">
                      <span>
                        <v-icon v-if="item.count > 0" color="green">
                          {{ `mdi-numeric-${item.count}-circle` }}
                        </v-icon>
                        <v-icon v-else-if="item.count === 0">
                          mdi-numeric-0-circle
                        </v-icon>
                      </span>
                    </span>
                  </td>
                  <td>
                    {{ lastXStatsGoalsTotal }}
                  </td>
                  <td>
                    {{ lastXStatsGoalsAverage }}
                    <v-icon
                      v-if="
                        lastXStatsGoalsAverage > lastXStatsGoalsSeasonAverage
                      "
                      small
                      color="green"
                    >
                      mdi-arrow-up-bold
                    </v-icon>
                    <v-icon
                      v-else-if="
                        lastXStatsGoalsAverage === lastXStatsGoalsSeasonAverage
                      "
                      color="orange"
                      small
                    >
                      mdi-arrow-left-right-bold
                    </v-icon>
                    <v-icon v-else color="red" small>
                      mdi-arrow-down-bold
                    </v-icon>
                  </td>
                  <td>
                    {{ lastXStatsGoalsSeasonAverage }}
                  </td>
                </tr>
                <tr>
                  <td>Assists</td>
                  <td v-if="lastXStats">
                    <span v-for="(item, i) in lastXStats.assists" :key="i">
                      <span>
                        <v-icon v-if="item.count > 0" color="green">
                          {{ `mdi-numeric-${item.count}-circle` }}
                        </v-icon>
                        <v-icon v-else-if="item.count === 0">
                          mdi-numeric-0-circle
                        </v-icon>
                      </span>
                    </span>
                  </td>
                  <td>
                    {{ lastXStatsAssistsTotal }}
                  </td>
                  <td>
                    {{ lastXStatsAssistsAverage }}
                    <v-icon
                      v-if="
                        lastXStatsAssistsAverage >
                          lastXStatsAssistsSeasonAverage
                      "
                      small
                      color="green"
                    >
                      mdi-arrow-up-bold
                    </v-icon>
                    <v-icon
                      v-else-if="
                        lastXStatsAssistsAverage ===
                          lastXStatsAssistsSeasonAverage
                      "
                      color="orange"
                      small
                    >
                      mdi-arrow-left-right-bold
                    </v-icon>
                    <v-icon v-else color="red" small>
                      mdi-arrow-down-bold
                    </v-icon>
                  </td>
                  <td>
                    {{ lastXStatsAssistsSeasonAverage }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="12">
          <span class="ml-2 pt-5 text-sm-h5">Most recent...</span>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" />
                  <th class="text-left" />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Goal</td>
                  <td>
                    <!-- <v-btn
                      elevation="2"
                      x-small
                      class="mx-2"
                      color="primary"
                      :to="mostRecentGoal.url"
                    >
                      {{ mostRecentGoal.desc }} ({{ mostRecentGoal.date }})
                    </v-btn> -->
                    {{ mostRecentGoal }}
                  </td>
                </tr>
                <tr>
                  <td>Hat-trick (goals)</td>
                  <td>{{ mostRecentGoalHattrick }}</td>
                </tr>
                <tr>
                  <td>3+ goals</td>
                  <td>{{ mostRecentGoalMoreThanThree }}</td>
                </tr>
                <tr>
                  <td>Assists</td>
                  <td>{{ mostRecentAssist }}</td>
                </tr>
                <tr>
                  <td>Hat-trick (assists)</td>
                  <td>{{ mostRecentAssistHattrick }}</td>
                </tr>
                <tr>
                  <td>3+ assists</td>
                  <td>{{ mostRecentAssistMoreThanThree }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { format, parseISO } from 'date-fns';
export default {
  name: 'Fun',
  data() {
    return {
      format,
      parseISO,
      selectedPlayer: null,
      mostRecentGoal: 'Not yet!',
      mostRecentGoalHattrick: 'Not yet!',
      mostRecentGoalMoreThanThree: 'Not yet!',
      mostRecentAssist: 'Not yet!',
      mostRecentAssistHattrick: 'Not yet!',
      mostRecentAssistMoreThanThree: 'Not yet!',
    };
  },
  computed: {
    ...mapGetters('leagues', ['getPlayerList', 'getPlayerListDDL']),
    ...mapState('auth', ['user']),
    ...mapState('stats', ['lastXStats', 'goalsAndAssistsCount']),
    ...mapState('leagues', ['league', 'matches']),
    lastXStatsGoalsTotal() {
      if (!this.lastXStats) return 0;
      const res = this.lastXStats.goals.find(item => {
        return item.total;
      });
      return res ? res.total : 0;
    },
    lastXStatsGoalsAverage() {
      if (!this.lastXStats) return 0;
      const res = this.lastXStats.goals.find(item => {
        return item.average;
      });
      return res ? res.average.toFixed(2) : 0;
    },
    lastXStatsGoalsSeasonAverage() {
      if (!this.lastXStats) return 0;
      const res = this.lastXStats.goals.find(item => {
        return item.seasonTotalAverage;
      });
      return res ? res.seasonTotalAverage.toFixed(2) : 0;
    },
    lastXStatsAssistsTotal() {
      if (!this.lastXStats) return 0;
      const res = this.lastXStats.assists.find(item => {
        return item.total;
      });
      return res ? res.total : 0;
    },
    lastXStatsAssistsAverage() {
      if (!this.lastXStats) return 0;
      const res = this.lastXStats.assists.find(item => {
        return item.average;
      });
      return res ? res.average.toFixed(2) : 0;
    },
    lastXStatsAssistsSeasonAverage() {
      if (!this.lastXStats) return 0;
      const res = this.lastXStats.assists.find(item => {
        return item.seasonTotalAverage;
      });
      return res ? res.seasonTotalAverage.toFixed(2) : 0;
    },
  },
  async mounted() {
    this.selectedPlayer = { text: this.user.nickname, value: this.user._id };
    await this.updatePlayerStats();
  },
  methods: {
    ...mapActions('stats', ['getLastXStats', 'getGoalsAndAssistsCount']),
    async updatePlayerStats() {
      await this.getAllLastXStats();
      await this.getAllGoalsAndAssistsCount();
    },
    async getAllLastXStats() {
      const payload = {
        playerId: this.selectedPlayer.text,
        leagueId: this.league._id.toString(),
        noOfGamesLimit: 6,
        type: 'OVERWRITTEN IN STORE',
      };
      await this.getLastXStats(payload);
    },
    async getAllGoalsAndAssistsCount() {
      const payload = {
        playerId: this.selectedPlayer.text,
        leagueId: this.league._id.toString(),
      };
      await this.getGoalsAndAssistsCount(payload);
      this.getMostRecents();
    },
    resetMostRecents() {
      this.mostRecentGoal = 'Not yet!';
      this.mostRecentGoalHattrick = 'Not yet!';
      this.mostRecentGoalMoreThanThree = 'Not yet!';
      this.mostRecentAssist = 'Not yet!';
      this.mostRecentAssistHattrick = 'Not yet!';
      this.mostRecentAssistMoreThanThree = 'Not yet!';
    },
    getMostRecents() {
      this.resetMostRecents();
      let foundGoal = false;
      let foundHattrick = false;
      let foundMostRecentGoalMoreThanThree = false;
      let foundAssist = false;
      let foundAssistsHattrick = false;
      let foundAssistsMostRecentGoalMoreThanThree = false;

      this.goalsAndAssistsCount.forEach(row => {
        if (foundGoal === false) {
          if (row.goals > 0) {
            foundGoal = true;
            this.mostRecentGoal = `${
              this.getMatchDetails(row.matchId)[0].name
            } (${format(
              new Date(this.getMatchDetails(row.matchId)[0].date),
              'E do MMM yyyy K:mmbbb',
            )})`;
            // this.mostRecentGoal = {
            //   desc: this.getMatchDetails(row.matchId)[0].description,
            //   date: format(parseISO(this.getMatchDetails(row.matchId)[0].date), 'E do MMM yyyy K:mmbbb'),
            //   url: { name: 'LeagueMatch', params: { leagueId: this.getMatchDetails(row.matchId)[0].leagueId, matchId: this.getMatchDetails(row.matchId)[0]._id } },
            // }
          }
        }
        if (foundHattrick === false) {
          if (row.goals > 2) {
            foundHattrick = true;
            this.mostRecentGoalHattrick = `${
              this.getMatchDetails(row.matchId)[0].name
            } (${format(
              new Date(this.getMatchDetails(row.matchId)[0].date),
              'E do MMM yyyy K:mmbbb',
            )})`;
          }
        }
        if (foundMostRecentGoalMoreThanThree === false) {
          if (row.goals > 3) {
            foundMostRecentGoalMoreThanThree = true;
            this.mostRecentGoalMoreThanThree = `${
              this.getMatchDetails(row.matchId)[0].name
            } (${format(
              new Date(this.getMatchDetails(row.matchId)[0].date),
              'E do MMM yyyy K:mmbbb',
            )})`;
          }
        }
        if (foundAssist === false) {
          if (row.assists > 0) {
            foundAssist = true;
            this.mostRecentAssist = `${
              this.getMatchDetails(row.matchId)[0].name
            } (${format(
              new Date(this.getMatchDetails(row.matchId)[0].date),
              'E do MMM yyyy K:mmbbb',
            )})`;
          }
        }
        if (foundAssistsHattrick === false) {
          if (row.assists > 2) {
            foundAssistsHattrick = true;
            this.mostRecentAssistHattrick = `${
              this.getMatchDetails(row.matchId)[0].name
            } (${format(
              new Date(this.getMatchDetails(row.matchId)[0].date),
              'E do MMM yyyy K:mmbbb',
            )})`;
          }
        }
        if (foundAssistsMostRecentGoalMoreThanThree === false) {
          if (row.assists > 3) {
            foundAssistsMostRecentGoalMoreThanThree = true;
            this.mostRecentAssistMoreThanThree = `${
              this.getMatchDetails(row.matchId)[0].name
            } (${format(
              new Date(this.getMatchDetails(row.matchId)[0].date),
              'E do MMM yyyy K:mmbbb',
            )})`;
          }
        }
      });
    },
    getMatchDetails(matchId) {
      return this.matches.filter(match => match._id.toString() === matchId);
    },
  },
};
</script>

<style></style>
