var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getMatchPoints.length > 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getMatchPoints,"items-per-page":20,"sort-by":"added","sort-desc":"","dense":"","mobile-breakpoint":0},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[(_vm.user.email !== 'rahulvaghela@hotmail.com')?_c('v-toolbar-title',[_vm._v(" Unapproved Points ")]):_c('v-toolbar-title',[_vm._v(" All Points ")])],1)]},proxy:true},{key:"item.min",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.min !== -1 ? ((item.min .toString() .padStart(2, '0')) + ":" + (item.sec.toString().padStart(2, '0'))) : 'No footage')+" ")]}},{key:"item.approved",fn:function(ref){
var item = ref.item;
return [(item.approved === false)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"warning","small":""}},[_vm._v(" Under Review ")]):_c('v-chip',{staticClass:"ma-2",attrs:{"color":"success","small":""}},[_vm._v(" Approved ")])]}},{key:"item.added",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format(new Date(item.added), 'E do MMM yyyy K:mm'))+" ")]}},{key:"item.submittedBy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.submittedBy ? item.submittedBy : 'Rahul')+" ")]}},(_vm.user.email === 'rahulvaghela@hotmail.com')?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('button',_vm._g({on:{"click":function($event){return _vm.approveEventClick(item)}}},on),[_c('v-icon',[_vm._v(" mdi-thumb-up-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Approve")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('button',_vm._g({on:{"click":function($event){return _vm.disapproveEventClick(item)}}},on),[_c('v-icon',[_vm._v(" mdi-thumb-down-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Reject")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('button',_vm._g({on:{"click":function($event){return _vm.deleteEventClick(item)}}},on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}:null],null,true)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }