var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('link',{attrs:{"rel":"stylesheet","href":"https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css","integrity":"sha256-h20CPZ0QyXlBuAw7A+KluUYx/3pK+c7lYEpqLTlxjYQ=","crossorigin":"anonymous"}}),_c('v-card',[_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"float-right ml-2 mb-4",attrs:{"small":"","block":"","color":"primary"},on:{"click":_vm.showAllPlayersList}},[(!_vm.showAllPlayers)?_c('span',[_vm._v(" Show All Players ")]):_c('span',[_vm._v(" Show Only The 14 ")])]),_c('draggable',{staticClass:"dragDropArea",style:({ height: _vm.heightOfPlayersList + 'px' }),attrs:{"list":!_vm.showAllPlayers ? _vm.activePlayers : _vm.allActivePlayers,"group":"players"}},_vm._l((!_vm.showAllPlayers
              ? _vm.activePlayers
              : _vm.allActivePlayers),function(player){return _c('v-btn',{key:player.nickname,staticClass:"mt-2",attrs:{"x-small":"","block":""}},[_vm._v(" "+_vm._s(player.order)+" | "+_vm._s(player.nickname)+" ")])}),1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.getAllTeamsDDL,"label":"Team 1"},model:{value:(_vm.selectedTeam1.teamName),callback:function ($$v) {_vm.$set(_vm.selectedTeam1, "teamName", $$v)},expression:"selectedTeam1.teamName"}}),_c('v-select',{attrs:{"items":_vm.getAllTeamColoursDDL,"label":"Colour"},model:{value:(_vm.selectedTeam1.colour),callback:function ($$v) {_vm.$set(_vm.selectedTeam1, "colour", $$v)},expression:"selectedTeam1.colour"}}),_c('draggable',{staticClass:"dragDropArea",style:({
            backgroundColor:
              _vm.selectedTeam1.colour === 'blue'
                ? '#2196f3'
                : _vm.selectedTeam1.colour,
          }),attrs:{"list":_vm.arrTeam1,"group":"players"}},_vm._l((_vm.arrTeam1),function(player){return _c('v-btn',{key:player.nickname,staticClass:"mt-2",attrs:{"x-small":"","block":""}},[_vm._v(" "+_vm._s(player.nickname)+" ")])}),1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.getAllTeamsDDL,"label":"Team 2"},model:{value:(_vm.selectedTeam2.teamName),callback:function ($$v) {_vm.$set(_vm.selectedTeam2, "teamName", $$v)},expression:"selectedTeam2.teamName"}}),_c('v-select',{attrs:{"items":_vm.getAllTeamColoursDDL,"label":"Colour"},model:{value:(_vm.selectedTeam2.colour),callback:function ($$v) {_vm.$set(_vm.selectedTeam2, "colour", $$v)},expression:"selectedTeam2.colour"}}),_c('draggable',{staticClass:"dragDropArea",style:({
            backgroundColor:
              _vm.selectedTeam2.colour === 'blue'
                ? '#2196f3'
                : _vm.selectedTeam2.colour,
          }),attrs:{"list":_vm.arrTeam2,"group":"players"}},_vm._l((_vm.arrTeam2),function(player){return _c('v-btn',{key:player.nickname,staticClass:"mt-2",attrs:{"x-small":"","block":""}},[_vm._v(" "+_vm._s(player.nickname)+" ")])}),1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('pre',[_vm._v(_vm._s(_vm.description))])])],1),_c('v-row',[_c('v-col',[(_vm.user.email === 'rahulvaghela@hotmail.com')?_c('v-btn',{staticClass:"float-right",attrs:{"small":"","color":"primary"},on:{"click":_vm.callSaveTeams}},[_vm._v(" Save Teams ")]):_vm._e(),_c('ShareNetwork',{staticClass:"float-right",attrs:{"id":"whatsapp-share","network":"whatsapp","url":"","title":_vm.whatsappShareTitle(),"description":_vm.whatsappShareTeams(),"quote":"The hot reload is so fast it\\'s near instant. - Evan You","hashtags":"vuejs,vite","popup":{ width: 400, height: 200 }}},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"#25d366","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-whatsapp ")]),_c('span',{attrs:{"id":"share-button-text"}},[_vm._v("Share")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }