<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col>
          <template>
            <v-icon
              class="mb-1 mr-1"
            >
              mdi-movie-open-star
            </v-icon>
            <span class="ml-2 pt-5 text-sm-h4">Season Highlights</span>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="upvotedEvents"
            :sort-desc="true"
            item-key="_id"
            :items-per-page="10"
            class="table-striped"
            mobile-breakpoint="0"
            dense
            show-expand
            single-expand
            @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
          >
            <template #item.month="{ item }">
              {{ format(parseISO(new Date(item.matchInformation.date).toISOString()), 'MMMM') }}
            </template>
            <template v-slot:expanded-item="{ headersX, item }">
              <td
                colspan="4"
              >
                <v-btn
                  v-if="
                    item.matchInformation.youtubeURL.startsWith('https:')"
                  x-small
                  color="red"
                  class="my-2 mr-2  white--text"

                  @click="navigateToYoutubeTimeStamp(item)"
                >
                  View <v-icon
                    right
                  >
                    mdi-youtube
                  </v-icon>
                </v-btn>
                <v-btn
                  x-small
                  color="primary"
                  class="my-2"
                  :to="{ name: 'LeagueMatch', params: { matchId: item.matchId } }"
                >
                  View Match
                </v-btn>
                <v-icon
                  class="ml-2"
                  small
                  @click="nominate(true, item._id)"
                >
                  mdi-thumb-up-outline
                </v-icon>
                <span
                  v-if="item.nominated"
                  class="ml-2"
                >{{ item.nominated.length }}</span>
                <span
                  v-else
                  class="ml-2"
                >0</span>
                <v-icon
                  small
                  class="ml-3"
                  @click="nominate(false, item._id)"
                >
                  mdi-thumb-down-outline
                </v-icon>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from 'axios'
  import { format, parseISO } from 'date-fns'
  export default {
    name: 'UpvotedEvents',
    data () {
      return {
        format,
        parseISO,
        headers: [
          { text: 'Month', value: 'month' },
          { text: 'Player', value: 'nickname' },
          { text: 'Match', value: 'matchInformation.description' },
          { text: '', value: 'data-table-expand' },
        ],
        upvotedEvents: [],
      }
    },
    computed: {
      ...mapState('auth', ['token']),
      ...mapState('leagues', ['league']),
    },
    mounted () {
      this.getUpvotedEvents()
    },
    methods: {
      getUpvotedEvents () {
        axios({
          method: 'get',
          url: process.env.VUE_APP_BACKENDAPI + '/stats/getUpvotedEvents',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.token.token,
          },
          params: { leagueId: this.league._id.toString() },
        }).then(response => {
          this.upvotedEvents = response.data
        }).catch(err => {
          console.error(err)
        })
      },
      navigateToYoutubeTimeStamp (item) {
        const min = item.min
        const sec = item.sec - 15

        if (item.matchInformation.youtubeURL.startsWith('https://www.youtube.com')) {
          // console.log(`${item.matchInformation.youtubeURL}&t=${min}m${sec}s`)
          window.open(`${item.matchInformation.youtubeURL}&t=${min}m${sec}s`, '_blank')
        } else {
          // console.log(`${item.matchInformation.youtubeURL}?t=${min}m${sec}s`)
          window.open(`${item.matchInformation.youtubeURL}?t=${min}m${sec}s`, '_blank')
        }
      },
    },
  }
</script>

<style>

</style>
