<template>
  <v-data-table
    v-if="getMatchPoints.length > 0"
    :headers="headers"
    :items="getMatchPoints"
    :items-per-page="20"
    sort-by="added"
    sort-desc
    class="elevation-1"
    dense
    :mobile-breakpoint="0"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title v-if="user.email !== 'rahulvaghela@hotmail.com'">
          Unapproved Points
        </v-toolbar-title>
        <v-toolbar-title v-else>
          All Points
        </v-toolbar-title>
      </v-toolbar>
    </template>
    <template #[`item.min`]="{ item }">
      {{
        item.min !== -1
          ? `${item.min
            .toString()
            .padStart(2, '0')}:${item.sec.toString().padStart(2, '0')}`
          : 'No footage'
      }}
    </template>
    <template #[`item.approved`]="{ item }">
      <v-chip v-if="item.approved === false" class="ma-2" color="warning" small>
        Under Review
      </v-chip>
      <v-chip v-else class="ma-2" color="success" small>
        Approved
      </v-chip>
    </template>
    <template #[`item.added`]="{ item }">
      {{ format(new Date(item.added), 'E do MMM yyyy K:mm') }}
    </template>
    <template #[`item.submittedBy`]="{ item }">
      {{ item.submittedBy ? item.submittedBy : 'Rahul' }}
    </template>
    <template
      v-if="user.email === 'rahulvaghela@hotmail.com'"
      #[`item.actions`]="{ item }"
    >
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <button v-on="on" @click="approveEventClick(item)">
            <v-icon>
              mdi-thumb-up-outline
            </v-icon>
          </button>
        </template>
        <span>Approve</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <button v-on="on" @click="disapproveEventClick(item)">
            <v-icon>
              mdi-thumb-down-outline
            </v-icon>
          </button>
        </template>
        <span>Reject</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <button v-on="on" @click="deleteEventClick(item)">
            <v-icon>
              mdi-delete
            </v-icon>
          </button>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { format } from 'date-fns';
export default {
  name: 'MatchEventsTable',
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapGetters('leagues', ['getMatchPoints']),
    ...mapState('auth', ['user']),
    ...mapState('leagues', ['match']),
    headers() {
      const headers = [
        {
          text: 'Player',
          align: 'start',
          sortable: false,
          value: 'nickname',
        },
        { text: 'Time', value: 'min' },
        { text: 'Type', value: 'type' },
        { text: 'Added', value: 'added' },
        { text: 'Submitted By', value: 'submittedBy' },
        { text: 'Approved', value: 'approved' },
      ];

      if (this.user.email === 'rahulvaghela@hotmail.com') {
        headers.push({ text: 'Action', value: 'actions', sortable: false });
      }

      return headers;
    },
    matchPointsFiltered() {
      return this.user.email === 'rahulvaghela@hotmail.com'
        ? this.matchPoints
        : this.matchPoints.filter(point => point.approved === false);
    },
  },
  methods: {
    ...mapActions('leagues', ['deleteEvent', 'approveEvent']),
    async deleteEventClick(item) {
      const payload = {
        matchId: this.match._id.toString(),
        eventId: item._id.toString(),
      };
      await this.deleteEvent(payload);
    },
    async approveEventClick(item) {
      const payload = {
        matchId: this.match._id.toString(),
        pointId: item._id.toString(),
        approved: true,
      };

      await this.approveEvent(payload);
    },
    async disapproveEventClick(item) {
      const payload = {
        matchId: this.match._id.toString(),
        pointId: item._id.toString(),
        approved: false,
      };

      await this.approveEvent(payload);
    },
  },
};
</script>

<style></style>
