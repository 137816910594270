<template>
  <v-card class="mb-3">
    <v-card-text>
      <v-row>
        <v-col>
          <template>
            <v-icon class="mb-1 mr-1">
              mdi-table
            </v-icon>
            <span class="ml-2 pt-5 text-sm-h4 ">Select league</span>
          </template>
        </v-col>
      </v-row>
      <v-row v-if="user">
        <v-col>
          <v-btn-toggle v-model="toggleLeague" mandatory @change="setLeague()">
            <v-btn
              small
              v-for="item in userLeagues"
              :key="item.leagueId"
              :value="item.leagueId"
            >
              {{ item.leagueName }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'SelectLeague',
  data() {
    return {
      toggleLeague: null,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    userLeagues() {
      const userLeagues = Array.from(this.user.subscriptions).reverse();
      return userLeagues;
    },
  },
  methods: {
    ...mapActions('leagues', ['getLeague', 'getMatches']),
    ...mapActions('stats', ['getStats']),
    async setLeague() {
      await this.getLeague(this.toggleLeague);
      await this.getMatches(this.toggleLeague);
      // await this.getStats(this.toggleLeague);
    },
  },
};
</script>
