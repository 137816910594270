<template>
  <div>
    <v-row>
      <v-col>
        <add-highlight :players="match.teams" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <match-highlights-table />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'MatchHighlights',
  components: {
    AddHighlight: () => import('../AddHighlight'),
    MatchHighlightsTable: () =>
      import('../../components/MatchView/MatchHighlightsTable'),
  },

  computed: {
    ...mapState('leagues', ['match']),
  },
};
</script>

<style></style>
