<template>
  <div>
    <v-row>
      <v-col>
        <add-event :players="match.teams" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Player
                </th>
                <th class="text-left">
                  Award
                </th>
                <th class="text-left">
                  Points
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in pointsTable" :key="i">
                <td>{{ item.nickname }}</td>
                <td>{{ item.events }}</td>
                <td>{{ item.points }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <match-events-table />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'MatchIndividualPoints',
  components: {
    AddEvent: () => import('../AddEvent'),
    MatchEventsTable: () => import('./MatchEventsTable'),
  },
  computed: {
    ...mapState('leagues', ['matchPoints', 'league', 'match']),
    pointsTable() {
      // order by player
      const playersOnly = Array.from(
        new Set(this.match.points.filter(point => point.approved)),
      );
      // add an array of events to a new array
      const playersAndTheirEvents = playersOnly
        .map(player => {
          return {
            nickname: player.nickname,
            events: this.getEventsForPlayer(player),
            points: this.getPointsForPlayer(player),
          };
        })
        .sort(this.compareByPoints);

      const uniquePlayers = Array.from(
        new Set(playersAndTheirEvents.map(player => player.nickname)),
      ).map(nickname => {
        return playersAndTheirEvents.find(
          player => player.nickname === nickname,
        );
      });

      return uniquePlayers;
    },
  },
  methods: {
    getEventsForPlayer(player) {
      const playerEvents = this.match.points
        .filter(point => point.approved)
        .filter(point => {
          return point.playerId.toString() === player.playerId.toString();
        })
        .map(point => {
          return this.returnUserFriendlyLabel(point.type);
        });

      const aCount = Array.from(
        new Map(
          [...new Set(playerEvents)].map(x => [
            x,
            playerEvents.filter(y => y === x).length,
          ]),
        ),
      );

      const pointsAsString = aCount.map(item => {
        if (item[1] > 1) {
          return `${item[0]} (${item[1]})`;
        } else {
          return `${item[0]}`;
        }
      });

      return pointsAsString.join(' and ');
    },
    getPointsForPlayer(player) {
      const playerEvents = this.match.points
        .filter(point => point.approved)
        .filter(point => {
          return point.playerId.toString() === player.playerId.toString();
        });

      let points = 0;
      playerEvents.forEach(event => {
        points += this.returnPointForEvent(event.type);
      });

      return points;
    },
    returnUserFriendlyLabel(type) {
      switch (type) {
        case 'goal':
          return 'Goal';
        case 'assist':
          return 'Assist';
        case 'ownGoal':
          return 'Own goal';
        case 'penalty':
          return 'Goal (pen.)';
        case 'penaltyConceded':
          return 'Penalty conceded';
        case 'penaltyMiss':
          return 'Penalty miss';
        case 'penaltySave':
          return 'Penalty save';
        case 'motm':
          return 'Man of the Match';
        case 'gotm':
          return 'Goalkeeper of the Match';
        case 'dotm':
          return 'Defender of the Match';
        case 'win':
          return 'Win';
        case 'draw':
          return 'Draw';
        case 'loss':
          return 'Lose';
        case 'concededUnder5':
          return 'Concede Under 5';
        case 'concededUnder4':
          return 'Concede Under 4';
        case 'concededUnder3':
          return 'Concede Under 3';
        case 'concededUnder1':
          return 'Clean sheet';
        default:
          return 'unknown';
        // code block
      }
    },
    returnPointForEvent(type) {
      return this.league.availablePoints.find(point => point.desc === type)
        ? this.league.availablePoints.find(point => point.desc === type).value
        : type;
    },
    compareByName(a, b) {
      // Use toUpperCase() to ignore character casing
      const nicknameA = a.nickname.toUpperCase();
      const nicknameB = b.nickname.toUpperCase();

      let comparison = 0;
      if (nicknameA > nicknameB) {
        comparison = 1;
      } else if (nicknameA < nicknameB) {
        comparison = -1;
      }
      return comparison;
    },
    compareByPoints(b, a) {
      // Use toUpperCase() to ignore character casing
      const nicknameA = a.points;
      const nicknameB = b.points;

      let comparison = 0;
      if (nicknameA > nicknameB) {
        comparison = 1;
      } else if (nicknameA < nicknameB) {
        comparison = -1;
      }
      return comparison;
    },
  },
};
</script>

<style></style>
