<template>
  <v-container
    id="register-view"
    class="fill-height"
    tag="section"
  >
    <v-row justify="center">
      <v-col cols="12">
        <v-slide-y-transition appear>
          <material-card
            light
            max-width="350"
            rounded
            class="mx-auto"
            color="primary"
            full-header
          >
            <template #heading>
              <div class="text-center pa-5">
                <div class="text-h4 font-weight-bold white--text">
                  Register
                </div>
              </div>
            </template>
            <v-form v-model="valid">
              <v-card-text class="text-center">
                <v-select
                  v-model="nickname"
                  :items="nicknames"
                  label="Nickname..."
                  prepend-icon="mdi-guy-fawkes-mask"
                  dense
                />

                <v-text-field
                  v-model.trim="firstName"
                  color="secondary"
                  placeholder="First Name..."
                  prepend-icon="mdi-numeric-1-box-outline"
                  dense
                />

                <v-text-field
                  v-model.trim="lastName"
                  color="secondary"
                  placeholder="Last Name..."
                  prepend-icon="mdi-numeric-2-box-outline"
                  dense
                />

                <v-text-field
                  v-model.trim="email"
                  color="secondary"
                  placeholder="Email..."
                  prepend-icon="mdi-email"
                  dense
                />

                <v-text-field
                  v-model.trim="password"
                  type="password"
                  class="mb-8"
                  color="secondary"
                  placeholder="Password..."
                  prepend-icon="mdi-lock-outline"
                  dense
                />

                <v-btn
                  color="primary"
                  rounded
                  text
                  large
                  :disabled="!valid"
                  @click="register"
                >
                  Register
                </v-btn>
              </v-card-text>
            </v-form>
          </material-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import firebase from 'firebase'
  import * as RealmWeb from 'realm-web'
  import { mapActions } from 'vuex'

  export default {
    name: 'RegisterView',
    data: () => ({
      valid: false,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      nickname: '',
      nicknames: [
        'Aaron',
        'Abdul',
        'Ben',
        'Gags',
        'Hits',
        'Joey',
        'Kish',
        'Ravi',
        'Stephen',
        'Vishal',
      ],
    }),
    methods: {
      ...mapActions('auth', ['getUser']),
      async register () {
        try {
          const userCred = await firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
          // Signed in
          const token = userCred.user.za
          await this.saveUserInfoOnRegistration(token)
          await this.getUser(userCred.user.email)
          this.$router.push({ name: 'Dashboard' })
        } catch (err) {
          console.error(err)
        }
      },
      async saveUserInfoOnRegistration (token) {
        const app = new RealmWeb.App({ id: process.env.VUE_APP_MONGO_REALM_SERVER })
        const credentials = RealmWeb.Credentials.jwt(token)
        try {
          const user = await app.logIn(credentials)
          const mongoUser = {
            firstName: this.firstName,
            lastName: this.lastName,
            nickname: this.nickname,
            email: this.email,
          }
          await user.functions.storeRegistrationData(mongoUser)
        } catch (err) {
          console.log(`Error: ${err}`)
        }
      },
      // testNode () {
      //   this.$nodeProject({
      //     method: 'post',
      //     url: '/api/test',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //   })
      //     .then(response => {
      //       console.log(response)
      //     }).catch(err => {
      //       console.log(err)
      //     })
      // },
    },
  }
</script>

<style lang="sass">
#register-view
  .v-list-item__subtitle
    -webkic-line-clamp: initial
    -webkit-box-orient: initial

  .v-list-item__title
    font-size: 1.12rem

  .v-list-item__subtitle
    font-size: .875rem
    color: #3C4858
    line-height: 1.5em
    font-weight: 300
</style>
