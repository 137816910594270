<template>
  <app-card class="pa-4 mb-4">
    <template v-if="!match.summary" class="pa-2">
      <v-alert
        type="warning"
        class="mt-5"
        text
      >
        <span>
          Match events have not been added yet
        </span>
      </v-alert>
    </template>
    <template v-else>
      <v-row>
        <v-col>
          <vote-motm />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
          <h2 class="float-right">
            {{ match.summary.team1.teamName }} ({{
              match.summary.team1.colour
            }})
          </h2>
        </v-col>
        <v-col cols="1" class="d-flex justify-center">
          <h1 v-if="match" :style="{ color: match.summary.team1.colour }">
            {{ match.summary.team1.score }}
          </h1>
        </v-col>
        <v-col cols="1" class="d-flex justify-center">
          <h1 v-if="match" :style="{ color: match.summary.team2.colour }">
            {{ match.summary.team2.score }}
          </h1>
        </v-col>
        <v-col cols="5">
          <h2>
            {{ match.summary.team2.teamName }} ({{
              match.summary.team2.colour
            }})
          </h2>
        </v-col>
      </v-row>
      <match-view-events
        v-if="isEventContainedInPoints('goal')"
        event-type="goal"
      />
      <match-view-events
        v-if="isEventContainedInPoints('assist')"
        event-type="assist"
      />
      <match-view-events
        v-if="isEventContainedInPoints('ownGoal')"
        event-type="ownGoal"
      />
      <match-view-events
        v-if="isEventContainedInPoints('penaltyConceded')"
        event-type="penaltyConceded"
      />
      <match-view-events
        v-if="isEventContainedInPoints('penalty')"
        event-type="penalty"
      />
      <match-view-events
        v-if="isEventContainedInPoints('penaltyMiss')"
        event-type="penaltyMiss"
      />
      <match-view-events
        v-if="isEventContainedInPoints('penaltySave')"
        event-type="penaltySave"
      />
    </template>
    <match-view-detail />
  </app-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'MatchView',
  components: {
    MatchViewEvents: () => import('../components/MatchView/MatchViewEvents'),
    MatchViewDetail: () => import('../components/MatchView/MatchViewDetail'),
    VoteMotm: () => import('../components/MatchView/VoteMotm'),
  },
  computed: {
    ...mapState('leagues', ['match', 'matchPoints']),
  },
  methods: {
    navigateToYoutubeTimeStamp(item) {
      const min = item.min;
      const sec = item.sec - 15;

      if (this.match.youtubeURL.startsWith('https://www.youtube.com')) {
        // console.log(`${this.match.youtubeURL}&t=${min}m${sec}s`)
        window.open(`${this.match.youtubeURL}&t=${min}m${sec}s`, '_blank');
      } else {
        // console.log(`${this.match.youtubeURL}?t=${min}m${sec}s`)
        window.open(`${this.match.youtubeURL}?t=${min}m${sec}s`, '_blank');
      }
    },
    isEventContainedInPoints(type) {
      const events = this.match.points.map(point => point.type);
      return events.includes(type);
    },
  },
};
</script>

<style></style>
