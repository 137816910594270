<template>
  <app-card class="pa-4 mb-4">
    <v-row>
      <v-col md="6" sm="12">
        <v-card>
          <v-img height="50px" src="./../../public/img/barcelona.jpg">
            <v-card-title class="white--text">
              <h1>
                Team Points
              </h1>
            </v-card-title>
          </v-img>
          <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Action
                    </th>
                    <th class="text-left">
                      Points
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in availablePointsTeam" :key="item.desc">
                    <td>{{ returnUserFriendlyLabel(item.desc) }}</td>
                    <td>{{ item.value }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="6" sm="12">
        <v-card>
          <v-img height="50px" src="./../../public/img/ronaldo.jpg">
            <v-card-title class="white--text">
              <h1>
                Individual Points
              </h1>
            </v-card-title>
          </v-img>
          <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Action
                    </th>
                    <th class="text-left">
                      Points
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in availablePointsIndividual"
                    :key="item.desc"
                  >
                    <td>{{ returnUserFriendlyLabel(item.desc) }}</td>
                    <td>{{ item.value }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </app-card>
</template>

<script>
import helper from '../mixins/helpers';
export default {
  name: 'LeagueDetails',
  mixins: [helper],
  props: {
    league: Object,
  },
  computed: {
    // ...mapState('leagues', ['league']),
    refactoredLeaguePointsIndividual() {
      return this.league.availablePointsIndividual.map(type => {
        const userFriendlyName = this.returnUserFriendlyLabel(
          Object.keys(type)[0],
        );
        return { type: userFriendlyName, points: Object.values(type)[0] };
      });
    },
    refactoredLeaguePointsTeam() {
      return this.league.availablePointsTeam.map(type => {
        const userFriendlyName = this.returnUserFriendlyLabel(
          Object.keys(type)[0],
        );
        return { type: userFriendlyName, points: Object.values(type)[0] };
      });
    },
    availablePointsTeam() {
      return this.league.availablePoints.filter(p => p.type === 'team');
    },
    availablePointsIndividual() {
      return this.league.availablePoints.filter(p => p.type === 'individual');
    },
  },
};
</script>

<style scoped>
.opacity {
  filter: brightness(50%);
}
</style>
