<template>
  <v-container id="buttons-view" fluid tag="section">
    <view-intro v-if="league" :heading="league.name" :loading="loading" />

    <league-details v-if="league" :league="league" />
    <app-card v-if="selectedPlayer" class="pa-4">
      <v-row v-if="user.email === 'rahulvaghela@hotmail.com'">
        <v-col cols="3">
          <modal-add-new-match />
        </v-col>
        <v-spacer />
        <v-col cols="3">
          <v-select
            v-model="selectedPlayer"
            label="Select player"
            :items="getPlayerListDDL"
            item-text="text"
            item-value="value"
            return-object
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :loading="!matches"
            :headers="headers"
            :items="matchesSorted"
            sort-by="date"
            sort-desc
            class="table-striped"
            mobile-breakpoint="0"
            item-key="name"
            @click:row="rowClickHandler"
          >
            <template v-slot:[`item.statsComplete`]="{ item }">
              <span v-if="item.statsComplete">
                <v-icon color="green">
                  mdi-check-circle
                </v-icon>
              </span>
              <span v-else>
                <v-icon color="red">
                  mdi-close-circle
                </v-icon>
              </span>
            </template>
            <template v-slot:[`item.awardsComplete`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    v-if="getAwardsCount(item) > 0"
                    color="green"
                    size="24"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span class="white--text">{{ getAwardsCount(item) }}</span>
                  </v-avatar>
                  <v-avatar
                    v-else
                    color="red"
                    size="24"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span class="white--text">{{ getAwardsCount(item) }}</span>
                  </v-avatar>
                </template>
                <span v-if="item.matchAwards">
                  <div v-for="(award, index) in item.matchAwards" :key="index">
                    {{ award.awardedTo }} - {{ award.type }} ({{
                      award.awardedBy
                    }})
                  </div>
                </span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.date`]="{ item }">
              <span v-if="item.date">{{ returnFormattedDate(item.date) }}</span>
            </template>
            <template v-slot:[`item.play`]="{ item }">
              <template v-if="selectedPlayer">
                <span
                  v-if="userPlaysMatch(item._id.toString())"
                  class="text-center"
                >
                  <v-icon color="green">
                    mdi-check-circle
                  </v-icon>
                </span>
                <span v-else class="text-center">
                  <v-icon color="red">
                    mdi-close-circle
                  </v-icon>
                </span>
              </template>
            </template>
            <template
              v-slot:[`item.totalPoints`]="{ item }"
              class="text-center"
            >
              <v-chip
                v-if="userPlaysMatch(item._id.toString())"
                class="ma-2 bold-chip"
                small
                text-color="white"
                color="blue"
              >
                {{ getPlayerPointsTotal(item) }}
              </v-chip>
              <v-chip v-else>
                Did not play
              </v-chip>
            </template>
            <template
              v-slot:[`item.individualPoints`]="{ item }"
              class="text-center"
            >
              <v-chip
                v-if="userPlaysMatch(item._id.toString())"
                class="ma-2 bold-chip"
                small
                text-color="white"
                color="blue lighten-2"
              >
                {{ getPlayerStats(item, 'total', 'individual') }}
              </v-chip>
            </template>
            <template v-slot:[`item.teamPoints`]="{ item }" class="text-center">
              <v-chip
                v-if="userPlaysMatch(item._id.toString())"
                class="ma-2 bold-chip"
                small
                text-color="white"
                color="blue lighten-2"
              >
                {{ getPlayerStats(item, 'total', 'team') }}
              </v-chip>
            </template>

            <template v-slot:[`item.yourStats`]="{ item }">
              <template v-for="(stat, index) in statTotals">
                <span
                  v-if="
                    getPlayerStats(item, stat.type, stat.category) !==
                      undefined &&
                      getPlayerStats(item, stat.type, stat.category) !== 0
                  "
                  :key="index"
                >
                  <template v-if="stat.type !== 'total'">
                    <!-- individual stats -->
                    <template v-if="stat.category === 'individual'">
                      <span> {{ stat.title }}:</span>
                      <v-chip
                        v-if="
                          getPlayerStats(item, stat.type, stat.category) >= 3
                        "
                        class="ma-2"
                        small
                        color="amber darken-2"
                        text-color="white"
                      >
                        {{
                          `${getPlayerStats(item, stat.type, stat.category)}`
                        }}
                      </v-chip>
                      <v-chip
                        v-else
                        class="ma-2"
                        small
                        color="blue lighten-1"
                        text-color="white"
                      >
                        {{
                          `${getPlayerStats(item, stat.type, stat.category)}`
                        }}
                      </v-chip>
                    </template>
                  </template>
                </span>
              </template>
            </template>
            <template v-slot:[`item.teamAccolades`]="{ item }">
              <template v-for="(stat, index) in statTotals">
                <span
                  v-if="
                    getPlayerStats(item, stat.type, stat.category) !==
                      undefined &&
                      getPlayerStats(item, stat.type, stat.category) !== 0
                  "
                  :key="index"
                >
                  <template v-if="stat.type !== 'total'">
                    <!-- individual stats -->
                    <!-- team stats -->
                    <template v-if="stat.category !== 'individual'">
                      <!-- <span> {{ stat.title }}:</span> -->
                      <template>
                        <v-chip class="ma-2" color="primary" small>
                          {{ stat.title }}
                        </v-chip>
                        <!-- <v-icon class="ma-1 mb-2" color="green">
                          mdi-check-circle
                        </v-icon> -->
                      </template>
                    </template>
                  </template>
                </span>
              </template>
            </template>
            <template v-slot:[`item.videoURL`]="{ item }">
              <span v-if="item.videoURL.startsWith('http')">
                <v-btn
                  :href="item.videoURL"
                  target="_blank"
                  color="red"
                  small
                  icon
                >
                  <v-icon dark>
                    mdi-youtube
                  </v-icon>
                </v-btn>
              </span>
              <span v-else>
                No footage
              </span>
            </template>
            <template v-slot:[`item.summary`]="{ item }">
              <span>{{ getScoreline(item.summary) }}</span>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">More info about {{ item.name }}</td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </app-card>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import helper from '../mixins/helpers';
export default {
  name: 'DashboardButtons',
  components: {
    // ModalAddNewMatch: () => import('../components/ModalAddNewMatch.vue'),
    ModalAddNewMatch: () => import('../components/ModalAddNewMatch_new.vue'),
    LeagueDetails: () => import('../components/LeagueDetails.vue'),
  },
  mixins: [helper],
  data: () => ({
    headers: [
      { text: 'Stats', value: 'statsComplete' },
      { text: 'Awards', value: 'awardsComplete' },
      { text: 'Date', value: 'date' },
      { text: 'Description', value: 'name' },
      { text: 'Score', value: 'summary' },
      { text: 'Points', value: 'totalPoints' },
      // { text: 'Indv. points', value: 'individualPoints' },
      // { text: 'Team points', value: 'teamPoints' },
      { text: 'Your stats', value: 'yourStats' },
      { text: 'Team accolades', value: 'teamAccolades' },
      { text: 'YouTube', value: 'videoURL' },
    ],
    loading: true,
    expandedRow: [],
    singleExpand: true,
    statTotals: [
      {
        category: 'individual',
        title: 'Indv. points',
        type: 'total',
      },
      {
        category: 'individual',
        title: 'Goals',
        type: 'goal',
      },
      {
        category: 'individual',
        title: 'Assists',
        type: 'assist',
      },
      {
        category: 'individual',
        title: 'OGs',
        type: 'ownGoal',
      },
      {
        category: 'individual',
        title: 'Pens',
        type: 'penalty',
      },
      {
        category: 'individual',
        title: 'Pen Conc.',
        type: 'penaltyConceded',
      },
      {
        category: 'individual',
        title: 'Pen Saves',
        type: 'penaltySave',
      },
      {
        category: 'individual',
        title: 'Pen Misses',
        type: 'penaltyMiss',
      },
      {
        category: 'individual',
        title: 'MoTM',
        type: 'motm',
      },
      {
        category: 'individual',
        title: 'DoTM',
        type: 'dotm',
      },
      {
        category: 'individual',
        title: 'GoTM',
        type: 'gotm',
      },
      {
        category: 'team',
        title: 'Team points',
        type: 'total',
      },
      {
        category: 'team',
        title: 'Win',
        type: 'win',
      },
      {
        category: 'team',
        title: 'Draw',
        type: 'draw',
      },
      // losses do not need to be shown as a count since no points are attributed to them
      // {
      //   category: 'team',
      //   title: 'Lose',
      //   type: 'lose',
      // },
      {
        category: 'team',
        title: 'Conceded under 5',
        type: 'concededUnder5',
      },
      {
        category: 'team',
        title: 'Conceded under 4',
        type: 'concededUnder4',
      },
      {
        category: 'team',
        title: 'Conceded under 3',
        type: 'concededUnder3',
      },
      {
        category: 'team',
        title: 'Clean sheet',
        type: 'concededUnder1',
      },
    ],
    selectedPlayer: null,
  }),
  computed: {
    ...mapState('leagues', ['league', 'matches']),
    ...mapGetters('leagues', ['getPlayerListDDL']),
    ...mapState('auth', ['user']),
    // selectedPlayer() {
    //   return {
    //     text: this.user.nickname,
    //     value: this.user._id.toString(),
    //   };
    // },
    matchesSorted() {
      return this.matches;
    },
    isRowExpanded() {
      return row => this.expandedRow === row.id;
    },
  },
  async mounted() {
    const leagueId = this.$route.params.leagueId.toString();
    await this.getLeague(leagueId);
    await this.getMatches(leagueId);
    this.loading = false;
    this.selectedPlayer = {
      text: this.user.nickname,
      value: this.user._id.toString(),
    };
  },
  methods: {
    ...mapActions('leagues', ['getLeague', 'getMatches', 'getMatch']),
    userPlaysMatch(matchId) {
      const match = this.matches.filter(match => {
        return match._id.toString() === matchId;
      })[0];

      const playerId = this.selectedPlayer
        ? this.selectedPlayer.value
        : this.user._id.toString();

      const playerPlayed = match.teams.filter(player => {
        return player.playerId.toString() === playerId;
      });

      return playerPlayed.length > 0;
    },
    getPlayerStats(item, type, category) {
      let playersPoints;

      const selectedPlayerId = this.selectedPlayer.value.toString();

      if (item.summary) {
        playersPoints = item.summary.playerPoints.find(
          p => p.player.playerId.toString() === selectedPlayerId,
        );
        if (playersPoints) {
          if (category === 'individual') {
            return playersPoints.individual[type];
          } else if (category === 'team') {
            return playersPoints.team[type];
          } else {
            return undefined;
          }
        } else {
          return undefined;
        }
      }
    },
    getPlayerPointsTotal(item) {
      let playersPoints;

      const selectedPlayerId = this.selectedPlayer.value.toString();

      if (item.summary) {
        playersPoints = item.summary.playerPoints.find(
          p => p.player.playerId.toString() === selectedPlayerId,
        );

        if (playersPoints) {
          return playersPoints.individual.total + playersPoints.team.total;
        } else {
          return 0;
        }
      }
      return 0;
    },
    getAwardsCount(match) {
      return match.matchAwards.length;
    },
    async getMatchAndNavigate(id) {
      await this.getMatch(id.toString());
      this.$router.push({ name: 'LeagueMatch', params: { matchId: id } });
    },
    rowClickHandler(item) {
      this.getMatchAndNavigate(item._id);
    },
    sortByDate(a, b) {
      if (a.date > b.date) return 1;
      if (a.date < b.date) return -1;
      return 0;
    },
    getScoreline(summary) {
      if (summary) {
        if (summary.team1 && summary.team2) {
          const team1Color =
            this.capitalizeFirstLetter(summary.team1.colour) + 's';
          const team2Color =
            this.capitalizeFirstLetter(summary.team2.colour) + 's';
          return `${team1Color} ${summary.team1.score} - ${summary.team2.score} ${team2Color}`;
        } else {
          return 'Summary error';
        }
      } else {
        return 'No summary';
      }
    },
  },
};
</script>

<style scoped>
.bold-chip {
  font-weight: bold;
}
</style>
