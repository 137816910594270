<template>
  <div>
    <!-- <v-sheet
      v-if="statsLoadingStatus"
      color="grey lighten-4"
      class="pa-3"
    >
      <v-skeleton-loader
        class="mx-auto"
        max-height="250"
        type="table"
      />
    </v-sheet> -->
    <v-data-table
      :headers="headers"
      :items="getPlayerEvents(selectedPlayer.value)"
      :sort-desc="true"
      item-key="_id"
      :items-per-page="10"
      class="table-striped"
      mobile-breakpoint="0"
      dense
      show-expand
      single-expand
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
    >
      <template #item.nominated="{ item }">
        <template v-if="item.nominated">
          <v-icon v-if="item.nominated.length > 0" color="#FFD700" small>
            mdi-star
          </v-icon>
          <v-icon v-else small>
            mdi-star
          </v-icon>
        </template>
        <template v-else>
          <v-icon small>
            mdi-star
          </v-icon>
        </template>
      </template>

      <template #item.type="{ item }">
        {{ returnUserFriendlyLabel(item.type) }}
      </template>
      <template #item.minsec="{ item }">
        <span v-if="item.videoURL.startsWith('https:')">
          {{ item.min.toString().padStart(2, '0') }}:{{
            item.sec.toString().padStart(2, '0')
          }}
        </span>
        <span v-else>
          <v-icon color="grey" light>
            mdi-video-off
          </v-icon>
        </span>
      </template>
      <template v-slot:expanded-item="{ headersX, item }">
        <td colspan="4">
          <v-btn
            v-if="item.videoURL.startsWith('https:')"
            x-small
            color="red"
            class="my-2 mr-2  white--text"
            @click="navigateToYoutubeTimeStamp(item)"
          >
            View
            <v-icon right>
              mdi-youtube
            </v-icon>
          </v-btn>
          <v-btn
            x-small
            color="primary"
            class="my-2"
            :to="{ name: 'LeagueMatch', params: { matchId: item.matchId } }"
          >
            View Match
          </v-btn>
          <v-icon class="ml-2" small @click="nominate(true, item._id)">
            mdi-thumb-up-outline
          </v-icon>
          <span v-if="item.nominated" class="ml-2">{{
            item.nominated.length
          }}</span>
          <span v-else class="ml-2">0</span>
          <v-icon small class="ml-3" @click="nominate(false, item._id)">
            mdi-thumb-down-outline
          </v-icon>
        </td>
      </template>
    </v-data-table>
    <!-- <v-alert
      v-else
      border="top"
      colored-border
      type="info"
      elevation="2"
    >
      No events associated with this player in the season selected
    </v-alert> -->
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import helper from '../../mixins/helpers';
export default {
  name: 'IndividualEvents',
  mixins: [helper],
  props: {
    selectedPlayer: Object,
  },
  data() {
    return {
      headers: [
        { text: '', value: 'nominated' },
        { text: 'Desc', value: 'matchName' },
        { text: 'Type', value: 'type' },
        { text: 'Time', value: 'minsec' },
        { text: '', value: 'data-table-expand' },
      ],
    };
  },
  computed: {
    ...mapState('stats', ['playerEvents', 'statsLoadingStatus']),
    ...mapState('auth', ['user']),
    ...mapGetters('stats', ['getPlayerEvents']),
  },
  methods: {
    ...mapActions('stats', ['nominateEvent']),
    navigateToYoutubeTimeStamp(item) {
      if (item.min === -1) return;
      const min = item.min;
      const sec = item.sec >= 10 ? item.sec - 10 : (item.sec = 0);

      if (item.videoURL.startsWith('https://www.youtube.com')) {
        window.open(`${item.videoURL}&t=${min}m${sec}s`, '_blank');
      } else {
        window.open(`${item.videoURL}?t=${min}m${sec}s`, '_blank');
      }
    },
    nominate(nominated, eventId) {
      const payload = {
        eventId: eventId,
        nickname: this.user.nickname,
        addNomination: nominated,
        playerEventsNickname: this.playerEvents[0].nickname,
      };

      if (nominated) {
        this.nominateEvent(payload);
      } else {
        this.nominateEvent(payload);
      }
    },
  },
};
</script>

<style scoped></style>
