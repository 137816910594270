var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"extended-forms-view","fluid":"","tag":"section"}},[_c('view-intro',{attrs:{"heading":"Date Pickers","link":"components/date-pickers"}}),_c('v-row',[_vm._l((3),function(n){return _c('v-col',{key:n,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('material-card',{attrs:{"title":_vm.pickers[n - 1],"color":"accent","icon":"mdi-calendar-today","icon-small":""}},[_c('v-card-text',[_c('v-menu',{ref:"menu",refInFor:true,attrs:{"close-on-content-click":false,"return-value":_vm.date[n],"min-width":"290px","offset-y":"","transition":"scale-transition"},on:{"update:returnValue":function($event){return _vm.$set(_vm.date, n, $event)},"update:return-value":function($event){return _vm.$set(_vm.date, n, $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"color":"secondary","label":"Select date","prepend-icon":"mdi-calendar-outline","readonly":""},model:{value:(_vm.date[n]),callback:function ($$v) {_vm.$set(_vm.date, n, $$v)},expression:"date[n]"}},on))]}}],null,true),model:{value:(_vm.menu[n]),callback:function ($$v) {_vm.$set(_vm.menu, n, $$v)},expression:"menu[n]"}},[_c('v-date-picker',{attrs:{"color":"secondary","landscape":n === 2,"scrollable":""},on:{"change":function () { return n === 3 ? _vm.$refs.menu[n - 1].save(_vm.date[n]) : undefined; }},model:{value:(_vm.date[n]),callback:function ($$v) {_vm.$set(_vm.date, n, $$v)},expression:"date[n]"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.menu[n] = false}}},[_vm._v(" Cancel ")]),(n !== 3)?_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.$refs.menu[n - 1].save(_vm.date[n])}}},[_vm._v(" OK ")]):_vm._e()],1)],1)],1)],1)],1)}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',[_c('div',{staticClass:"text-h5 mb-3"},[_vm._v(" Switches ")]),_vm._l((2),function(n){return _c('v-switch',{key:n,attrs:{"label":("Toggle is " + (n === 1 ? 'on' : 'off')),"hide-details":n === 1,"input-value":n === 1}})})],2),_c('div',[_c('div',{staticClass:"text-h5 mb-3"},[_vm._v(" Tags ")]),_c('v-combobox',{attrs:{"color":"secondary","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"color":"secondary","close":"","small":""},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}})],1),_c('div',[_c('div',{staticClass:"text-h5 mb-3"},[_vm._v(" Progress Bar ")]),_vm._l((3),function(n){return _c('v-progress-linear',{key:n,staticClass:"mt-2",attrs:{"color":['secondary', 'info', 'warning'][n - 1],"value":[30, 60, 40][n - 1],"buffer-value":n === 3 ? 0 : undefined,"stream":n === 3}})})],2),_c('div',[_c('v-subheader',{staticClass:"mb-6"},[_vm._v(" File Input ")])],1),_c('v-file-input',{attrs:{"display-size":1000,"color":"deep-purple accent-4","counter":"","label":"File input","multiple":"","outlined":"","placeholder":"Select your files","prepend-icon":"mdi-paperclip"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" File(s) ")]):_vm._e()]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',[_c('div',{staticClass:"text-h5 mb-3"},[_vm._v(" Customizable Select ")]),_vm._l((2),function(n){return _c('v-select',{key:n,attrs:{"items":_vm.movies,"multiple":n === 2,"color":"secondary","item-color":"secondary","label":"Movie","menu-props":"offset-y"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"mx-3 mb-3",attrs:{"active-class":"secondary elevation-4 white--text","elevation":"0"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1),(n === 2)?_c('v-scale-transition',[(attrs.inputValue)?_c('v-list-item-icon',{staticClass:"my-3"},[_c('v-icon',[_vm._v("mdi-check")])],1):_vm._e()],1):_vm._e()],1)]}}],null,true)})})],2),_c('div',[_c('div',{staticClass:"text-h5 mb-3"},[_vm._v(" Dropdown & Dropup ")]),_vm._l((3),function(n){return _c('div',{key:n,staticClass:"mb-3"},[_c('material-dropdown',{attrs:{"items":_vm.dropdown,"color":"success","origin":[undefined, 'top right', 'bottom right'][n - 1],"right":n === 2,"top":n === 3}},[(n !== 3)?[_vm._v(" "+_vm._s(n === 1 ? 'MultiLevel' : '')+" Dropdown ")]:_c('span',[_vm._v("Dropup")])],2)],1)})],2),_c('div',{staticClass:"text-h5 mb-3"},[_vm._v(" Sliders ")]),_c('v-slider',{attrs:{"color":"secondary"}}),_c('v-range-slider',{attrs:{"value":[40, 60],"color":"info","track-color":"info lighten-4"}})],1)],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }