<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    @keydown.esc="dialog = false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on" @click="reset">
        Add New Match
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Add New Match</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="description"
                label="Description"
                required
              />
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="youtubeURL" label="YouTube URL" required />
            </v-col>
            <v-col cols="12">
              <date-picker
                v-model="selectedDateTime"
                type="datetime"
                :minute-step="15"
                :show-time-panel="showTimePanel"
                use12h
                :show-second="false"
                format="DD/MM/YYYY HH:mm (ddd)"
                :default-value="defaultDate"
              >
                <template v-slot:footer>
                  <button class="mx-btn mx-btn-text" @click="toggleTimePanel">
                    {{ showTimePanel ? 'select date' : 'select time' }}
                  </button>
                </template>
              </date-picker>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="createNewMatch()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import objectId from 'bson-objectid';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { mapActions, mapState } from 'vuex';
export default {
  name: 'AddNewMatch',
  components: { DatePicker },
  data: () => ({
    dialog: false,
    description: '',
    youtubeURL: '',
    selectedDateTime: null,
    showTimePanel: false,
    defaultDate: new Date(),
  }),
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('leagues', ['addNewMatch', 'getMatches']),
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    async createNewMatch() {
      const leagueId = objectId(this.$route.params.leagueId);
      const datetime = this.selectedDateTime;
      const description = this.description;
      const youtubeURL = this.youtubeURL;
      const payload = {
        leagueId: leagueId,
        description,
        datetime,
        youtubeURL,
      };

      await this.addNewMatch(payload);
      await this.getMatches(this.$route.params.leagueId.toString());
      this.dialog = false;
    },
    reset() {
      const defaultDateTime = new Date();
      defaultDateTime.setHours(20, 30, 0);
      this.defaultDateTime = defaultDateTime;
    },
  },
};
</script>
