import axios from 'axios'

export default {
  namespaced: true,
    state: {
    },
    getters: {
    },
    mutations: {
  },
  actions: {
    async confirmedTeamsEmail ({ rootState }, payload) {
      console.log(payload)
      axios({
        method: 'get',
        url: process.env.VUE_APP_BACKENDAPI + '/mail/sendTeamEmail',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + rootState.auth.token.token,
        },
        params: { emails: payload },
      }).then(response => {
        console.log(response)
      })
    },
  },
}
