<template>
  <v-container
    id="buttons-view"
    fluid
    tag="section"
  >
    <view-intro
      heading="Leagues"
    />

    <app-card class="pa-4">
      <v-row>
        <v-col
          cols="12"
        >
          <div class="text-h5 mb-6">
            <v-data-table
              :headers="headers"
              :items="leagues"
              class="table-striped"
              mobile-breakpoint="0"
              @click:row="rowClickHandler"
            >
              <template
                v-slot:[`item.startDate`]="{ item }"
              >
                <span>{{ format(parseISO(new Date(item.startDate).toISOString()), 'E do MMM yyyy') }}</span>
              </template>
              <template
                v-slot:[`item.endDate`]="{ item }"
              >
                <span>{{ format(parseISO(new Date(item.endDate).toISOString()), 'E do MMM yyyy') }}</span>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </app-card>
  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { format, parseISO } from 'date-fns'
  export default {
    name: 'Leagues',
    data: () => ({
      format,
      parseISO,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Start', value: 'startDate' },
        { text: 'End', value: 'endDate' },
      ],
    }),
    computed: {
      ...mapState('leagues', ['leagues']),
    },
    async mounted () {
      await this.getLeagues()
    },
    methods: {
      ...mapActions('leagues', ['getLeagues']),
      rowClickHandler (item) {
        this.$router.push({ name: 'League', params: { leagueId: item._id } })
      },
    },
  }
</script>
