<template>
  <span>
    <v-btn
      v-if="event.min === -1"
      target="_blank"
      small
      text
      tile
      class="pb-1 mr-1"
      enabled="false"
      color="#333"
      style="cursor: not-allowed"
      @click="navigateToYoutubeTimeStamp(event)"
    >
      {{ getEventNickname() }}
      <v-icon
        small
        color="#666"
        class="ml-1"
      >
        mdi-video-off
      </v-icon>

    </v-btn>
    <v-btn
      v-else
      target="_blank"
      small
      text
      tile
      class="pb-1 mr-1"
      :color="event.approved ? '#333' : 'error'"
      @click="navigateToYoutubeTimeStamp(event)"
    >
      {{ getEventNickname() }} {{ getEventTime() }}
    </v-btn>
  </span>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'MatchViewEventsPlayerAndTime',
  props: {
    event: Object,
  },
  computed: {
    ...mapState('leagues', ['match']),
  },
  methods: {
    navigateToYoutubeTimeStamp(item) {
      if (item.min === -1) return;
      const min = item.min;
      const sec = item.sec >= 10 ? item.sec - 10 : (item.sec = 0);

      if (this.match.videoURL.startsWith('https://www.youtube.com')) {
        // console.log(`${this.match.videoURL}&t=${min}m${sec}s`)
        window.open(`${this.match.videoURL}&t=${min}m${sec}s`, '_blank');
      } else {
        // console.log(`${this.match.videoURL}?t=${min}m${sec}s`)
        window.open(`${this.match.videoURL}?t=${min}m${sec}s`, '_blank');
      }
    },
    getEventTime() {
      const time =
        this.event.min !== -1
          ? `(${this.event.min}:${this.event.sec.toString().padStart(2, '0')})`
          : '(No footage)';
      return `${time}`;
    },
    getEventNickname() {
      return `${this.event.nickname}`;
    },
  },
};
</script>

<style>

</style>
