<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-btn
          @click="login">
          <v-icon small color="primary" class="mr-2">
            mdi-google
          </v-icon> Login
        </v-btn>
        <v-btn
          class="ml-3"
          @click="updateYoutubeVideoDetails">
          <v-icon small color="error" class="mr-2">
            mdi-youtube
          </v-icon> Update video details
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  name: 'GoogleAuth',
  props: {
    videoId: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    categoryId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      responseData: null,
    };
  },
  computed: {
    ...mapState('auth', ['user', 'token']),
  },
  methods: {
    login() {
      window.open('http://localhost:3003/api/v1/auth/google', 'auth', 'popup');
    },
    async updateYoutubeVideoDetails() {
      await axios('http://localhost:3003/api/v1/youtube/update', {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
          Authorization: 'Bearer ' + this.token.token,
        },
        data: {
          id: this.videoId,
          snippet: {
            categoryId: this.categoryId,
            title: this.title,
            description: this.description,
          },
        },
      }).then(response => {
        response ? this.$toast.info('YouTube video details updated', { timeout: 2000 }) : this.$toast.error('Error updating YouTube video details', { timeout: 2000 }); ;
      })
      .catch(() => {
        this.$toast.error('Error updating YouTube video details', { timeout: 2000 });
        this.responseData = 'error';
      });
    },
    // fetchProtectedData() {
    //   axios('http://localhost:3003/api/v1/auth/protected', {
    //     method: 'GET',
    //     credentials: 'include',
    //     headers: {
    //       Accept: 'application/json',
    //       'Content-Type': 'application/json',
    //       'Access-Control-Allow-Credentials': true,
    //     },
    //   })
    //     .then(response => {
    //       this.responseData = response.data;
    //     })
    //     .catch(error => {
    //       console.error(error);
    //       this.responseData = null;
    //     });
    // },
  },
}
</script>
