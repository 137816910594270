<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    @keydown.esc="dialog = false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on" @click="reset">
        Add New Match
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Add New Match</span>
      </v-card-title>
      <v-card-text class="reducedFontSize">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="name" label="Name" required />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="playersPerTeamCountSelected"
                label="Players per team"
                :items="playersPerTeamCountOptions"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-switch
                v-model="videoURLAvailable"
                :label="
                  `${
                    videoURLAvailable
                      ? 'Enter video URL'
                      : 'Add video URL later'
                  }`
                "
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="videoURL"
                label="Video URL"
                :disabled="!videoURLAvailable"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <div class="mt-2">
                Kickoff
              </div>
            </v-col>
            <v-col cols="10">
              <date-picker
                v-model="selectedDateTime"
                type="datetime"
                :minute-step="15"
                :show-time-panel="showTimePanel"
                use12h
                :show-second="false"
                format="DD/MM/YYYY HH:mm (ddd)"
                :default-value="defaultDateTime"
              >
                <template v-slot:footer>
                  <button class="mx-btn mx-btn-text" @click="toggleTimePanel">
                    {{ showTimePanel ? 'select date' : 'select time' }}
                  </button>
                </template>
              </date-picker>
            </v-col>
          </v-row>
          <v-row>
            <!-- <v-col cols="4">
              <v-switch
                v-model="camPersonIsALeaguePlayer"
                :label="
                  `${
                    camPersonIsALeaguePlayer
                      ? 'Cam person from league?'
                      : 'Enter name'
                  }`
                "
            /></v-col> -->
            <v-col cols="12">
              <v-select
                v-model="camPersonSelected"
                label="Cam person"
                :items="getPlayerListDDL"
                item-text="text"
                item-value="value"
                return-object
              />
            </v-col>
            <!-- <v-col cols="4">
              <v-text-field
                v-model="camPersonCustom"
                label="Custom"
                :disabled="camPersonIsALeaguePlayer"
              />
            </v-col> -->
          </v-row>
          <v-row>
            <!-- <v-col cols="4">
              <v-switch
                v-model="kitPersonIsALeaguePlayer"
                :label="
                  `${
                    kitPersonIsALeaguePlayer
                      ? 'Kit person from league?'
                      : 'Enter name'
                  }`
                "
            /></v-col> -->
            <v-col cols="12">
              <v-select
                v-model="kitPersonSelected"
                label="Kit person"
                :items="getPlayerListDDL"
                item-text="text"
                item-value="value"
                return-object
              />
            </v-col>
            <!-- <v-col cols="4">
              <v-text-field
                v-model="kitPersonCustom"
                label="Custom"
                :disabled="kitPersonIsALeaguePlayer"
            /></v-col> -->
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="createNewMatch()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import objectId from 'bson-objectid';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { mapActions, mapState, mapGetters } from 'vuex';
export default {
  name: 'AddNewMatch',
  components: { DatePicker },
  data: () => ({
    dialog: false,
    name: '',
    videoURLAvailable: false,
    videoURL: '',
    selectedDateTime: null,
    showTimePanel: false,
    camPersonSelected: {},
    kitPersonSelected: {},
    defaultDateTime: new Date(),
    playersPerTeamCountSelected: 7,
    playersPerTeamCountOptions: [5, 6, 7],
    // camPersonIsALeaguePlayer: true,
    // camPersonCustom: '',
    // kitPersonIsALeaguePlayer: true,
    // kitPersonCustom: '',
  }),
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('leagues', ['matches']),
    ...mapGetters('leagues', ['getPlayerListDDL']),
  },
  mounted() {
    this.reset();
  },
  methods: {
    ...mapActions('leagues', ['addNewMatch', 'getMatches']),
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    async createNewMatch() {
      const leagueId = objectId(this.$route.params.leagueId);
      const date = this.selectedDateTime;
      const name = this.name;
      const videoURL = this.videoURL;

      const createdById = this.user._id;
      const createdByNickname = this.user.nickname;
      const playersPerTeam = this.playersPerTeamCountSelected;

      const camPersonId = this.camPersonSelected.value;
      const camPersonNickname = this.camPersonSelected.text;

      const kitPersonId = this.kitPersonSelected.value;
      const kitPersonNickname = this.kitPersonSelected.text;

      const payload = {
        leagueId,
        date,
        name,
        videoURL,
        createdById,
        createdByNickname,
        playersPerTeam,
        camPersonId,
        camPersonNickname,
        kitPersonId,
        kitPersonNickname,
      };

      await this.addNewMatch(payload);
      await this.getMatches(this.$route.params.leagueId.toString());
      this.dialog = false;
    },
    getNextTuesday() {
      const currentDate = new Date();
      const day = currentDate.getDay();
      const daysUntilNextTuesday = (9 - day) % 7;
      const nextTuesday = new Date(
        currentDate.getTime() + daysUntilNextTuesday * 24 * 60 * 60 * 1000,
      );

      // Adjust the time to 00:00:00 to get only the date value
      nextTuesday.setHours(20, 30, 0, 0);

      return nextTuesday;
    },
    reset() {
      this.name = this.getMostRecentMatchName();
      this.selectedDateTime = this.getNextTuesday();
      this.camPersonSelected = {
        value: '6166ff8d4b23ddf75728e995',
        text: 'Kapz',
      };
      this.kitPersonSelected = {
        value: '608087f35dd6248d301ed7d8',
        text: 'Amit',
      };
    },
    getMostRecentMatchName() {
      const matchNamesAndDate = this.matches.map(match => {
        return {
          name: match.name,
          date: match.date,
        };
      });
      const mostRecentMatch = matchNamesAndDate
        .sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        })
        .at(-1);

      return 'Most recent match name: ' + mostRecentMatch.name;
    },
  },
};
</script>
<style scoped></style>
