import firebase from 'firebase'

var firebaseConfig = {
    apiKey: 'AIzaSyCbxzfkBxCRoL5Qh-tzkcxOnMaO5gHT_Gc',
    authDomain: 'asidestats.firebaseapp.com',
    databaseURL: 'https://asidestats-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'asidestats',
    storageBucket: 'asidestats.appspot.com',
    messagingSenderId: '616720183420',
    appId: '1:616720183420:web:25747a6f18066b774a59f9',
    measurementId: 'G-D2DBEHLH25',
  }

const firebaseAppCore = firebase.initializeApp(firebaseConfig)
firebase.analytics()

export default {
  dbCore: firebaseAppCore.firestore(),
}
