<template>
  <v-card
    :loading="loading"
    class="mx-auto my-12"
  >
    <template slot="progress">
      <v-progress-linear
        color="primary"
        height="5"
        indeterminate
      />
    </template>

    <v-card-title class="text-h5">
      {{ typeTitle }}
    </v-card-title>

    <v-sheet
      color="primary"
      elevation="1"
      height="10"
    />
    <!-- <v-img
      height="100"
      :src="require(`@/assets/${statsImage}`)"
    /> -->

    <v-card-text>
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
            <tr>
              <th class="text-left">
                Pos
              </th>
              <th class="text-left">
                Player
              </th>
              <th class="text-center">
                Games
              </th>
              <th class="text-center">
                Avg
              </th>
              <th class="text-center">
                {{ typeTitle }}
              </th>
            </tr>
            <tr
              v-for="(item, index) in getStats2021Ordered(type)"
              :key="item.name"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ item.name }}</td>
              <td class="text-center">
                {{ item.games }}
              </td>
              <template>
                <td
                  v-if="type === 'points'"
                  class="text-center"
                >
                  <span class="grey--text">{{ (item.points / item.games).toFixed(2) }}</span>
                </td>
                <td
                  v-else-if="type === 'goals'"
                  class="text-center"
                >
                  <span class="grey--text">{{ (item.goals / item.games).toFixed(2) }}</span>
                </td>
                <td
                  v-else-if="type === 'assists'"
                  class="text-center"
                >
                  <span class="grey--text">{{ (item.assists / item.games).toFixed(2) }}</span>
                </td>
                <td
                  v-else
                  class="text-center"
                >
                  <span class="grey--text">{{ (item.wins / item.games).toFixed(2) }}</span>
                </td>
              </template>
              <template>
                <td
                  v-if="type === 'points'"
                  class="text-center"
                >
                  {{ item.points }}
                </td>
                <td
                  v-else-if="type === 'goals'"
                  class="text-center"
                >
                  {{ item.goals }}
                </td>
                <td
                  v-else-if="type === 'assists'"
                  class="text-center"
                >
                  {{ item.assists }}
                </td>
                <td
                  v-else
                  class="text-center"
                >
                  {{ item.wins }}
                </td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { titleCase } from '@/util/helpers'
  export default {
    name: 'TopTen',
    props: {
      type: String,
    },
    data: () => ({
      loading: false,
      selection: 1,
      statsImage: 'stats.jpg',
    }),
    computed: {
      ...mapGetters('stats', ['getStats2021Ordered']),
      typeTitle () {
        return titleCase(this.type)
      },
    },
    methods: {
      reserve () {
        this.loading = true

        setTimeout(() => (this.loading = false), 2000)
      },
    },
  }
</script>
<style>
</style>
