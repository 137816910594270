import * as RealmWeb from 'realm-web';
import axios from 'axios';

function initialState() {
  return {
    statsLoadingStatus: false,
    stats: null,
    leaguePoints: null,
    lastUpdated: null,
    lastXStats: null,
  };
}

export default {
  namespaced: true,
  state: {
    stats: null,
    leaguePoints: null,
    lastUpdated: null,
    playerEvents: null,
    lastXStats: null,
    goalsAndAssistsCount: null,
  },
  getters: {
    getStats2021Ordered: state => payload => {
      const ordered = state.stats
        .slice()
        .sort((a, b) => (a[payload] < b[payload] ? 1 : -1));
      return ordered;
      // return state.stats2021
    },
    getTeamTotal: (state, getters, rootState) => payload => {
      // matchId: payload.matchId, colour: orange/blue
      const leaguePoints = rootState.stats.leaguePoints.filter(match => {
        return match.matchId === payload.matchId;
      });

      if (leaguePoints.length === 0) {
        return 0;
      }

      const scorelineChangingEvents = leaguePoints.filter(point => {
        return (
          point.type === 'goal' ||
          point.type === 'ownGoal' ||
          point.type === 'penalty'
        );
      });
      let total = 0;
      scorelineChangingEvents.forEach(event => {
        const newPayload = {
          matchId: payload.matchId,
          nickname: event.nickname,
        };
        switch (event.type) {
          case 'ownGoal':
            total +=
              payload.colour === getters.getEventPlayerColour(newPayload)
                ? 0
                : 1;
            break;
          case 'goal':
            total +=
              payload.colour === getters.getEventPlayerColour(newPayload)
                ? 1
                : 0;
            break;
          case 'penalty':
            total +=
              payload.colour === getters.getEventPlayerColour(newPayload)
                ? 1
                : 0;
            break;
          case 'penaltyMiss':
            total += 0;
            break;
        }
      });
      return total;
    },
    getPlayerEvents: (state, getters, rootState) => playerId => {
      const allPoints = [];
      rootState.leagues.matches.forEach(match => {
        if (match.points.length > 0 && typeof match.points !== 'undefined') {
          const allMatchPoints = [...match.points];
          allMatchPoints.forEach(point => {
            point.videoURL = match.videoURL;
            point.matchName = match.name;
          });
          allPoints.push(...allMatchPoints);
        }
      });

      const filteredByPlayerId = allPoints.filter(
        point => point.playerId.toString() === playerId.toString(),
      );

      return filteredByPlayerId;
    },
    // get2020statsPoints: (state, getters, rootState) => {
    //     if (state.stats === null) {
    //         return []
    //     }
    //     const pointsOnly = state.stats.filter(st => {
    //         return st.category === 'points'
    //     })
    //     const chartDataStructure = []
    //     const chartHeaders = ['Player', 'Points', 'Average']
    //     chartDataStructure.push(chartHeaders)
    //     pointsOnly[0].points.forEach(point => {
    //         const data = [point.player, point.points, point.avgPoints]
    //         chartDataStructure.push(data)
    //     })
    //     return chartDataStructure
    // },
    // get2020statsWins: (state, getters, rootState) => {
    //     if (state.stats === null) {
    //         return []
    //     }
    //     const winsOnly = state.stats.filter(st => {
    //         return st.category === 'wins'
    //     })
    //     const chartDataStructure = []
    //     const chartHeaders = ['Player', 'Wins', 'Average']
    //     chartDataStructure.push(chartHeaders)
    //     winsOnly[0].wins.forEach(point => {
    //         const data = [point.player, point.wins, point.avgWins]
    //         chartDataStructure.push(data)
    //     })
    //     return chartDataStructure
    // },
    // get2020statsGoals: (state, getters, rootState) => {
    //     if (state.stats === null) {
    //         return []
    //     }
    //     const goalsOnly = state.stats.filter(st => {
    //         return st.category === 'goals'
    //     })
    //     const chartDataStructure = []
    //     const chartHeaders = ['Player', 'Goals', 'Average']
    //     chartDataStructure.push(chartHeaders)
    //     goalsOnly[0].goals.forEach(point => {
    //         const data = [point.player, point.goals, point.avgGoals]
    //         chartDataStructure.push(data)
    //     })
    //     return chartDataStructure
    // },
    // get2020statsAssists: (state, getters, rootState) => {
    //     if (state.stats === null) {
    //         return []
    //     }
    //     const assistsOnly = state.stats.filter(st => {
    //         return st.category === 'assists'
    //     })
    //     const chartDataStructure = []
    //     const chartHeaders = ['Player', 'Assists', 'Average']
    //     chartDataStructure.push(chartHeaders)
    //     assistsOnly[0].assists.forEach(point => {
    //         const data = [point.player, point.assists, point.avgAssists]
    //         chartDataStructure.push(data)
    //     })
    //     return chartDataStructure
    // }
  },
  mutations: {
    SET_STATS(state, payload) {
      state.stats = payload;
    },
    SET_LEAGUE_POINTS(state, payload) {
      state.leaguePoints = payload;
    },
    LAST_UPDATED(state, payload) {
      state.lastUpdated = payload;
    },
    RESET(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    SET_STATS_LOADING_STATUS(state, payload) {
      state.statsLoadingStatus = payload;
    },
    SET_PLAYER_EVENTS(state, payload) {
      state.playerEvents = payload;
    },
    SET_LAST_X_STATS(state, payload) {
      state.lastXStats = payload;
    },
    SET_GOALS_AND_ASSISTS_COUNT(state, payload) {
      state.goalsAndAssistsCount = payload;
    },
  },
  actions: {
    // async getStats({ commit, rootState, dispatch }, payload) {
    //   commit('SET_STATS_LOADING_STATUS', true);
    //   axios({
    //     method: 'get',
    //     url: process.env.VUE_APP_BACKENDAPI + '/stats/getStats',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Authorization: 'Bearer ' + rootState.auth.token.token,
    //     },
    //     params: { leagueId: payload },
    //   })
    //     .then(response => {
    //       dispatch('getLastUpdated', payload);
    //       commit('SET_STATS', response.data.result);
    //       commit('SET_STATS_LOADING_STATUS', false);
    //       this._vm.$toast.info('Stats loaded', { timeout: 2000 });
    //     })
    //     .catch(err => {
    //       console.error(err);
    //       this._vm.$toast.error('Stats failed to load', { timeout: 2000 });
    //     });
    // },
    async getStatsPublic({ commit, state, dispatch, rootState }, payload) {
      await dispatch('auth/getCustomToken', {}, { root: true });
      await dispatch('auth/getIdToken', {}, { root: true });
      commit('auth/SET_USER', null, { root: true });
      commit('SET_STATS_LOADING_STATUS', true);
      axios({
        method: 'get',
        url: process.env.VUE_APP_BACKENDAPI + '/stats/getStats',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + rootState.auth.idToken,
        },
        params: { leagueId: payload },
      })
        .then(response => {
          dispatch('getLastUpdated', payload);
          commit('SET_STATS', response.data.result);
          commit('SET_STATS_LOADING_STATUS', false);
        })
        .catch(err => {
          console.error(err);
        });
    },
    async getLastUpdated({ commit, rootState }, payload) {
      let token = null;
      if (rootState.auth.idToken) {
        token = rootState.auth.idToken;
      } else if (rootState.auth.token) {
        token = rootState.auth.token.token;
      }
      if (!token) {
        return;
      }
      axios({
        method: 'get',
        url: process.env.VUE_APP_BACKENDAPI + '/stats/lastUpdated',
        headers: {
          Authorization: 'Bearer ' + token,
        },
        params: { leagueId: payload },
      })
        .then(response => {
          if (response.data !== '') {
            commit('LAST_UPDATED', response.data);
          } else {
            commit('LAST_UPDATED', null);
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    async getPlayerEvents({ commit, rootState }, payload) {
      commit('SET_STATS_LOADING_STATUS', true);
      axios({
        method: 'get',
        url: process.env.VUE_APP_BACKENDAPI + '/stats/getPlayerEvents',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + rootState.auth.token.token,
        },
        params: { nickname: payload },
      }).then(response => {
        commit('SET_PLAYER_EVENTS', response.data);
        commit('SET_STATS_LOADING_STATUS', false);
      });
    },
    async nominateEvent({ commit, rootState, dispatch }, payload) {
      axios({
        method: 'get',
        url: process.env.VUE_APP_BACKENDAPI + '/stats/nominateEvent',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + rootState.auth.token.token,
        },
        params: {
          eventId: payload.eventId,
          nickname: payload.nickname,
          addNomination: payload.addNomination,
        },
      }).then(response => {
        dispatch('getPlayerEvents', payload.playerEventsNickname);
      });
    },
    async getLastXStats({ commit, rootState }, payload) {
      commit('SET_STATS_LOADING_STATUS', true);
      const app = new RealmWeb.App({
        id: process.env.VUE_APP_MONGO_REALM_SERVER,
      });
      const credentials = RealmWeb.Credentials.jwt(rootState.auth.token.token);
      try {
        const user = await app.logIn(credentials);
        payload.type = 'goal';
        const goalsStats = await user.functions.getLastXStats(payload);
        payload.type = 'assist';
        const assistsStats = await user.functions.getLastXStats(payload);

        const stats = {
          goals: goalsStats,
          assists: assistsStats,
        };

        commit('SET_LAST_X_STATS', stats);
        commit('SET_STATS_LOADING_STATUS', false);
      } catch (err) {
        this._vm.$toast.error('Last x stats failed to load', { timeout: 2000 });
        console.log(`Error: ${err}`);
      }
    },
    async getGoalsAndAssistsCount({ commit, rootState }, payload) {
      commit('SET_STATS_LOADING_STATUS', true);
      const app = new RealmWeb.App({
        id: process.env.VUE_APP_MONGO_REALM_SERVER,
      });
      const credentials = RealmWeb.Credentials.jwt(rootState.auth.token.token);
      try {
        const user = await app.logIn(credentials);
        const result = await user.functions.getGoalsAndAssistsCount(payload);
        commit('SET_GOALS_AND_ASSISTS_COUNT', result);
        commit('SET_STATS_LOADING_STATUS', false);
      } catch (err) {
        this._vm.$toast.error('Goals and assists count failed to load', {
          timeout: 2000,
        });
        console.log(`Error: ${err}`);
      }
    },
    async updateLeagueStats({ commit, dispatch, rootState }, payload) {
      commit('SET_STATS_LOADING_STATUS', true);
      axios({
        method: 'get',
        url:
          process.env.VUE_APP_BACKENDAPI +
          '/stats/updateLeagueAndMatchCalculatedStats',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + rootState.auth.token.token,
        },
        params: { leagueId: payload },
      })
        .then(response => {
          this._vm.$toast.success(
            `Stats updated for league: ${rootState.leagues.league.name}`,
            {
              timeout: 2000,
            },
          );
          dispatch('leagues/getLeagues', payload, { root: true });
          dispatch('leagues/getMatches', payload, { root: true });
          commit('SET_STATS_LOADING_STATUS', false);
        })
        .catch(err => {
          console.error(err);
        });
    },
  },
};
