<template>
  <v-container
    id="login-view"
    class="fill-height"
    tag="section"
  >
    <v-row justify="center">
      <v-col cols="12">
        <v-slide-y-transition appear>
          <material-card
            light
            max-width="350"
            rounded
            class="mx-auto"
            color="primary"
            full-header
          >
            <template #heading>
              <div class="text-center pa-5">
                <div class="text-h4 font-weight-bold white--text">
                  Login
                </div>
              </div>
            </template>
            <v-form v-model="valid">
              <v-card-text class="text-center">
                <v-text-field
                  ref="email"
                  v-model="email"
                  color="secondary"
                  placeholder="Email..."
                  prepend-icon="mdi-email"
                  required
                  type="email"
                  :rules="emailRules"
                  @keyup.enter="signInViaStore"
                />
                <v-text-field
                  v-model="password"
                  class="mb-8"
                  color="secondary"
                  placeholder="Password..."
                  prepend-icon="mdi-lock-outline"
                  required
                  type="password"
                  :rules="passwordRules"
                  @keyup.enter="signInViaStore"
                />

                <v-btn
                  color="primary"
                  rounded
                  text
                  large
                  :disabled="!valid"
                  :loading="loading"
                  @click="signInViaStore"
                >
                  Login
                </v-btn>
                <div
                  v-if="error"
                  class="errorText"
                >
                  <p>{{ error }}</p>
                </div>
              <!-- <v-btn
                color="accent"
                rounded
                text
                large
                @click="testRealm"
              >
                Test Realm
              </v-btn>
              {{ mongoUser }} -->
              </v-card-text>
            </v-form>
          </material-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // import store from '../store'
  // import { get } from 'vuex-pathify'
  import { mapActions, mapState } from 'vuex'
  // import * as RealmWeb from 'realm-web'
  // import firebase from 'firebase'

  export default {
    name: 'LoginView',
    data: () => ({
      socials: [
        {
          href: '#',
          icon: 'mdi-facebook',
        },
        {
          href: '#',
          icon: 'mdi-twitter',
        },
        {
          href: '#',
          icon: 'mdi-github',
        },
      ],
      email: null,
      password: null,
      mongoUser: null,
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
      ],
      valid: false,
      error: '',
    }),
    computed: {
      ...mapState('auth', ['token', 'loading']),
    },
    mounted () {
      this.focusInput()
    },
    methods: {
      ...mapActions('auth', ['signUserIn', 'setToken']),
      // signUserIn () {
      //   store.set('user/signUserIn!', { email: this.email, password: this.password })
      // },
      async signInViaStore () {
        const cred = {
          email: this.email,
          password: this.password,
        }

        this.signUserIn(cred)
        // this.$router.push({ name: 'Dashboard' }).catch(() => {})
      },
      // async signIn () {
      //   if (this.valid === false) {
      //     return
      //   }
      //   const login = await firebase
      //     .auth()
      //     .signInWithEmailAndPassword(this.email, this.password)
      //     .then(cred => {
      //       return firebase
      //         .auth()
      //         .currentUser.getIdTokenResult()
      //         .then(idTokenResult => {
      //           this.setToken(idTokenResult)
      //           return true
      //         }).catch(err => {
      //           console.log(err)
      //           return false
      //         })
      //     }).catch(err => {
      //       console.error('ERROR: ', err)
      //       this.error = err.message
      //       return false
      //     })
      //   if (login) {
      //     this.$router.push({ name: 'Dashboard' }).catch(() => {})
      //   }
      // },
      focusInput () {
        this.$refs.email.focus()
      },
    },
  }
</script>

<style scoped>
  .errorText {
    color: red
  }
</style>
