// Imports
import Vue from 'vue'
import Router from 'vue-router'
// import { trailingSlash } from '@/util/helpers'
import {
  layout,
  route,
} from '@/util/routes'
import firebase from 'firebase'
import jwtDecode from 'jwt-decode'
import store from '../store/index'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    layout('Default', [
      // ### Public League Table Access - TEMP
      // route('StatsDashboard', null, '/tuesday-footy-stats'),

      // ### Account
      route('Dashboard', null, '/', { requiresAuth: true }),

      // Pages
      // ### Account
      route('Profile', null, 'profile', { requiresAuth: true }),

      // ### Leagues
      route('Leagues', null, 'leagues', { requiresAuth: true }),
      route('League', null, 'leagues/:leagueId', { requiresAuth: true }),
      route('LeagueEdit', null, 'leagues/:leagueId/edit', { requiresAuth: true }),
      route('LeagueMatches', null, 'leagues/:leagueId/matches', { requiresAuth: true }),
      route('LeagueMatch', null, 'leagues/:leagueId/matches/:matchId', { requiresAuth: true }),
      route('LeagueMatchEdit', null, 'leagues/:leagueId/matches/:matchId/edit', { requiresAuth: true }),
      route('LeaguePlayers', null, 'leagues/:leagueId/players', { requiresAuth: true }),

      // TODO Players
      // route('Timeline', null, 'components/timeline'),
      // route('UserProfile', null, 'components/profile'),

      // // Components
      // route('Buttons', null, 'components/buttons'),
      // route('Grid', null, 'components/grid'),
      // route('Tabs', null, 'components/tabs'),
      // route('Notifications', null, 'components/notifications'),
      // route('Icons', null, 'components/icons'),
      // route('Typography', null, 'components/typography'),

      // // Forms
      // route('Regular Forms', null, 'forms/regular'),
      // route('Extended Forms', null, 'forms/extended'),
      // route('Validation Forms', null, 'forms/validation'),
      // route('Wizard', null, 'forms/wizard'),

      // // Tables
      // route('Regular Tables', null, 'tables/regular'),
      // route('Extended Tables', null, 'tables/extended'),
      // route('Data Tables', null, 'tables/data-tables'),

      // // Maps
      // route('Google Maps', null, 'maps/google'),
      // route('Fullscreen Map', null, 'maps/fullscreen'),

      // route('Rtl', null, 'pages/rtl'),
      // route('Widgets', null, 'widgets'),
      // route('Charts', null, 'charts'),
      // route('Calendar', null, '*'),
    ]),
    layout('Page', [
      // route('Lock', null, 'lock'),
      route('Login', null, 'login'),
      route('Register', null, 'register'),
      route('Logout', null, 'logout'),

          // ### Auth
      route('Authenticated', null, 'auth-success', { requiresAuth: true }),
    ]),
    layout('Default', [
      route('Dashboard', null, '*', { requiresAuth: true }),
    ]),
  ],
})

function tokenExpired () {
  // check if the token has expired or not
  const token = store.state.auth?.token?.token
  if (token === undefined) {
    return true
  }
  const decoded = jwtDecode(token)
  if (Date.now() >= decoded.exp * 1000) {
    store.dispatch('auth/signUserOut', {}, { root: true })
    return true
  } else {
    return false
  }
}

router.beforeEach((to, from, next) => {
  const loggedIn = firebase.auth().currentUser?.email
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const tokenHasExpired = tokenExpired()
  // const isApproved = store.state.auth?.user?.approved

  let moveToNext = false

  if (requiresAuth) {
    if (loggedIn && !tokenHasExpired) {
      moveToNext = true
    } else {
      moveToNext = false
    }
  } else {
    moveToNext = true
  }

  if (moveToNext) {
    next()
  } else {
    console.log('error')
    next({ path: '/login' })
  }

  // Re-add this?
  // to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
  //   firebase.auth().signOut().then(() => {
  //     localStorage.removeItem('vuex')
  //     localStorage.clear()
})

export default router
