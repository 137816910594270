<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col>
          <template>
            <v-icon class="mb-1 mr-1">
              mdi-table-large
            </v-icon>
            <span class="ml-2 pt-5 text-sm-h4">League Table</span>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="currentLeagueStandings"
            dense
            sort-by="points"
            :sort-desc="true"
            :items-per-page="10"
            :mobile-breakpoint="0"
            class="table-striped"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'LeagueTable',
  data() {
    return {
      headers: [
        { text: 'Player', value: 'nickname', width: '50px', fixed: true },
        { text: 'Points', value: 'points' },
        { text: 'G', value: 'games' },
        { text: 'W', value: 'win' },
        { text: 'D', value: 'draw' },
        { text: 'L', value: 'lose' },
        { text: 'Goals', value: 'goal' },
        { text: 'Assists', value: 'assist' },
        { text: 'OGs', value: 'ownGoal' },
        { text: 'PC', value: 'penaltyConceded' },
        { text: 'PM', value: 'penaltyMiss' },
        { text: 'PS', value: 'penaltySave' },
        { text: 'MoTM', value: 'motm' },
        { text: 'DoTM', value: 'dotm' },
        { text: 'GoTM', value: 'gotm' },
        { text: '<5', value: 'concededUnder5' },
        { text: '<4', value: 'concededUnder4' },
        { text: '<3', value: 'concededUnder3' },
        { text: '<1', value: 'concededUnder1' },
      ],
    };
  },
  computed: {
    ...mapState('leagues', ['league']),
    currentLeagueStandings() {
      if (this.league) {
        return this.league.currentLeague;
      } else {
        return [];
      }
    },
  },
};
</script>
