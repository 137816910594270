<template>
  <v-navigation-drawer
    id="default-drawer"
    v-model="drawer"
    :dark="darkOverride"
    :right="$vuetify.rtl"
    :src="drawerImage ? require(`@/assets/${imageOverride}`) : ''"
    :mini-variant.sync="mini"
    mini-variant-width="80"
    app
    width="260"
  >
    <!-- Drawer background darker shade -->
    <template
      v-if="drawerImage"
      #img="props"
    >
      <v-img
        :key="image"
        :gradient="gradientOverride"
        v-bind="props"
      />
    </template>
    <!-- Menu items -->
    <div class="px-2">
      <!-- header / logo -->
      <default-drawer-header />

      <v-divider class="mx-3" />
      <!-- account menu -->
      <default-account-settings />

      <v-divider class="mx-3 mb-2" />

      <!-- main menu items -->
      <template v-if="token !== null || token">
        <default-list
          v-if="user"
          :items="items"
        />
      </template>
    </div>

    <!-- Drawer footer -->
    <!-- <template #append>
      <div class="pa-4 text-center">
        <app-btn
          class="text-none mb-4"
          color="white"
          href="https://vuetifyjs.com"
          small
          text
        >
          Documentation
        </app-btn>

        <app-btn
          block
          class="text-none"
          color="secondary"
          href="https://store.vuetifyjs.com/products/vuetify-material-dashboard-pro"
        >
          Buy Now
        </app-btn>
      </div>
    </template> -->

    <div class="pt-12" />
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import { get, sync } from 'vuex-pathify'
  import { mapState } from 'vuex'

  export default {
    name: 'DefaultDrawer',
    components: {
      DefaultAccountSettings: () => import(
        /* webpackChunkName: "default-account-settings" */
        './widgets/AccountSettings'
      ),
      DefaultDrawerHeader: () => import(
        /* webpackChunkName: "default-drawer-header" */
        './widgets/DrawerHeader'
      ),
      DefaultList: () => import(
        /* webpackChunkName: "default-list" */
        './List'
      ),
    },
    data: () => ({
      darkOverride: true,
      gradientOverride: 'rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)',
      imageOverride: 'timothy-tan-PAe2UhGo-S4-unsplash.jpg',
    }),
    computed: {
      ...get('user', [
        'dark',
        'gradient',
        'image',
      ]),
      ...get('app', [
        'items',
        'version',
      ]),
      ...sync('app', [
        'drawer',
        'drawerImage',
        'mini',
      ]),
      ...mapState('auth', ['token', 'user']),
    },
  }
</script>

<style lang="sass">
#default-drawer
  .v-list-item
    margin-bottom: 8px

  .v-list-item::before,
  .v-list-item::after
    display: none

  .v-list-group__header__prepend-icon,
  .v-list-item__icon
    margin-top: 12px
    margin-bottom: 12px
    margin-left: 4px

  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important
</style>
