import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import store from './store'
import i18n from './i18n'
import { sync } from 'vuex-router-sync'
import firebase from 'firebase'
import axios from 'axios'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

const toastOptions = {
    // You can set your default options here
}

Vue.use(Toast, toastOptions)

// set up an eventBus listener, so you can call it anywhere.
// globally call Vue.$eventBus or
// call this.$eventBus from within a component.
const event = function event(Vue) {
  const eventBus = new Vue()
 Vue.prototype.$eventBus = eventBus
}
Vue.use(event)

Vue.prototype.$nodeProject = axios.create({
  baseURL: process.env.VUE_APP_BACKENDAPI,
})

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // console.log("Do something before request is sent");
    return config
  },
  function (error) {
    // console.log("Do something with request error");
    console.log(error)
    return Promise.reject(error)
  },
)

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // console.log("Do *nothing* with response data");
    return response
  },
  function (error) {
    console.log('Axios API response error: ', error)
    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch('auth/getCustomToken')
      } else {
        console.log(error.response)
        return Promise.reject(error)
      }
    } else return error
  },
)

Vue.config.productionTip = false

sync(store, router)

let app = null

// wait for firebase auth to init before creating the app
firebase.auth().onAuthStateChanged(() => {
  /* eslint-disable no-new */
  if (!app) {
    app =
      new Vue({
        router,
        vuetify,
        store,
        i18n,
        created () {
          firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            user.getIdTokenResult().then(idTokenResult => {
              store.commit('auth/SET_TOKEN', idTokenResult)
            })
          }
        })
      },
      onIdle () {
        //
        if (this.$route.meta.exemptFromIdleCheck === true) {
          // keep user logged in if page is exempt from idleness check
        } else {
          firebase
            .auth()
            .signOut()
            .then(() => {
              window.localStorage.clear()
              this.$router.push({ name: 'Login' })
            })
        }
      },
      onActive () {
        // do nothing. keep user logged in
      },
      render: h => h(App),
        }).$mount('#app')
      }
})
