<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field v-model="name" label="Name" required />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-select
          v-model="playersPerTeamCountSelected"
          label="Players per team"
          :items="playersPerTeamCountOptions"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-switch
          v-model="videoURLAvailable"
          label="Enter video URL"
          @change="() => videoURL = ''"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="videoURL"
          label="Video URL"
          :disabled="!videoURLAvailable"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <div class="mt-2">
          Kickoff
        </div>
      </v-col>
      <v-col cols="10">
        <date-picker
          v-model="selectedDateTime"
          type="datetime"
          :minute-step="15"
          :show-time-panel="showTimePanel"
          use12h
          :show-second="false"
          format="DD/MM/YYYY HH:mm (ddd)"
          :default-value="defaultDateTime"
        >
          <template v-slot:footer>
            <button class="mx-btn mx-btn-text" @click="toggleTimePanel">
              {{ showTimePanel ? 'select date' : 'select time' }}
            </button>
          </template>
        </date-picker>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-select
          v-model="camPersonSelected"
          label="Cam person"
          :items="getPlayerListDDL"
          return-object
        />
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="kitPersonSelected"
          label="Kit person"
          :items="getPlayerListDDL"
          item-text="text"
          item-value="value"
          return-object
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-switch
          v-model="statsComplete"
          label="Stats completed?"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          color="primary"
          class="float-right"
          @click="updateMatchSettings()"
        >
          Save
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { mapActions, mapState, mapGetters } from 'vuex';
export default {
  name: 'MatchSettings',
  components: { DatePicker },
  data: () => ({
    dialog: false,
    name: '',
    videoURLAvailable: false,
    videoURL: '',
    selectedDateTime: null,
    showTimePanel: false,
    camPersonSelected: {},
    kitPersonSelected: {},
    defaultDateTime: new Date(),
    playersPerTeamCountSelected: 7,
    playersPerTeamCountOptions: [5, 6, 7],
    statsComplete: false,
  }),
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('leagues', ['matches', 'match']),
    ...mapGetters('leagues', ['getPlayerListDDL']),
  },
  mounted() {
    this.populateMatchSettings();
  },
  methods: {
    ...mapActions('leagues', ['updateMatch', 'getMatches']),
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    populateMatchSettings() {
      this.name = this.match.name;
      this.videoURLAvailable = this.match.videoURL.toString().length > 0
      this.videoURL = this.match.videoURL
      this.selectedDateTime = this.match.date
      this.camPersonSelected = {
        value: this.match.camPersonId.toString(),
        text: this.match.camPersonNickname,
      }
      this.kitPersonSelected = {
        value: this.match.kitPersonId.toString(),
        text: this.match.kitPersonNickname,
      }
      this.statsComplete = this.match.statsComplete
    },
    async updateMatchSettings() {
      const matchId = this.match._id;
      const date = this.selectedDateTime;
      const name = this.name;
      const videoURL = this.videoURL;
      const playersPerTeam = this.playersPerTeamCountSelected;
      const camPersonId = this.camPersonSelected.value;
      const camPersonNickname = this.camPersonSelected.text;
      const kitPersonId = this.kitPersonSelected.value;
      const kitPersonNickname = this.kitPersonSelected.text;
      const statsComplete = this.statsComplete

      const payload = {
        matchId,
        date,
        name,
        videoURL,
        playersPerTeam,
        camPersonId,
        camPersonNickname,
        kitPersonId,
        kitPersonNickname,
        statsComplete,
      };

      await this.updateMatch(payload);
      // await this.getMatches(this.league._id.toString());
    },
  },
};
</script>
