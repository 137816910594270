<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
      >
        <v-text-field
          v-model="description"
          label="Description"
          required
        />
      </v-col>
      <v-col
        cols="12"
      >
        <v-text-field
          v-model="youtubeURL"
          label="YouTube URL"
          required
        />
      </v-col>
      <v-col cols="12">
        <date-picker
          v-model="selectedDateTime"
          type="datetime"
          :minute-step="15"
          :show-time-panel="showTimePanel"
          use12h
          :show-second="false"
          format="DD/MM/YYYY HH:mm (ddd)"
          :default-value="defaultDate"
        >
          <template v-slot:footer>
            <button
              class="mx-btn mx-btn-text"
              @click="toggleTimePanel"
            >
              {{ showTimePanel ? 'select date' : 'select time' }}
            </button>
          </template>
        </date-picker>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          color="primary"
          class="float-right"
          @click="saveMatchSettings()"
        >
          Save
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import objectId from 'bson-objectid'
  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/index.css'
  import { mapState, mapActions } from 'vuex'
  export default {
    name: 'MatchSettings',
    components: { DatePicker },
    data: () => ({
      description: '',
      youtubeURL: '',
      selectedDateTime: null,
      showTimePanel: false,
      defaultDate: new Date(),
    }),
    computed: {
      ...mapState('leagues', ['match']),
    },
    mounted () {
      this.setDefaultValues()
    },
    methods: {
      ...mapActions('leagues', ['updateMatchSettings', 'getMatch']),
      toggleTimePanel () {
        this.showTimePanel = !this.showTimePanel
      },
      async saveMatchSettings () {
        const matchId = objectId(this.$route.params.matchId)
        const datetime = this.selectedDateTime
        const description = this.description
        const youtubeURL = this.youtubeURL
        const payload = {
          matchId,
          datetime,
          description,
          youtubeURL,
        }

        await this.updateMatchSettings(payload)
        await this.getMatch(this.$route.params.matchId.toString())
      },
      setDefaultValues () {
        this.description = this.match.description
        this.youtubeURL = this.match.youtubeURL
        const ts = Number(Date.parse(this.match.date))
        const dt = new Date(ts)
        this.selectedDateTime = dt
      },
    },
  }
</script>

<style>

</style>
