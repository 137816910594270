import * as RealmWeb from 'realm-web';

export default {
  namespaced: true,
  state: {
    leagues: null,
    league: null,
    matches: null,
    match: null,
    matchHighlights: null,
    matchAwards: null,
  },
  getters: {
    getPlayerList: state => {
      return state.league.players.sort((a, b) => {
        return a.nickname < b.nickname ? -1 : 1;
      });
    },
    getPlayerListDDL: state => {
      return state.league.players
        .map(player => {
          return { text: player.nickname, value: player._id };
        })
        .sort((a, b) => {
          return a.text < b.text ? -1 : 1;
        });
    },
    getAllTeamColoursDDL: state => {
      return state.league.teams.map(team => {
        return {
          // upper case first character of colour
          text: team.defaultColour.replace(/(^\w|\s\w)/g, m => m.toUpperCase()),
          value: team.defaultColour,
        };
      });
    },
    getAllTeamsDDL: state => {
      return state.league.teams.map(team => {
        return {
          text: team.name,
          value: team.name,
        };
      });
    },
    getMatchTeams: state => {
      // add team total to this

      const teams = state.match.teams.map(player => {
        return {
          colour: player.colour,
          teamName: player.teamName,
        };
      });

      const uniqueTeams = [
        ...new Map(teams.map(team => [team.colour, team])).values(),
      ];

      uniqueTeams.forEach(team => {
        team.players = state.match.teams.filter(
          player => player.colour === team.colour,
        );
      });

      return uniqueTeams;
    },

    matchPointsApproved: state => {
      return state.match.points
        ? state.match.points.filter(point => point.approved === true)
        : [];
    },
    getMatchPoints: state => {
      return state?.match?.points ? state.match.points : [];
    },
  },
  mutations: {
    SET_LEAGUES(state, payload) {
      state.leagues = payload;
    },
    SET_LEAGUE(state, payload) {
      state.league = payload;
    },
    SET_MATCHES(state, payload) {
      state.matches = payload;
    },
    SET_MATCH(state, payload) {
      state.match = payload;
    },
    SET_MATCH_HIGHLIGHTS(state, payload) {
      state.matchHighlights = payload;
    },
    SET_MATCH_AWARDS(state, payload) {
      state.matchAwards = payload;
    },
  },
  actions: {
    async getLeagues({ commit, dispatch, rootState }) {
      const app = new RealmWeb.App({
        id: process.env.VUE_APP_MONGO_REALM_SERVER,
      });
      const credentials = RealmWeb.Credentials.jwt(
        rootState.auth?.token?.token,
      );
      try {
        const user = await app.logIn(credentials);
        const result = await user.functions.getLeagues_new();
        commit('SET_LEAGUES', result);
      } catch (err) {
        this._vm.$toast.error('Leagues failed to load', { timeout: 2000 });
        console.error(`Errors: ${err}`);
        dispatch('auth/signUserOut', {}, { root: true });
      }
    },
    async getLeague({ commit, state, rootState }, payload) {
      const leagues = state.leagues;

      commit(
        'SET_LEAGUE',
        leagues.find(league => league._id.toString() === payload),
      );

      // const app = new RealmWeb.App({
      //   id: process.env.VUE_APP_MONGO_REALM_SERVER,
      // });
      // const credentials = RealmWeb.Credentials.jwt(rootState.auth.token.token);
      // try {
      //   const user = await app.logIn(credentials);
      //   const result = await user.functions.getLeague_new(payload);
      //   commit('SET_LEAGUE', result[0]);
      //   this._vm.$toast.info('League_new loaded', { timeout: 2000 });
      // } catch (err) {
      //   this._vm.$toast.error('League failed to load', { timeout: 2000 });
      //   console.error(`Error: ${err}`);
      // }
    },
    async getMatches({ commit, dispatch, state, rootState }, payload) {
      const app = new RealmWeb.App({
        id: process.env.VUE_APP_MONGO_REALM_SERVER,
      });
      const credentials = RealmWeb.Credentials.jwt(rootState.auth.token.token);
      try {
        const user = await app.logIn(credentials);
        const result = await user.functions.getMatches_new(payload);
        commit('SET_MATCHES', result);
        dispatch('getMatch', state.match._id.toString());
      } catch (err) {
        this._vm.$toast.error('Matches failed to load', { timeout: 2000 });
        console.error(`Error: ${err}`);
      }
    },
    async getMatch({ commit, state, rootState }, payload) {
      // const matches = state.matches;

      // commit(
      //   'SET_MATCH',
      //   matches.find(match => match._id.toString() === payload),
      // );

      const app = new RealmWeb.App({
        id: process.env.VUE_APP_MONGO_REALM_SERVER,
      });
      const credentials = RealmWeb.Credentials.jwt(rootState.auth.token.token);
      try {
        const user = await app.logIn(credentials);
        const result = await user.functions.getMatch_new(payload);
        commit('SET_MATCH', result[0]);
      } catch (err) {
        this._vm.$toast.error('Match failed to load', { timeout: 2000 });
        console.error(`Error: ${err}`);
      }
    },
    async addEvent({ commit, dispatch, state, rootState }, payload) {
      const app = new RealmWeb.App({
        id: process.env.VUE_APP_MONGO_REALM_SERVER,
      });
      const credentials = RealmWeb.Credentials.jwt(rootState.auth.token.token);
      try {
        const user = await app.logIn(credentials);
        await user.functions.addEvent_new(payload);
        dispatch('stats/updateLeagueStats', state.league._id.toString(), {
          root: true,
        });
      } catch (err) {
        console.error(err);
        // dispatch('auth/signUserOut', {}, { root: true })
        this._vm.$toast.error('Failed to add event', { timeout: 2000 });
      }
    },
    async deleteEvent({ commit, dispatch, state, rootState }, payload) {
      const app = new RealmWeb.App({
        id: process.env.VUE_APP_MONGO_REALM_SERVER,
      });
      const credentials = RealmWeb.Credentials.jwt(rootState.auth.token.token);
      try {
        const user = await app.logIn(credentials);
        await user.functions.deleteEvent_new(payload);
        dispatch('stats/updateLeagueStats', state.league._id.toString(), {
          root: true,
        });
      } catch (err) {
        this._vm.$toast.error('Failed to delete event', { timeout: 2000 });
        console.error(`Error: ${err}`);
      }
    },
    async deleteHighlight({ commit, dispatch, state, rootState }, payload) {
      const app = new RealmWeb.App({
        id: process.env.VUE_APP_MONGO_REALM_SERVER,
      });
      const credentials = RealmWeb.Credentials.jwt(rootState.auth.token.token);
      try {
        const user = await app.logIn(credentials);
        await user.functions.deleteHighlight(payload);
        dispatch('stats/updateLeagueStats', state.league._id.toString(), {
          root: true,
        });
      } catch (err) {
        this._vm.$toast.error('Failed to delete highlight', { timeout: 2000 });
        console.error(`Error: ${err}`);
      }
    },
    async approveEvent({ commit, dispatch, state, rootState }, payload) {
      const app = new RealmWeb.App({
        id: process.env.VUE_APP_MONGO_REALM_SERVER,
      });
      const credentials = RealmWeb.Credentials.jwt(rootState.auth.token.token);
      try {
        const user = await app.logIn(credentials);
        await user.functions.approveEvent_new(payload);
        dispatch('stats/updateLeagueStats', state.league._id.toString(), {
          root: true,
        });
      } catch (err) {
        this._vm.$toast.error('Failed to approve event', { timeout: 2000 });
        console.error(`Error: ${err}`);
      }
    },
    async addMatchAward({ commit, dispatch, state, rootState }, payload) {
      const app = new RealmWeb.App({
        id: process.env.VUE_APP_MONGO_REALM_SERVER,
      });
      const credentials = RealmWeb.Credentials.jwt(rootState.auth.token.token);
      try {
        const user = await app.logIn(credentials);
        await user.functions.addMatchAward_new(payload);
        dispatch('stats/updateLeagueStats', state.league._id.toString(), {
          root: true,
        });
      } catch (err) {
        this._vm.$toast.error('Failed to add match award', { timeout: 2000 });
        console.error(`Error: ${err}`);
      }
    },
    async deleteMatchAward({ commit, rootState }, payload) {
      const app = new RealmWeb.App({
        id: process.env.VUE_APP_MONGO_REALM_SERVER,
      });
      const credentials = RealmWeb.Credentials.jwt(rootState.auth.token.token);
      try {
        const user = await app.logIn(credentials);
        await user.functions.deleteMatchAward(payload);
      } catch (err) {
        this._vm.$toast.error('Failed to delete match award', {
          timeout: 2000,
        });
        console.error(`Error: ${err}`);
      }
    },
    // async getMatchAwards({ commit, rootState }, payload) {
    //   const app = new RealmWeb.App({
    //     id: process.env.VUE_APP_MONGO_REALM_SERVER,
    //   });
    //   const credentials = RealmWeb.Credentials.jwt(rootState.auth.token.token);
    //   try {
    //     const user = await app.logIn(credentials);
    //     const result = await user.functions.getMatchAwards(payload);
    //     commit('SET_MATCH_AWARDS', result);
    //     this._vm.$toast.info('Match awards loaded', { timeout: 2000 });
    //   } catch (err) {
    //     console.error(`Error: ${err}`);
    //     this._vm.$toast.error('Match awards loaded failed to load', {
    //       timeout: 2000,
    //     });
    //   }
    // },
    // async getLeagueAwards ({ commit, rootState }, payload) {
    //     const app = new RealmWeb.App({ id: process.env.VUE_APP_MONGO_REALM_SERVER })
    //     const credentials = RealmWeb.Credentials.jwt(rootState.auth.token.token)
    //     try {
    //         const user = await app.logIn(credentials)
    //         const result = await user.functions.getLeagueAwards(payload)
    //         commit('SET_MATCH_AWARDS', result)
    //     } catch (err) {
    //         console.error(`Error: ${err}`)
    //     }
    // },
    async addNewMatch({ dispatch, rootState }, payload) {
      const app = new RealmWeb.App({
        id: process.env.VUE_APP_MONGO_REALM_SERVER,
      });
      const credentials = RealmWeb.Credentials.jwt(rootState.auth.token.token);
      try {
        const user = await app.logIn(credentials);
        await user.functions.addNewMatch_new(payload);
      } catch (err) {
        this._vm.$toast.error('Failed to add new match', { timeout: 2000 });
        console.error(err);
        // dispatch('auth/signUserOut', {}, { root: true })
      }
    },
    async updateMatch({ dispatch, rootState }, payload) {
      const app = new RealmWeb.App({
        id: process.env.VUE_APP_MONGO_REALM_SERVER,
      });
      const credentials = RealmWeb.Credentials.jwt(rootState.auth.token.token);
      try {
        const user = await app.logIn(credentials);
        await user.functions.updateMatch(payload);
        this._vm.$toast.success('Match settings updated', { timeout: 2000 });
      } catch (err) {
        this._vm.$toast.error('Failed to add new match', { timeout: 2000 });
        console.error(err);
        // dispatch('auth/signUserOut', {}, { root: true })
      }
    },
    async saveTeams({ dispatch, rootState }, payload) {
      const app = new RealmWeb.App({
        id: process.env.VUE_APP_MONGO_REALM_SERVER,
      });
      const credentials = RealmWeb.Credentials.jwt(rootState.auth.token.token);
      try {
        const user = await app.logIn(credentials);
        await user.functions.saveTeams_new(payload);
        dispatch('leagues/getMatch', payload.matchIdAsString, { root: true });
      } catch (err) {
        this._vm.$toast.error('Failed to save teams', { timeout: 2000 });
        console.error(err);
      }
    },
    // async updateMatchSettings({ rootState }, payload) {
    //   const app = new RealmWeb.App({
    //     id: process.env.VUE_APP_MONGO_REALM_SERVER,
    //   });
    //   const credentials = RealmWeb.Credentials.jwt(rootState.auth.token.token);
    //   try {
    //     const user = await app.logIn(credentials);
    //     await user.functions.updateMatchSettings(payload);
    //     this._vm.$toast.success('Match settings updated', { timeout: 2000 });
    //   } catch (err) {
    //     this._vm.$toast.error('Failed to update match settings', {
    //       timeout: 2000,
    //     });
    //     console.error(err);
    //   }
    // },
    async addHighlight({ commit, dispatch, state, rootState }, payload) {
      const app = new RealmWeb.App({
        id: process.env.VUE_APP_MONGO_REALM_SERVER,
      });
      const credentials = RealmWeb.Credentials.jwt(rootState.auth.token.token);
      try {
        const user = await app.logIn(credentials);
        await user.functions.addHighlight_new(payload);
        dispatch('stats/updateLeagueStats', state.league._id.toString(), {
          root: true,
        });
      } catch (err) {
        console.error(err);
        this._vm.$toast.error('Failed to add highlight', { timeout: 2000 });
        // dispatch('auth/signUserOut', {}, { root: true })
      }
    },
  },
};
