var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.match.highlights.length > 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.match.highlights,"items-per-page":5,"dense":"","mobile-breakpoint":0},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Highlights ")])],1)]},proxy:true},{key:"item.min",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.min !== -1 ? ((item.min .toString() .padStart(2, '0')) + ":" + (item.sec.toString().padStart(2, '0'))) : 'No footage')+" ")]}},{key:"item.added",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format(new Date(item.added), 'E do MMM yyyy K:mm'))+" ")]}},{key:"item.submittedBy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.submittedBy ? item.submittedBy : 'Rahul')+" ")]}},(_vm.user.email === 'rahulvaghela@hotmail.com')?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('button',_vm._g({on:{"click":function($event){return _vm.deleteHighlightClick(item)}}},on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}:null],null,true)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }