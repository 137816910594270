<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    @keydown.esc="dialog = false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        v-bind="attrs"
        :disabled="!eligibleToVote()"
        v-on="on"
        @click="reset"
      >
        Vote MOTM/DOTM/GOTM
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Vote for your players of the match</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row v-if="user.email === 'rahulvaghela@hotmail.com'">
            <v-col>
              <v-select
                v-model="selectedAwardedBy"
                label="Awarded By"
                :items="match.teams"
                item-text="nickname"
                prepend-icon="mdi-account-check"
                return-object
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4" sm="12">
              <v-select
                v-model="selectedMotm"
                label="Man"
                :items="playersFromBothTeams"
                item-text="nickname"
                return-object
                prepend-icon="mdi-shield-account"
              />
            </v-col>
            <v-col cols="12" md="4" sm="12">
              <v-select
                v-model="selectedGotm"
                label="Goalkeeper"
                :items="playersFromBothTeams"
                item-text="nickname"
                return-object
                prepend-icon="mdi-boxing-glove"
              />
            </v-col>
            <v-col cols="12" md="4" sm="12">
              <v-select
                v-model="selectedDotm"
                label="Defender"
                :items="playersFromBothTeams"
                item-text="nickname"
                return-object
                prepend-icon="mdi-shield-star"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="save()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { formatDistanceToNow, addDays, isPast } from 'date-fns';
export default {
  name: 'Vote',
  data: () => ({
    formatDistanceToNow,
    addDays,
    isPast,
    dialog: false,
    selectedMotm: null,
    selectedGotm: null,
    selectedDotm: null,
    selectedAwardedBy: null,
  }),
  computed: {
    ...mapState('leagues', ['match', 'matchAwards']),
    ...mapState('auth', ['user']),
    playersFromBothTeams() {
      return [...this.match.teams].sort((a, b) => {
        return a.nickname > b.nickname ? 1 : -1;
      });
    },
  },
  mounted() {
    this.reset();
  },
  methods: {
    ...mapActions('leagues', ['addMatchAward', 'getMatchAwards']),
    async save() {
      const payload = {
        matchId: this.match._id,
        awardedBy: this.selectedAwardedBy.nickname,
        awardedById: this.selectedAwardedBy.playerId,
        submittedBy: this.user.nickname,
        submittedById: this.user._id,
      };

      const motmAward = { ...payload };
      const gotmAward = { ...payload };
      const dotmAward = { ...payload };

      motmAward.type = 'motm';
      motmAward.awardedTo = this.selectedMotm.nickname;
      motmAward.awardedToId = this.selectedMotm.playerId;
      gotmAward.type = 'gotm';
      gotmAward.awardedTo = this.selectedGotm.nickname;
      gotmAward.awardedToId = this.selectedGotm.playerId;
      dotmAward.type = 'dotm';
      dotmAward.awardedTo = this.selectedDotm.nickname;
      dotmAward.awardedToId = this.selectedDotm.playerId;

      // 3 types
      await this.addMatchAward(motmAward);
      await this.addMatchAward(gotmAward);
      await this.addMatchAward(dotmAward);

      this.dialog = false;
    },
    reset() {
      this.eligibleToVote();
    },
    bgColor(color) {
      switch (color) {
        case 'orange':
          return 'orange';
        case 'blue':
          return '#2196F3';
        default:
          return 'black';
      }
    },
    eligibleToVote() {
      // // played in match
      // const playedInMatch =
      //   this.match.teams.filter(
      //     player => player.nickname === this.user.nickname,
      //   ).length > 0;
      // // within 7 days of match date
      // const within7DayOfMatch = !isPast(addDays(new Date(this.match.date), 6));
      // // not already voted
      // const notVoted =
      //   this.matchAwards.filter(award => award.awardedBy === this.user.nickname)
      //     .length === 0;

      const isAdmin = this.user.email === 'rahulvaghela@hotmail.com';

      // return isAdmin ? true : playedInMatch && within7DayOfMatch && notVoted;

      return isAdmin;
    },
  },
};
</script>

<style scoped></style>
