var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"table-striped",attrs:{"headers":_vm.headers,"items":_vm.getPlayerEvents(_vm.selectedPlayer.value),"sort-desc":true,"item-key":"_id","items-per-page":10,"mobile-breakpoint":"0","dense":"","show-expand":"","single-expand":""},on:{"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); }},scopedSlots:_vm._u([{key:"item.nominated",fn:function(ref){
var item = ref.item;
return [(item.nominated)?[(item.nominated.length > 0)?_c('v-icon',{attrs:{"color":"#FFD700","small":""}},[_vm._v(" mdi-star ")]):_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-star ")])]:[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-star ")])]]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.returnUserFriendlyLabel(item.type))+" ")]}},{key:"item.minsec",fn:function(ref){
var item = ref.item;
return [(item.videoURL.startsWith('https:'))?_c('span',[_vm._v(" "+_vm._s(item.min.toString().padStart(2, '0'))+":"+_vm._s(item.sec.toString().padStart(2, '0'))+" ")]):_c('span',[_c('v-icon',{attrs:{"color":"grey","light":""}},[_vm._v(" mdi-video-off ")])],1)]}},{key:"expanded-item",fn:function(ref){
var headersX = ref.headersX;
var item = ref.item;
return [_c('td',{attrs:{"colspan":"4"}},[(item.videoURL.startsWith('https:'))?_c('v-btn',{staticClass:"my-2 mr-2  white--text",attrs:{"x-small":"","color":"red"},on:{"click":function($event){return _vm.navigateToYoutubeTimeStamp(item)}}},[_vm._v(" View "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-youtube ")])],1):_vm._e(),_c('v-btn',{staticClass:"my-2",attrs:{"x-small":"","color":"primary","to":{ name: 'LeagueMatch', params: { matchId: item.matchId } }}},[_vm._v(" View Match ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){return _vm.nominate(true, item._id)}}},[_vm._v(" mdi-thumb-up-outline ")]),(item.nominated)?_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.nominated.length))]):_c('span',{staticClass:"ml-2"},[_vm._v("0")]),_c('v-icon',{staticClass:"ml-3",attrs:{"small":""},on:{"click":function($event){return _vm.nominate(false, item._id)}}},[_vm._v(" mdi-thumb-down-outline ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }