<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="user.email === 'rahulvaghela@hotmail.com'"
        color="primary"
        dark
        v-bind="attrs"
        class="ml-2"
        v-on="on"
      >
        Add Match Award
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Add Match Award</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-radio-group v-model="type" :column="false">
                <v-radio value="motm">
                  <template v-slot:label>
                    <div class="rbLabel">
                      Man of the Match
                    </div>
                  </template>
                </v-radio>
                <v-radio value="gotm">
                  <template v-slot:label>
                    <div class="rbLabel">
                      Goalkeeper of the Match
                    </div>
                  </template>
                </v-radio>
                <v-radio value="dotm">
                  <template v-slot:label>
                    <div class="rbLabel">
                      Defender of the Match
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              awarded by
              <v-radio-group v-model="awardedBy">
                <v-radio
                  v-for="n in players"
                  :key="n.nickname"
                  :label="n.nickname"
                  :value="n.nickname"
                >
                  <template v-slot:label>
                    <v-btn
                      depressed
                      x-small
                      :color="n.colour"
                      class="white--text"
                    >
                      {{ n.nickname }}
                    </v-btn>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="6">
              awarded to
              <v-radio-group v-model="awardedTo">
                <v-radio
                  v-for="n in players"
                  :key="n.nickname"
                  :label="n.nickname"
                  :value="n.nickname"
                >
                  <template v-slot:label>
                    <v-btn
                      depressed
                      x-small
                      :color="n.colour"
                      class="white--text"
                    >
                      {{ n.nickname }}
                    </v-btn>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="saveMatchAward()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'AddAMatchAward',
  props: {
    players: Array,
  },
  data: () => ({
    dialog: false,
    type: 'motm',
    awardedBy: null,
    awardedTo: null,
  }),
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('leagues', ['addMatchAward', 'getMatchAwards']),
    async saveMatchAward() {
      const matchAward = {
        leagueId: this.$route.params.leagueId,
        matchId: this.$route.params.matchId,
        type: this.type,
        awardedBy: this.awardedBy,
        awardedTo: this.awardedTo,
      };
      await this.addMatchAward(matchAward);
      // this.getMatchAwards(this.$route.params.matchId) // deprecated
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.rbLabel {
  margin-right: 10px;
}
.rbPlayer {
  font-weight: bold;
}
</style>
