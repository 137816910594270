<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col>
          <template>
            <v-icon class="mb-1 mr-1">
              mdi-account-details
            </v-icon>
            <span class="ml-2 pt-5 text-sm-h4"
              >Individual Stats and Events</span
            >
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!-- <v-combobox
            v-model="selectedPlayer"
            cols="12"
            :items="getPlayerListDDL"
            item-text="text"
            item-value="value"
            label="Select a player"
            hide-selected
            @change="updatePlayerStats"
          /> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-tabs v-model="tab" background-color="primary" center-active dark>
            <v-tab :key="1" :href="`#tab-${1}`">
              Stats
            </v-tab>
            <v-tab :key="2" :href="`#tab-${2}`">
              Events
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" touchless>
            <v-tab-item :key="1" :value="'tab-' + 1">
              <!-- <individual-stats :selected-stats="selectedStats" class="mt-3" /> -->
            </v-tab-item>
            <v-tab-item :key="2" :value="'tab-' + 2">
              <individual-events
                class="mt-3"
                :selectedPlayer="selectedPlayer"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
export default {
  name: 'UserDashboard',
  components: {
    IndividualStats: () => import('../UserStats/IndividualStats.vue'),
    IndividualEvents: () => import('../UserStats/IndividualEvents.vue'),
  },
  data() {
    return {
      tab: null,
      selectedStats: [],
      selectedPlayer: null,
    };
  },
  computed: {
    ...mapGetters('leagues', ['getPlayerList', 'getPlayerListDDL']),
    ...mapState('auth', ['user']),
    ...mapState('leagues', ['league', 'leagues']),
    ...mapState('stats', ['stats', 'statsLoadingStatus', 'lastUpdated']),
  },
  watch: {
    // stats() {
    //   this.updatePlayerStats();
    // },
  },
  async mounted() {
    this.selectedPlayer = { text: this.user.nickname, value: this.user._id };
    // this.updatePlayerStats();
  },
  methods: {
    ...mapActions('stats', ['getStats', 'getPlayerEvents']),
    ...mapActions('leagues', ['getLeague']),
    // updatePlayerStats() {
    //   if (!this.selectedPlayer) {
    //     this.selectedStats = [];
    //   } else {
    //     const filterToUser = this.stats.filter(playerStats => {
    //       return playerStats.name === this.selectedPlayer.text;
    //     })[0];

    //     if (filterToUser === undefined) {
    //       this.selectedStats = [];
    //     } else {
    //       this.selectedStats = [
    //         {
    //           type: 'Appearances',
    //           number: filterToUser.games,
    //         },
    //         {
    //           type: 'Goals',
    //           number: filterToUser.goals,
    //         },
    //         {
    //           type: 'Assists',
    //           number: filterToUser.assists,
    //         },
    //         {
    //           type: 'Own goals',
    //           number: filterToUser.ownGoals,
    //         },
    //         {
    //           type: 'Penalties missed',
    //           number: filterToUser.penaltyMiss,
    //         },
    //         {
    //           type: 'Penalties saved',
    //           number: filterToUser.penaltySave,
    //         },
    //         {
    //           type: 'Penalties conceeded',
    //           number: filterToUser.penaltyConceeded,
    //         },
    //       ];
    //     }
    //     this.getPlayerEvents(this.selectedPlayer.text);
    //   }
    // },
  },
};
</script>

<style></style>
