<template>
  <v-container>
    <v-row>
      <google-auth class="mb-3" :video-id="videoId" :title="title" :description="youtubeDesc" category-id="17" />
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3>YouTube Description</h3>
        <v-textarea
          name="input-7-1"
          :value="youtubeDesc"
          class="youtubeDec"
          rows="25"
          row-height="15"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { format } from 'date-fns';
import { titleCase } from '@/util/helpers';

export default {
  name: 'YoutubeDescription',
  components: {
    GoogleAuth: () => import('../components/Dashboard/GoogleAuth.vue'),
  },
  props: {
    players: Array,
    matchPoints: Array,
    matchAwards: Array,
  },
  data() {
    return {
      format,
      orangeTotal: 0,
      blueTotal: 0,
      playlistItems: null,
    };
  },
  computed: {
    ...mapState('leagues', ['league', 'match']),
     ...mapGetters('gauth', ['isReady', 'isSignedIn', 'getUser']),
    playersReactive() {
      return this.players;
    },
    matchAwardsReactive() {
      return this.matchAwards;
    },
    youtubeDesc() {
      let description = `${this.match.name} - ${format(this.match.date, 'dd/MM/yyyy')}

Final Score: ${titleCase(this.match.summary.team2.colour)}s ${this.match.summary.team2.score} - ${this.match.summary.team1.score} ${titleCase(this.match.summary.team1.colour)}s

Man of the Match - ${this.getMatchAwardee('motm')}
Goalkeeper of the Match - ${this.getMatchAwardee('gotm')}
Defender of the Match - ${this.getMatchAwardee('dotm')}

Score | Time Goalscorer (Assist)
${this.scorelineChangingEvents}`

if (this.nonScoreLineChangingEvents.length > 0) {
  description += `\n${this.nonScoreLineChangingEvents}`
}

description += `\n${this.orangeTeam}

Vs.

${this.blueTeam}`

return description
    },
    scorelineChangingEvents() {
      // get the events that cause a scoreline change
      // goal, ownGoal, penalty
      const scorelineChangingEvents = this.match.points
        .filter(point => {
          return (
            point.type === 'goal' ||
            point.type === 'ownGoal' ||
            point.type === 'penalty'
          );
        })
        .filter(point => {
          return !point.approved === false;
        });
      const orderedByMinsAndSecs = scorelineChangingEvents.sort(
        this.sortByMinsSecs,
      );

      let orangeTotal = 0;
      let blueTotal = 0;
      return orderedByMinsAndSecs
        .map(event => {
          orangeTotal += this.addToTeamScore(event, 'orange');
          blueTotal += this.addToTeamScore(event, 'blue');
          return `${orangeTotal} - ${blueTotal} | ${event.min
            .toString()
            .padStart(2, '0')}:${event.sec.toString().padStart(2, '0')} ${
            event.nickname
          } ${this.getAdditionalInfo(event)} \n`;
        })
        .join('');
      // add one to correct team depending on which team the event is attributed to
    },
    nonScoreLineChangingEvents() {
      // get the events that cause a scoreline change
      // goal, ownGoal, penalty
      const nonScorelineChangingEvents = this.match.points
        .filter(point => {
          return point.type === 'penaltyMiss';
        })
        .filter(point => {
          return !point.approved === false;
        });
      const orderedByMinsAndSecs = nonScorelineChangingEvents.sort(
        this.sortByMinsSecs,
      );
      const res = orderedByMinsAndSecs
        .map(event => {
          return `${event.min
            .toString()
            .padStart(2, '0')}:${event.sec.toString().padStart(2, '0')} ${
            event.nickname
          } ${this.getAdditionalInfo(event)} \n`;
        })
        .join('');

      return res
      // return orderedByMinsAndSecs
      //   .map(event => {
      //     return `${event.min
      //       .toString()
      //       .padStart(2, '0')}:${event.sec.toString().padStart(2, '0')} ${
      //       event.nickname
      //     } ${this.getAdditionalInfo(event)} \n`;
      //   })
      //   .join('');
      // add one to correct team depending on which team the event is attributed to
    },
    orangeTeam() {
      return this.getTeamLineup('orange');
    },
    blueTeam() {
      return this.getTeamLineup('blue');
    },
    title() {
      return `${this.match.name} - ${format(this.match.date, 'dd/MM/yyyy')}`;
    },
    videoId() {
      return this.getYouTubeVideoId(this.match.videoURL)
    },
  },
  methods: {
    getMatchAwardee(type) {
      const playerIds = this.match.summary.matchAwards[type];

      if (!playerIds) {
        return 'No Player';
      }

      const players = [];

      playerIds.forEach(playerId => {
        const player = this.league.players.filter(player => {
          return player._id.toString() === playerId.toString();
        })[0];

        players.push(player ? player.nickname : 'Player not found');
      });

      return players.join(' & ');
    },
    getTeamLineup(colour) {
      if (this.match.teams.length === 0) {
        return '';
      }

      const team = this.match.teams.filter(player => {
        return player.colour === colour;
      });
      let teamText = `${colour.toUpperCase()}S \n`;

      let i = 0
      team.forEach(player => {
        teamText += player.nickname
        if (i < team.length - 1) {
          teamText += '\n'
        }
        i++
      });

      return teamText;
    },
    sortByMinsSecs(a, b) {
      if (a.min > b.min) return 1;
      if (a.min < b.min) return -1;
      // check your filters
      if (a.sec < b.sec) return -1;
      if (a.sec > b.sec) return 1;
      return 0;
    },
    addToTeamScore(event, colour) {
      const player = this.playersReactive.filter(
        player => player.nickname === event.nickname,
      );
      switch (event.type) {
        case 'ownGoal':
          return player[0].colour === colour ? 0 : 1;
        case 'goal':
          return player[0].colour === colour ? 1 : 0;
        case 'penalty':
          return player[0].colour === colour ? 1 : 0;
        case 'penaltyMiss':
          return 0;
      }
    },
    getAdditionalInfo(event) {
      switch (event.type) {
        case 'ownGoal':
          return '(Own Goal)';
        case 'goal':
          return `(${this.getAssister(event.min, event.sec)})`;
        case 'penalty':
          return `Penalty - Conceded By ${this.getPenaltyConcededBy(
            event.min,
            event.sec,
          )})`;
        case 'penaltyMiss':
          return `Penalty Miss - Saved By ${this.getPenaltySavedBy(
            event.min,
            event.sec,
          )} & Conceded By ${this.getPenaltyConcededBy(event.min, event.sec)}`;
      }
    },
    getAssister(min, sec) {
      const assistText = this.match.points.filter(event => {
        return (
          event.type === 'assist' && event.min === min && event.sec === sec
        );
      });
      return assistText[0] ? assistText[0].nickname : 'No Assist';
    },
    getPenaltyConcededBy(min, sec) {
      const penaltyConcededByText = this.match.points.filter(event => {
        return (
          event.type === 'penaltyConceded' &&
          event.min === min &&
          event.sec === sec
        );
      });
      return penaltyConcededByText[0].nickname;
    },
    getPenaltySavedBy(min, sec) {
      const penaltySavedByText = this.match.points.filter(event => {
        return (
          event.type === 'penaltySave' && event.min === min && event.sec === sec
        );
      });
      return penaltySavedByText[0].nickname;
    },
    getYouTubeVideoId(url) {
      try {
        const videoUrl = new URL(url);
        let videoId = '';

        if (videoUrl.hostname === 'www.youtube.com' && videoUrl.searchParams.has('v')) {
          videoId = videoUrl.searchParams.get('v');
        } else if (videoUrl.hostname === 'youtu.be') {
          // Extract video ID from the path
          videoId = videoUrl.pathname.substr(1);
        }

        if (videoId) {
          return videoId;
        } else {
          return null; // URL is not a valid YouTube URL
        }
      } catch (error) {
        return null; // Invalid URL format
      }
    },
  },
};
</script>
