<template>
  <v-container id="buttons-view" fluid tag="section">
    <view-intro
      v-if="match"
      :heading="match.name"
      :date="matchDate"
      :link="match.videoURL"
    />
    <match-view v-if="matchPointsApproved" />
    <v-card
      v-if="user.email === 'rahulvaghela@hotmail.com'"
      class="pa-4"
      :loading="loading"
    >
      <v-tabs
        v-if="!loading"
        v-model="tab"
        fixed-tabs
        background-color="primary"
        dark
      >
        <v-tab :key="1" :href="`#tab-${1}`">
          Settings
        </v-tab>
        <v-tab :key="2" :href="`#tab-${2}`">
          Teams
        </v-tab>
        <v-tab :key="3" :href="`#tab-${3}`">
          Events
        </v-tab>
        <v-tab :key="4" :href="`#tab-${4}`">
          Player Awards
        </v-tab>
        <v-tab :key="5" :href="`#tab-${5}`">
          YouTube Description
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item :key="1" :value="'tab-' + 1">
          <match-settings />
        </v-tab-item>
        <v-tab-item :key="2" :value="'tab-' + 2">
          <team-selection />
        </v-tab-item>
        <v-tab-item :key="3" :value="'tab-' + 3">
          <v-row v-if="user.email === 'rahulvaghela@hotmail.com'">
            <v-col cols="12" class="mt-3">
              <add-event v-if="match" :players="match.teams" />
              <edit-event-d-d v-if="match" :players="match.teams" />
            </v-col>
          </v-row>
          <v-row class="mt-5">
            <v-col cols="12">
              <match-events-table />
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item :key="4" :value="'tab-' + 4">
          <v-row>
            <v-col cols="12" class="mt-3">
              <add-match-award v-if="match" :players="match.teams" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="mt-3">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Type
                      </th>
                      <th class="text-left">
                        Awarded By
                      </th>
                      <th class="text-left">
                        Awarded To
                      </th>
                      <th class="text-left">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in matchAwards" :key="item._id.toString()">
                      <td>{{ item.type }}</td>
                      <td>{{ item.awardedBy }}</td>
                      <td>{{ item.awardedTo }}</td>
                      <td>
                        <v-btn
                          small
                          color="primary"
                          @click="deleteAward(item._id.toString())"
                        >
                          Delete
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item :key="5" :value="'tab-' + 5">
          <v-row>
            <v-col cols="12" class="mt-3">
              <youtube-description
                v-if="matchPointsApproved.length > 0"
                :players="match.teams"
                :match-points="matchPoints"
                :match-awards="matchAwards"
              />
              <v-alert
                v-else
                type="warning"
                class="mt-5"
                text
              >
                <span>
                  YouTube description can only be updated once match points have been approved
                </span>
              </v-alert>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
  name: 'DashboardButtons',
  components: {
    AddEvent: () => import('../components/AddEvent'),
    EditEventDD: () => import('../components/EditEventDD'),
    AddMatchAward: () => import('../components/AddMatchAward'),
    YoutubeDescription: () => import('../components/YoutubeDescription'),
    TeamSelection: () => import('../components/TeamSelection'),
    MatchSettings: () => import('../components/MatchSettings_new'),
    MatchView: () => import('../components/MatchView'),
    MatchEventsTable: () => import('../components/MatchView/MatchEventsTable'),
  },
  data() {
    return {
      tab: null,
      loading: true,
      editDialog: false,
    };
  },
  computed: {
    ...mapGetters('leagues', ['matchPointsApproved']),
    ...mapState('leagues', ['match', 'matchPoints', 'matchAwards']),
    ...mapState('auth', ['user']),
    matchDate() {
      const d = new Date(this.match.date);
      return d;
    },
  },
  async mounted() {
    const matchId = this.$route.params.matchId.toString();
    await this.getMatch(matchId);
    // await this.getMatchPoints(matchId); // deprecated
    // await this.getMatchAwards(matchId); // deprecated
    // await this.getMatchHighlights(matchId); // deprecated
    this.loading = false;
  },
  methods: {
    ...mapActions('leagues', [
      'getMatch',
      // 'getMatchPoints',
      // 'getMatchAwards',
      // 'getMatchHighlights',
      'deleteMatchAward',
    ]),
    async deleteAward(id) {
      await this.deleteMatchAward(id);
      // this.getMatchAwards(this.$route.params.matchId); // deprecated
    },
    navigateToYoutubeTimeStamp(item) {
      const min = item.min;
      const sec = item.sec - 15;

      if (this.match.youtubeURL.startsWith('https://www.youtube.com')) {
        // console.log(`${this.match.youtubeURL}&t=${min}m${sec}s`)
        window.open(`${this.match.youtubeURL}&t=${min}m${sec}s`, '_blank');
      } else {
        // console.log(`${this.match.youtubeURL}?t=${min}m${sec}s`)
        window.open(`${this.match.youtubeURL}?t=${min}m${sec}s`, '_blank');
      }
    },
    rowClickHandler(item) {
      const linkedEvents = this.findMatchingEvent(item.added);
      const eventType = this.findEventType(linkedEvents);
      const modalData = { linkedEvents: linkedEvents, eventType: eventType };
      this.$eventBus.$emit('showModal', modalData);
    },
    findMatchingEvent(time) {
      return this.matchPoints.filter(event => {
        return (
          event.added.getHours() === time.getHours() &&
          event.added.getMinutes() === time.getMinutes() &&
          event.added.getSeconds() === time.getSeconds()
        );
      });
    },
    findEventType(linkedEvents) {
      const eventTypes = linkedEvents.map(event => {
        return event.type;
      });

      const goal = ['goal', 'assist'];
      const penalty = ['penalty', 'penaltyConceded'];
      const penaltyMiss = ['penaltyMiss', 'penaltySave', 'penaltyConceded'];
      const ownGoal = ['ownGoal'];

      const goalFound = eventTypes.every(events => goal.includes(events));
      const penaltyFound = eventTypes.every(events => penalty.includes(events));
      const penaltyMissFound = eventTypes.every(events =>
        penaltyMiss.includes(events),
      );
      const ownGoalFound = eventTypes.every(events => ownGoal.includes(events));

      let typeFound = 'Event type not found';
      if (goalFound) {
        typeFound = 'goal';
      } else if (penaltyFound) {
        typeFound = 'penalty';
      } else if (penaltyMissFound) {
        typeFound = 'penaltyMiss';
      } else if (ownGoalFound) {
        typeFound = 'ownGoal';
      } else {
        console.error('Event type not found');
      }

      return typeFound;
    },
  },
};
</script>
