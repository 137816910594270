<template>
  <div>
    <v-btn
      v-if="player"
      class="list-group-item"
      :style="{ backgroundColor: bgColor(player.colour)}"
      block
      @click="removePlayerSelected"
    >
      {{ player.nickname }}
      <v-icon small color="error" class="ml-1">
        mdi-cancel
      </v-icon>
    </v-btn>
    <!-- <div
      v-for="(player) in arrEvent"
      :key="player.nickname"
      class="list-group-item"
      :style="{ backgroundColor: bgColor(player.colour), borderRadius: '5px'}"
    >
      <span>{{ player.nickname.toUpperCase() }}</span>
    </div> -->
    <!-- If there are no players in the array, display the event type -->
    <span
      v-else
    >{{ friendlyDesc.toUpperCase() }}</span>
  </div>
</template>
<script>
  export default {
    name: 'DragDropArea',
  props: {
      player: {
        type: Object,
      },
      friendlyDesc: {
        type: String,
    },
      arrayName: {
        type: String,
      },
    },
    methods: {
      bgColor (color) {
        switch (color) {
          case 'orange':
            return 'orange'
          case 'blue':
            return '#2196F3'
          default:
            return 'black'
        }
      },
      removePlayerSelected() {
        this.$eventBus.$emit('remove-player-selected', this.arrayName);
      },
    },
  }
</script>
