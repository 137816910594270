<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col>
          <template>
            <v-icon class="mb-1 mr-1">
              mdi-run-fast
            </v-icon>
            <span class="ml-2 pt-5 text-sm-h4">Quick Access</span>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          Latest Match:
          <v-btn
            elevation="2"
            small
            class="mx-2"
            color="primary"
            :to="latestMatchURL"
          >
            {{ latestMatchDesc }}
          </v-btn>
        </v-col>
        <v-col>
          All Matches:
          <v-btn
            elevation="2"
            small
            class="mx-2"
            color="primary"
            :to="{
              name: 'League',
              params: { leagueId: league._id },
            }"
          >
            {{ league.name }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { format, parseISO } from 'date-fns';
export default {
  name: 'QuickAccess',
  data() {
    return {
      format,
      parseISO,
      latestMatchURL: null,
      latestMatchDesc: null,
    };
  },
  computed: {
    ...mapState('leagues', ['league', 'matches']),
  },
  watch: {
    matches() {
      this.getLatestMatch();
    },
  },
  methods: {
    getLatestMatch() {
      const latestMatch = new Date(
        Math.max(...this.matches.map(match => new Date(match.date))),
      );

      const match = this.matches.filter(match => {
        return new Date(match.date).toISOString() === latestMatch.toISOString();
      })[0];

      this.latestMatchDesc = `${match.name} (${format(
        parseISO(new Date(match.date).toISOString()),
        'E do MMM',
      )})`;
      this.latestMatchURL = {
        name: 'LeagueMatch',
        params: { leagueId: match.leagueId, matchId: match._id },
      };
    },
  },
};
</script>

<style></style>
