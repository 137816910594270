<template>
  <div v-if="match.points.length > 0">
    <v-divider v-if="eventType !== 'goals'" class="my-2" />
    <v-row>
      <v-col class="text-right" cols="5">
        <span
          v-for="event in eventsRequestedByPropEventType(
            match.summary.team1.teamName,
          )"
          :key="event.time"
        >
          <match-view-events-player-and-time :event="event" />
        </span>
      </v-col>
      <v-col cols="2" class="d-flex justify-center align-self-center">
        <h4 class="grey--text text--darken-1 hidden-sm-and-down">
          {{ returnUserFriendlyLabel(eventType).toUpperCase() }}
        </h4>
        <h5 class="grey--text text--darken-1 hidden-md-and-up">
          <span v-if="eventType === 'penaltyConceded'">PENS CONC.</span>
          <span v-else-if="eventType === 'penalty'">PENS</span>
          <span v-else>{{ eventType.toUpperCase() }}</span>
        </h5>
      </v-col>
      <v-col cols="5" class="text-left">
        <span
          v-for="event in eventsRequestedByPropEventType(
            match.summary.team2.teamName,
          )"
          :key="event.time"
        >
          <match-view-events-player-and-time :event="event" />
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import helper from '../../mixins/helpers';
export default {
  name: 'MatchViewEvents',
  components: {
    MatchViewEventsPlayerAndTime: () =>
      import('./MatchViewEventsPlayerAndTime'),
  },
  mixins: [helper],
  props: {
    eventType: String,
  },
  computed: {
    ...mapState('leagues', ['match']),
  },
  methods: {
    eventsRequestedByPropEventType(team) {
      return this.match.points
        .filter(point => {
          return point.type === this.eventType && point.team === team;
        })
        .map(point => {
          return {
            nickname: point.nickname,
            min: point.min,
            sec: point.sec,
            approved: point.approved,
          };
        })
        .sort((a, b) => {
          return a.min - b.min || a.secs - b.secs;
        });
    },
  },
};
</script>

<style></style>
