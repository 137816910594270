<template>
  <div>
    <v-row>
      <v-col
        cols="6"
        class="d-flex justify-center"
      />
      <v-col
        cols="6"
        class="d-flex justify-center"
      />
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-list
          disabled
          dense
        >
          <v-list-item-group>
            <v-list-item
              v-for="(player, i) in match.teams.filter(player => player.colour === 'orange').sort((a,b) => (a.nickname > b.nickname) ? 1 : ((b.nickname > a.nickname) ? -1 : 0))"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="d-flex justify-center"
                >
                  <v-chip
                    outlined
                    color="orange"
                  >
                    <span class="text-button orange--text">{{ player.nickname }}</span>
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col cols="6">
        <v-list
          disabled
          dense
        >
          <v-list-item-group>
            <v-list-item
              v-for="(player, i) in match.teams.filter(player => player.colour === 'blue').sort((a,b) => (a.nickname > b.nickname) ? 1 : ((b.nickname > a.nickname) ? -1 : 0))"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="d-flex justify-center"
                >
                  <v-chip
                    outlined
                    color="blue"
                  >
                    <span class="text-button primary--text">{{ player.nickname }}</span>
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'Lineups',
    data () {
      return {
        model: 1,
      }
    },
    computed: {
      ...mapState('leagues', ['match']),
    },
  }
</script>

<style>

</style>
