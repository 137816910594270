<template>
  <div v-if="token !== null || token">
    <default-list
      v-if="token.signInProvider === 'password'"
      :items="items"
      class="mb-n2"
    />
    <default-list
      v-else
      :items="itemsCustom"
      class="mb-n2"
    />
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'

  export default {
    name: 'DefaultAccountSettings',
    components: {
      DefaultList: () => import(
        /* webpackChunkName: "default-list" */
        '../List'
      ),
    },
    data: () => ({
      items: [
        {
          title: 'Loading',
          icon: 'mdi-soccer',
          items: [
            // {
            //   title: 'My Profile',
            //   icon: 'mdi-account',
            //   to: '/profile',
            // },
            {
              title: 'Logout',
              icon: 'mdi-logout',
              to: '/login',
            },
          ],
        },
      ],
      itemsCustom: [
        {
          title: 'Guest',
          icon: 'mdi-soccer',
          items: [
            {
              title: 'Logout',
              icon: 'mdi-logout',
              to: '/login',
              action: 'logout',
            },
          ],
        },
      ],
    }),
    computed: {
      ...mapState('auth', ['token', 'user']),
      ...mapGetters('auth', ['getUserNickname']),
    },
    mounted () {
      this.items[0].title = this.getUserNickname
    },
  }
</script>
